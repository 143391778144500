import {
    WALLET_CREATE_REQUEST,
    WALLET_CREATE_SUCCESS,
    WALLET_CREATE_FAIL,
    WALLET_REQUEST,
    WALLET_SUCCESS,
    WALLET_FAIL,
    UPI_CREATE_REQUEST,
    UPI_CREATE_SUCCESS,
    UPI_CREATE_FAIL,
    WALLET_HISTORY_REQUEST,
    WALLET_HISTORY_SUCCESS,
    WALLET_HISTORY_FAIL,
    WALLET_GET_REQUEST,
    WALLET_GET_SUCCESS,
    WALLET_GET_FAIL
} from '../actions/walletActions'

const initialState = {

    wallets: { all: [], error: '', isLoading: false },
    wallet: { wallet: [], error: '', isLoading: false },
    walletCreate: { wallet: {}, error: '', isLoading: false },
    upiCreate: { wallet: {}, error: '', isLoading: false },
    walletHistory: { wallet: [], error: '', isLoading: false },
};
export default function (state = initialState, action: any) {
    switch (action.type) {
        case WALLET_CREATE_REQUEST:
            return { ...state, walletCreate: { wallet: {}, error: '', isLoading: true } };
        case WALLET_CREATE_SUCCESS:
            return { ...state, walletCreate: { wallet: action.payload, error: '', isLoading: false } };
        case WALLET_CREATE_FAIL:
            return { ...state, walletCreate: { wallet: {}, error: action.payload, isLoading: false } };
        case UPI_CREATE_REQUEST:
            return { ...state, upiCreate: { wallet: {}, error: '', isLoading: true } };
        case UPI_CREATE_SUCCESS:
            return { ...state, upiCreate: { wallet: action.payload, error: '', isLoading: false } };
        case UPI_CREATE_FAIL:
            return { ...state, upiCreate: { wallet: {}, error: action.payload, isLoading: false } };
        case WALLET_REQUEST:
            return { ...state, wallets: { all: state.wallets.all, error: '', isLoading: true } };
        case WALLET_SUCCESS:
            return { ...state, wallets: { all: action.payload, error: '', isLoading: false } };
        case WALLET_FAIL:
            return { ...state, wallets: { all: [], error: action.payload, isLoading: false } };
        case WALLET_GET_REQUEST:
            return { ...state, wallet: { wallet: {}, error: '', isLoading: true } };
        case WALLET_GET_SUCCESS:
            return { ...state, wallet: { wallet: action.payload, error: '', isLoading: false } };
        case WALLET_GET_FAIL:
            return { ...state, wallet: { wallet: {}, error: action.payload, isLoading: false } };
        case WALLET_HISTORY_REQUEST:
            return { ...state, walletHistory: { wallet: state.walletHistory.wallet, error: '', isLoading: true } };
        case WALLET_HISTORY_SUCCESS:
            return { ...state, walletHistory: { wallet: action.payload, error: '', isLoading: false } };
        case WALLET_HISTORY_FAIL:
            return { ...state, walletHistory: { wallet: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }
}

