import { useCallback, useEffect, useState } from "react";
import { Image, Platform, Text, View } from "react-native";
import EsiCheckbox from "../../../components/custom/checkbox";

export const PopularFilters = (props) => {
    const { filters, setFilters } = props;

    const data = useCallback([
        {
            label: "Popular Products",
            value: "Is_Popular", chacked: filters?.popular.indexOf("Is_Popular") >-1 || false
        },
        {
            label: "Top Products",
            value: "Is_Top_Product", chacked: filters?.popular.indexOf("Is_Top_Product") >-1 || false
        },
        {
            label: "Today's Hot Products",
            value: "Whats_Hot", chacked: filters?.popular.indexOf("Whats_Hot") >-1 || false
        },
        {
            label: "Upcoming Products",
            value: "Is_Upcoming", chacked: filters?.popular.indexOf("Is_Upcoming") >-1 || false
        },
        {
            label: "Verified Products",
            value: "Is_Verified", chacked: filters?.popular.indexOf("Is_Verified") >-1 || false
        }
    ], [filters]);

    const onchange = res => {
        setFilters(props => {
            let index=props.popular.indexOf(res.value);
            if(index>=0){
                props.popular.splice(index, 1);
            }
            else{
                props.popular.push(res.value);
            }
            return { ...props}
        });
    }

    return (
        <View style={[{ marginHorizontal: 5 }, Platform.select({ ios: { zIndex: 100 } })]}>
            <View style={{ flexDirection: "row" }}>
                <Image style={{ width: 25, height: 25, marginRight: 10 }}
                    source={require('../../../assets/images/favicon.png')}
                />
                <Text style={{
                    fontSize: 14, color: "#03dac4",
                    marginTop: 3,
                }}>Popular</Text>
            </View>
            <EsiCheckbox data={data} onchange={onchange} />
        </View>
    )
}