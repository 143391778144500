import { useEffect, useState } from "react";
import { ActivityIndicator, Image, Platform, Text, View } from "react-native";
import { useDispatch } from "react-redux";
import { EsiAutocompleteDropdown } from "../../../components/custom/DropDowns/AutoCompleteDropDown";
import { getPartnersAction } from "../../../state/actions/node-actions/home-actions";

export const PartnerFilters = (props) => {
    const { filters, setFilters } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [partners, setPartners] = useState([]);
    const [selected, setSelected] = useState(null);

    const callBackGetData = async (status, data) => {
        setLoading(false);
        if (status) {
            setPartners(data.map(item => ({ "id": item.Partner_Details_Id, "title": item.Name })));
        } else {
            setPartners([]);
        }

        if (seconds == -2) {
            setSelected(data.map(item => ({ "id": item.Partner_Details_Id, "title": item.Name }))[0]);
            setSeconds(2);
        }
    }

    const [seconds, setSeconds] = useState(-1);
    useEffect(() => {
        if (seconds != -2) {
            const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
            return () => clearTimeout(timer)
        } else {
            setLoading(true);
            dispatch(getPartnersAction({
                "Records_Filter": "FILTER",
                "Status": "Active",
                "Partner_Details_Id": filters?.partnerId
            },
                { PageNo: 1, PageSize: 10, SortBy: "NAME", SortOrder: "ASC" }
                , callBackGetData));
        }
    }, [seconds]);


    useEffect(() => {
        if (filters?.partnerId != selected?.id && filters?.partnerId) {
            setSeconds(-2);
        } else {
            if (partners.length <= 0 && !(selected?.id))
                apiCall("");
        }
    }, [filters]);

    const apiCall = (q) => {
        setLoading(true);
        dispatch(getPartnersAction({
            "Records_Filter": "FILTER",
            "Status": "Active",
            "Search_Partner_Name": q
        },
            { PageNo: 1, PageSize: 10, SortBy: "NAME", SortOrder: "ASC" }
            , callBackGetData));
    }

    return (
        <View style={[{ marginHorizontal: 5 }, Platform.select({ ios: { zIndex: 100 } })]}>
            <View style={{ flexDirection: "row" }}>
                <Image style={{ width: 25, height: 25, marginRight: 10 }}
                    source={require('../../../assets/images/favicon.png')}
                />
                <Text style={{
                    fontSize: 14, color: "#03dac4",
                    marginTop: 3,
                }}>Partners</Text>
            </View>
            {seconds <= 0 ?
                <EsiAutocompleteDropdown
                    loading={loading}
                    data={partners}
                    label={"Search Partner"}
                    onSelect={(item) => {
                        setSelected(item);
                        if(item?.id){
                            setFilters((props => {
                                return { ...props, partnerId: item.id }
                            }))
                        }
                    }}
                    selected={selected}
                    getSuggestions={(q) => {
                        apiCall(q);
                    }}
                    onClear={()=>{
                        setFilters((props => {
                            return { ...props, partnerId: "" }
                        }))
                    }}
                /> : <ActivityIndicator />}
        </View>
    )
}