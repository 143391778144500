import axios from 'axios';
import { boomiApi } from './commonAxios';
export const CATEGORYRESET = "CATEGORYRESET";
export const SET_ALL_CATEGORIES = "SET_ALL_CATEGORIES";


/*=============================================================
                  Get All Categories Action
===============================================================*/

export const getAllCategoriesAction = () => async (dispatch: any) => {
  try {
    const formData = {
    };
    let { data } = await boomiApi.post(
      `/Categories/AllCategoryGet?PageNo=0&PageSize=500&SortBy=CATEGORY_NAME&SortOrder=ASC`,
      formData,
    );
    var AllCategoriesData = data.AllCategories[0];
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: SET_ALL_CATEGORIES,
          payload: AllCategoriesData,
        });
      } else {
        dispatch({
          type: SET_ALL_CATEGORIES,
          payload: AllCategoriesData.Category,
        });
      }
    }
  } catch (error) {
  }
};


export const getAllfilterCategoriesAction = (callBack: any) => async (dispatch: any) => {
  try {
    const formData = {
    };
    let { data } = await boomiApi.post(
      `/Categories/AllCategoryGet?PageNo=0&PageSize=500&SortBy=CATEGORY_NAME&SortOrder=ASC`,
      formData,
    );
    var AllCategoriesData = data.AllCategories[0]
    if (data) {
      callBack(AllCategoriesData.Category)
    }
  } catch (error) {
  }
};

export const getFilterCategoriesAction = (formData:any,callBack: any) => async (dispatch: any) => {
  try {
    let { data } = await boomiApi.post(
      `/Categories/Get?PageNo=0&PageSize=500&SortBy=CATEGORY_NAME&SortOrder=ASC`,
      formData,
    );
    if (data) {
      callBack(data)
    }
  } catch (error) {
  }
};

