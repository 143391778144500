
import { nodeGetApi as meeapi, urlGenarator } from "../commonAxios";

export const getMyBidServicesAction = (
    pagination = { PageNo: 1, PageSize: 29, SortBy: "TITLE", SortOrder: "ASC" },
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/bidding/services/bided-services', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, [], data?.Pagination);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(error.message == "Network Error" ? "network" : false, error);
    });
};
export const getBiddingServicesCart = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/bidding/services/bided-services-cart', {}),
        formData,
    ).then(response => {
        let data = response.data;
        if (formData?.getCount) {
            try {
                responseFunction(true, data[0]);
            } catch (error) {

                responseFunction(true,
                    {
                        "Counts": 0
                    }
                );
            }
        } else {
            try {
                responseFunction(true, data);
            } catch (error) {
                responseFunction(true, []);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getBiddingServiceDetails = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/bidding/services/bided-services-details', {}),
        formData,
    ).then(response => {
        let data = response.data;
        try {
            responseFunction(true, data);
        } catch (error) {
            responseFunction(true, {});
        }

    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getBiddingPartnerServiceDetails = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/bidding/services/bided-partner-service-details', {}),
        formData,
    ).then(response => {
        let data = response.data;
        try {
            responseFunction(true, data);
        } catch (error) {
            responseFunction(true, {});
        }

    }).catch(error => {
        responseFunction(false, error);
    });
};