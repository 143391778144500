import { nodeCudApi } from "../commonAxios";
import config from "../config";
import axios from 'axios';
export const meeapiBoomi = axios.create({
  baseURL: config.url,
  headers: config.headersCommon
});


// Add a request interceptor
meeapiBoomi.interceptors.request.use(

  request => {
    return request;
  },
  error => {
    // Do something with response error
    //   console.log('Interceptor Request API ERR:', error.message);
    //   console.log('Interceptor Request API ERR:', error.code);
    //   console.log('Interceptor Request API ERR:', error.status);


    return Promise.reject(error);
  },
);

// Add a response interceptor
meeapiBoomi.interceptors.response.use(
  response => {
    return response;
  },

  error => {
    // Do something with response error
    //   console.log('Interceptor response API ERR:', error.message);
    //   console.log('Interceptor response API ERR:', error.code);
    //   console.log('Interceptor response API ERR:', error.status);
    return Promise.reject(error);
  },
);

export const meeapiCUD = nodeCudApi;


export default { meeapiCUD, meeapiBoomi };
