import axios from "axios";
import { boomiApi, nodeCudApi } from "./commonAxios";
export const ALL_CASHBACK_REQUEST = "ALL_CASHBACK_REQUEST";
export const ALL_CASHBACK_SUCCESS = "ALL_CASHBACK_SUCCESS";
export const ALL_CASHBACK_FAIL = "ALL_CASHBACK_FAIL";

export const CASHBACK_REQUEST = "CASHBACK_REQUEST";
export const CASHBACK_SUCCESS = "CASHBACK_SUCCESS";
export const CASHBACK_FAIL = "CASHBACK_FAIL";

export const ALL_CASHBACK_FILTER = "ALL_CASHBACK_FILTER";

export const ALL_VISITORS_REQUEST = "ALL_VISITORS_REQUEST";
export const ALL_VISITORS_SUCCESS = "ALL_VISITORS_SUCCESS";
export const ALL_VISITORS_FAIL = "ALL_VISITORS_FAIL";

export const ALL_VISITOR_REQUEST = "ALL_VISITOR_REQUEST";
export const ALL_VISITOR_SUCCESS = "ALL_VISITOR_SUCCESS";
export const ALL_VISITOR_FAIL = "ALL_VISITOR_FAIL";

export const TICKET_CREATE_REQUEST = "TICKET_CREATE_REQUEST";
export const TICKET_CREATE_SUCCESS = "TICKET_CREATE_SUCCESS";
export const TICKET_CREATE_FAIL = "TICKET_CREATE_FAIL";


/*=============================================================
                  Get All Missing Cashback Action
===============================================================*/
export const getbillreceipts = (formData: any) => async (dispatch: any) => {

    try {
        dispatch({
            type: ALL_CASHBACK_REQUEST
        });
        let { data } = await boomiApi.post(`/Tickets/Missing_Cashback_GET?PageNo=0&PageSize=1000&SortBy=TITLE&SortOrder=ASC`,
            formData);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: ALL_CASHBACK_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: ALL_CASHBACK_SUCCESS,
                    payload: data.Bill_Receipt
                });
            }
        }
    } catch (err) {
        dispatch({
            type: ALL_CASHBACK_FAIL,
            payload: err
        });
    }
};
/*=============================================================
                  Get Offer Visitor Action
===============================================================*/
export const getPartnersIdAction = (formData: any, callBackUserVisitsGet: any) => async (dispatch: any) => {

    try {
        dispatch({
            type: ALL_VISITORS_REQUEST
        });
        let { data } = await boomiApi.post(`/Offers_Visitor/Get?PageNo=0&PageSize=1000&SortBy=TITLE&SortOrder=ASC`,
            formData);
        if (data) {

            if (data.Is_Data_Exist === '0') {
                callBackUserVisitsGet([])
                dispatch({
                    type: ALL_VISITORS_SUCCESS,
                    payload: []
                });
            } else {

                callBackUserVisitsGet(data.User_Visits)
                dispatch({
                    type: ALL_VISITORS_SUCCESS,
                    payload: data.User_Visits
                });
            }
        }
    } catch (err) {
        dispatch({
            type: ALL_VISITORS_FAIL,
            payload: err
        });
    }
};
/*=============================================================
                  Get Offer Visitor Action
===============================================================*/
export const getVisitsIdActions = (formData: any, callBackPartnersGet: any) => async (dispatch: any) => {

    try {
        dispatch({
            type: ALL_VISITOR_REQUEST
        });
        let { data } = await boomiApi.post(`/Offers_Visitor/Get?PageNo=0&PageSize=1000&SortBy=TITLE&SortOrder=ASC`,
            formData);
        if (data) {

            if (data.Is_Data_Exist === '0') {
                callBackPartnersGet([])
                dispatch({
                    type: ALL_VISITOR_SUCCESS,
                    payload: []
                });
            } else {
                callBackPartnersGet(data.User_Visits)
                dispatch({
                    type: ALL_VISITOR_SUCCESS,
                    payload: data.User_Visits
                });
            }
        }
    } catch (err) {
        dispatch({
            type: ALL_VISITOR_FAIL,
            payload: err
        });
    }
};
/*=============================================================
                 Filter Action
===============================================================*/
export const cashbackFilter = (fType: any, value: any) => {
    return function (dispatch) {
        dispatch({
            type: ALL_CASHBACK_FILTER,
            fType: fType,
            value: value,
        });
    }
}
/*=============================================================
                 file upload Action
===============================================================*/
export const uploadAttachment = (
    imageData, fileData,
    callBackUploadFile

) => async dispatch => {
    try {
        let { data } = await boomiApi.post(
            `/User_Upload/Upload?functionality=MissingCashBack&fileextension=${fileData.ext}&filetype=Images&filename=${fileData.fileName}`,
            imageData,
            {
                transformRequest: (d) => d,
            }
        );
        if (data) {
            callBackUploadFile(data);
        }
    } catch (err) {
    }
};


/*=============================================================
                 Filter Action
===============================================================*/
export const AddTicketsAction = (formData: any, navigation: any, toast: any, resetForm: any) => async dispatch => {
    dispatch({
        type: TICKET_CREATE_REQUEST
    });
    //   await axios.post(`${config.url}/Tickets/Missing_Cashback_Create`, formData, {
    //     headers: headers,
    //   })
    await nodeCudApi.post(`/Tickets/Missing_Cashback_Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: TICKET_CREATE_SUCCESS
                });
                resetForm();
                navigation.navigate('MissingCashback')
                toast.show({ message: 'Ticket Created Successfully.', type: 'info', duration: 3000, position: 'top' });
                const formValue = {
                    User_Email_Id: formData.User_Email_Id,
                    Records_Filter: "FILTER",
                };
                dispatch(getbillreceipts(formValue))
            } else {
                toast.show({ message: successResponse.UI_Display_Message, type: 'info', duration: 3000, position: 'top' });
                const errors = {};
                dispatch({
                    type: TICKET_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: TICKET_CREATE_FAIL,
                payload: "Currently server is not working.Please try sometime later."
            });
        })
};


