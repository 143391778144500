import axios from "axios";
import { boomiApi } from "./commonAxios";
import config from './config';

export const MY_SERVICES_REQUEST = "MY_SERVICES_REQUEST";
export const MY_SERVICES_SUCCESS = "MY_SERVICES_SUCCESS";
export const MY_SERVICES_FAIL = "MY_SERVICES_FAIL";

export const getMyServices = (formData) => async (dispatch) => {
    dispatch({
        type: MY_SERVICES_REQUEST
    });
    boomiApi.post(`/User_Service_Request/Get?PageNo=0&PageSize=1000&SortBy=USER_SERVICE_ID&SortOrder=DESC`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.Success_Response?.Is_Data_Exist == "1") {
                dispatch({
                    type: MY_SERVICES_SUCCESS,
                    payload: successResponse.User_Service
                });
            } else {
                dispatch({
                    type: MY_SERVICES_FAIL
                });
            }
        })
        .catch(error => {
            dispatch({
                type: MY_SERVICES_FAIL
            });
        })
};

export const getSingleServiceDetails = (formData, responseFunction) => async (dispatch) => {

    boomiApi.post(`/User_Service/Single_Get`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.Success_Response?.Is_Data_Exist == "1") {
                responseFunction(true, successResponse);
            } else {
                responseFunction(false, {});
            }
        })
        .catch(error => {
            responseFunction(false, {});
        })
};

export const getCitys = (formData, responseFunction) => async (dispatch) => {

    boomiApi.post(`/User_Service/City_List_Get`, {
        "City name": formData
    })
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.Success_Response?.Is_Data_Exist == "1") {
                responseFunction(true, successResponse.User_Service_City_List_Get_Response, formData);
            } else {
                responseFunction(false, [], formData);
            }
        })
        .catch(error => {
            responseFunction(false, [], formData);
        })
};

export const getCityServices = (formData, responseFunction) => async (dispatch) => {

    boomiApi.post(`/User_Service/Get`, {
        "Zip_Code": formData.Zip_Code,
        "Records_Filter": "FILTER",
        "Service_Name": formData.Service_Name
    })
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.Success_Response?.Is_Data_Exist == "1") {
                responseFunction(true, successResponse.services);
            } else {
                responseFunction(false, []);
            }
        })
        .catch(error => {
            responseFunction(false, []);
        })
};

export const addService = (formData, responseFunction) => async (dispatch) => {

    boomiApi.post(`/User_Service_Request/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.Response_Status == "Success") {
                responseFunction(true, successResponse);
            } else {
                responseFunction(false, "Currently server is not working. Please try again later.");
            }
        })
        .catch(error => {
            responseFunction(false, "Currently server is not working. Please try again later.");
        })
};

export const ServiceInvoiceAction = (formData: any, updateStates: any) => async (dispatch) => {
    boomiApi.post(`/User_Service/Invoice_Get`, formData)
        .then(response => {
            let successResponse = response.data;
            updateStates(true, successResponse);
        })
        .catch(error => {
            updateStates(false, null);
        })
};

//Get Reviews and Ratings
export const getServiceRatingsAction = (formData: any, setAlldelireviewsandratings: any) => async (dispatch: any) => {
    try {
        let { data } = await boomiApi.post(
            `/User_Service/Review_And_Rating_Get`,
            formData,
        );
        if (data) {
            if (data.Is_Data_Exist === "0") {
                setAlldelireviewsandratings({ status: false });
            } else {
                setAlldelireviewsandratings({ status: true, data: data?.Service_Review_Rating || [] });
            }
        }
    }
    catch (err) {

    }
};


//Create Reviews and Ratings
export const servicesRiviewUpdateAction = (formData: any, toast: any, successCall: any) => async (dispatch: any) => {

    boomiApi.put(
        `/User_Service_Request/Update`,
        formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                toast.show({ message: 'Review added successfully.', type: 'info', duration: 3000, position: 'top' });
                successCall();
            } else {
                toast.show({ message: 'Failed to add Review, please try again.', type: 'info', duration: 3000, position: 'top' });
            }
        })
        .catch(error => {
            toast.show({ message: "Please try sometime later.Currently server is not working", type: 'info', duration: 3000, position: 'top' });
        })
};

//Create Reviews and Ratings
export const servicesUpdateAction = (formData: any, successCall: any) => async (dispatch: any) => {

    boomiApi.put(
        `/User_Service_Request/Update`,
        formData,
    )
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                successCall(true, response.data);
            } else {
                successCall(false, response.data);
            }
        })
        .catch(error => {
            successCall(false, "");
        })
};
