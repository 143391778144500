import axios from "axios";
import { boomiApi } from "./commonAxios";

export const ALL_GIFTS_REQUEST = "ALL_GIFTS_REQUEST";
export const ALL_GIFTS_GET = "ALL_GIFTS_GET";
export const ALL_GIFTS_FAIL = "ALL_GIFTS_FAIL";

export const ALL_IMAGE_GIFTS_REQUEST = "ALL_IMAGE_GIFTS_REQUEST";
export const ALL_IMAGE_GIFTS = "ALL_IMAGE_GIFTS";
export const ALL_IMAGE_GIFTS_FAIL = "ALL_IMAGE_GIFTS_FAIL";

export const ALL_ORDERED_GIFTS_REQUEST = "ALL_ORDERED_GIFTS_REQUEST";
export const ALL_ORDERED_GIFTS_GET = "ALL_ORDERED_GIFTS_GET";
export const ALL_ORDERED_GIFTS_FAIL = "ALL_ORDERED_GIFTS_FAIL";

export const ALL_RECEIVED_GIFTS_REQUEST = "ALL_RECEIVED_GIFTS_REQUEST";
export const ALL_RECEIVED_GIFTS_GET = "ALL_RECEIVED_GIFTS_GET";
export const ALL_RECEIVED_GIFTS_FAIL = "ALL_RECEIVED_GIFTS_FAIL";

export const GIFTS_CREATE_REQUEST = "GIFTS_CREATE_REQUEST";
export const GIFTS_CREATE_SUCCESS = "GIFTS_CREATE_SUCCESS";
export const GIFTS_CREATE_FAIL = "GIFTS_CREATE_FAIL";

export const getAllVendorsAction = (formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ALL_GIFTS_REQUEST,
    });
    let { data } = await boomiApi.post(
      `/Gift_Voucher/Vendor_Get?PageNo=0&PageSize=1000`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ALL_GIFTS_GET,
          payload: []
        });
      } else {
        dispatch({
          type: ALL_GIFTS_GET,
          payload: data.Gift_Voucher_Vendor
        });
      }
    }
  } catch (error) {
    dispatch({
      type: ALL_GIFTS_FAIL,
      payload: error
    });
  }
};
export const getGiftsImageAction = (formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ALL_IMAGE_GIFTS_REQUEST,
    });
    let { data } = await boomiApi.post(
      `/Gift_Voucher/Image_Get`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ALL_IMAGE_GIFTS,
          payload: data
        });
      } else {
        dispatch({
          type: ALL_IMAGE_GIFTS,
          payload: data.Image_Gift,
        });

      }
    }
  } catch (error) {
    dispatch({
      type: ALL_IMAGE_GIFTS_FAIL,
      payload: error
    });
  }
};
export const addGiftCardAction = (formData: any, navigation: any, toast: any,) => async (dispatch: any) => {
  dispatch({
    type: GIFTS_CREATE_REQUEST
  });
  await boomiApi.post(`/Gift_Voucher/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: GIFTS_CREATE_SUCCESS
        });
        // navigation.navigate('GiftCard')
        // toast.show({ message: 'Gifts Created Successfully.', type: 'info', duration: 3000, position: 'top' });
      } else {
        toast(successResponse.UI_Display_Message, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        dispatch({
          type: GIFTS_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: GIFTS_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};
export const getAllOrderedGiftAction = (formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ALL_ORDERED_GIFTS_REQUEST,
    });
    let { data } = await boomiApi.post(
      `/Gift_Voucher/Ordered_Gifts_Get`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ALL_ORDERED_GIFTS_GET,
          payload: data
        });
      } else {
        dispatch({
          type: ALL_ORDERED_GIFTS_GET,
          payload: data.GiftVoucherGet
        });
      }
    }
  } catch (error) {
    dispatch({
      type: ALL_ORDERED_GIFTS_FAIL,
      payload: error
    });
  }
};
export const getAllReceivedGiftsAction = (formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ALL_RECEIVED_GIFTS_REQUEST,
    });
    let { data } = await boomiApi.post(
      `/Gift_Voucher/Received_Gifts_get`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ALL_RECEIVED_GIFTS_GET,
          payload: data
        });
      } else {
        dispatch({
          type: ALL_RECEIVED_GIFTS_GET,
          payload: data.GiftVoucherGet
        });
      }
    }
  } catch (error) {
    dispatch({
      type: ALL_RECEIVED_GIFTS_FAIL,
      payload: error
    });
  }
};
