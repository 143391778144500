const back = require("../assets/icons/back.png");
const clock = require("../assets/icons/clock.png");
const flag_icon = require("../assets/icons/flagicon.png");
const focus = require("../assets/icons/focus.png");
const bike = require("../assets/icons/bike.png");
const car = require("../assets/icons/map-car.png");
export default {
    back,
    clock,
    flag_icon,
    focus,
    bike,
    car
}