import { ALL_GIFTS_FAIL, ALL_GIFTS_GET, ALL_GIFTS_REQUEST, ALL_IMAGE_GIFTS, ALL_IMAGE_GIFTS_FAIL, ALL_IMAGE_GIFTS_REQUEST, ALL_ORDERED_GIFTS_FAIL, ALL_ORDERED_GIFTS_GET, ALL_ORDERED_GIFTS_REQUEST, ALL_RECEIVED_GIFTS_FAIL, ALL_RECEIVED_GIFTS_GET, ALL_RECEIVED_GIFTS_REQUEST, GIFTS_CREATE_FAIL, GIFTS_CREATE_REQUEST, GIFTS_CREATE_SUCCESS } from "../actions/giftActions";

const initialState = {
    gifts: { all: [], error: '', isLoading: false },
    giftImages: { all: [], error: '', isLoading: false },
    orderedGifts: { all: [], error: '', isLoading: false },
    receivedGifts: { all: [], error: '', isLoading: false },
    giftsCreate: { gifts: {}, error: '', isLoading: false },
};
export default function (state = initialState, action: any) {
    switch (action.type) {
        case ALL_GIFTS_REQUEST:
            return { ...state, gifts: { all: state.gifts.all, error: '', isLoading: true } };
        case ALL_GIFTS_GET:
            return { ...state, gifts: { all: action.payload, error: '', isLoading: false } };
        case ALL_GIFTS_FAIL:
            return { ...state, gifts: { all: [], error: action.payload, isLoading: false } };

        case ALL_IMAGE_GIFTS_REQUEST:
            return { ...state, giftImages: { all: state.giftImages.all, error: '', isLoading: true } };
        case ALL_IMAGE_GIFTS:
            return { ...state, giftImages: { all: action.payload, error: '', isLoading: false } };
        case ALL_IMAGE_GIFTS_FAIL:
            return { ...state, giftImages: { all: [], error: action.payload, isLoading: false } };

        case ALL_ORDERED_GIFTS_REQUEST:
            return { ...state, orderedGifts: { all: state.orderedGifts.all, error: '', isLoading: true } };
        case ALL_ORDERED_GIFTS_GET:
            return { ...state, orderedGifts: { all: action.payload, error: '', isLoading: false } };
        case ALL_ORDERED_GIFTS_FAIL:
            return { ...state, orderedGifts: { all: [], error: action.payload, isLoading: false } };

        case ALL_RECEIVED_GIFTS_REQUEST:
            return { ...state, receivedGifts: { all: state.receivedGifts.all, error: '', isLoading: true } };
        case ALL_RECEIVED_GIFTS_GET:
            return { ...state, receivedGifts: { all: action.payload, error: '', isLoading: false } };
        case ALL_RECEIVED_GIFTS_FAIL:
            return { ...state, receivedGifts: { all: [], error: action.payload, isLoading: false } };

        case GIFTS_CREATE_REQUEST:
            return { ...state, giftsCreate: { gifts: {}, error: '', isLoading: true } };
        case GIFTS_CREATE_SUCCESS:
            return { ...state, giftsCreate: { gifts: action.payload, error: '', isLoading: false } };
        case GIFTS_CREATE_FAIL:
            return { ...state, giftsCreate: { gifts: {}, error: action.payload, isLoading: false } };
        default:
            return state;
    }
}