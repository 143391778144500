import {
    APPRATINGSANDREVIEWS_REQUEST,
    APPRATINGSANDREVIEWS_SUCCESS ,
   APPRATINGSANDREVIEWS_FAIL ,
     APPRATINGSANDREVIEWS_GET_REQUEST,
    APPRATINGSANDREVIEWS_GET_SUCCESS,
   APPRATINGSANDREVIEWS_GET_FAIL,
    APPRATINGSANDREVIEWS_CREATE_REQUEST,
    APPRATINGSANDREVIEWS_CREATE_SUCCESS,
   APPRATINGSANDREVIEWS_CREATE_FAIL,
  APPRATINGSANDREVIEWS_UPDATE_REQUEST,
APPRATINGSANDREVIEWS_UPDATE_SUCCESS,
APPRATINGSANDREVIEWS_UPDATE_FAIL,
} from '../actions/reviewsandratingsAction';

const initialState = {
    reviewandratings: { all: [], error: '', isLoading: false },
    reviewandrating: { reviewandrating: {}, error: '', isLoading: false },
    reviewandratingCreate: { reviewandrating: {}, error: '', isLoading: false },
    reviewandratingUpdate: { reviewandrating: {}, error: '', isLoading: false },
};
export default function (state = initialState, action:any) {
    switch (action.type) {
        case APPRATINGSANDREVIEWS_REQUEST:
            return { ...state, reviewandratings: { all: state.reviewandratings.all, error: '', isLoading: true } };
          case APPRATINGSANDREVIEWS_SUCCESS:
            return { ...state, reviewandratings: { all: action.payload, error: '', isLoading: false } };
          case APPRATINGSANDREVIEWS_FAIL:
            return { ...state, reviewandratings: { all: [], error: action.payload, isLoading: false } };
          case APPRATINGSANDREVIEWS_GET_REQUEST:
            return { ...state, reviewandrating: { reviewandrating: {}, error: '', isLoading: true } };
          case APPRATINGSANDREVIEWS_GET_SUCCESS:
            return { ...state, reviewandrating: { reviewandrating: action.payload, error: '', isLoading: false } };
          case APPRATINGSANDREVIEWS_GET_FAIL:
            return { ...state, reviewandrating: { reviewandrating: {}, error: action.payload, isLoading: false } };
    
          case APPRATINGSANDREVIEWS_CREATE_REQUEST:
            return { ...state, reviewandratingCreate: { reviewandrating: {}, error: '', isLoading: true } };
          case APPRATINGSANDREVIEWS_CREATE_SUCCESS:
            return { ...state, reviewandratingCreate: { reviewandrating: state.reviewandratingCreate.reviewandrating, error: '', isLoading: false } };
          case APPRATINGSANDREVIEWS_CREATE_FAIL:
            return { ...state, reviewandratingCreate: { reviewandrating: {}, error: action.payload, isLoading: false } };

            case APPRATINGSANDREVIEWS_UPDATE_REQUEST:
              return { ...state, reviewandratingUpdate: { reviewandrating: {}, error: '', isLoading: true } };
            case APPRATINGSANDREVIEWS_UPDATE_SUCCESS:
              return { ...state, reviewandratingUpdate: { reviewandrating: state.reviewandratingUpdate.reviewandrating, error: '', isLoading: false } };
            case APPRATINGSANDREVIEWS_UPDATE_FAIL:
              return { ...state, reviewandratingUpdate: { reviewandrating: {}, error: action.payload, isLoading: false } };
       
          default:
            return state;      
    }
}