import axios from "axios";
import { boomiApi } from "./commonAxios";

export const ALL_FINANCIAL_TRANSACTION_REQUEST = "ALL_FINANCIAL_TRANSACTION_REQUEST";
export const ALL_FINANCIAL_TRANSACTION_GET = "ALL_FINANCIAL_TRANSACTION_GET";
export const ALL_FINANCIAL_TRANSACTION_FAIL = "ALL_FINANCIAL_TRANSACTION_FAIL";
export const ALL_FINANCIAL_TRANSACTION_FILTER = "ALL_FINANCIAL_TRANSACTION_FILTER";

export const getAllFinancialTransactions = (formData:any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ALL_FINANCIAL_TRANSACTION_REQUEST,
    });
    let { data } = await boomiApi.post(
      `/Payments/User_Financial_Transaction_Get`,
      formData
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ALL_FINANCIAL_TRANSACTION_GET,
          payload: data
        });
      } else {
        dispatch({
          type: ALL_FINANCIAL_TRANSACTION_GET,
          payload: data.Store_Type_Details
        });
      }
    }
  } catch (error) {
    dispatch({
      type: ALL_FINANCIAL_TRANSACTION_FAIL,
      payload: error
    });
  }
};

export const financialTransactionFilter = (fType: any, value: any) => {
    return function (dispatch) {
      dispatch({
        type: ALL_FINANCIAL_TRANSACTION_FILTER,
        fType: fType,
        value: value,
      });
    }
  }

