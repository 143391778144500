import axios from "axios";
import { boomiApi } from "./commonAxios";
import config from "./config";

export const ALL_TRANSACTION_REQUEST = "ALL_TRANSACTION_REQUEST";
export const ALL_TRANSACTION_GET = "ALL_TRANSACTION_GET";
export const ALL_TRANSACTION_FAIL = "ALL_TRANSACTION_FAIL";

export const getAllTransactions = (formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ALL_TRANSACTION_REQUEST,
    });
    let { data } = await boomiApi.post(
      `/Order/Order_Transactions_Get`,
      formData,
    
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ALL_TRANSACTION_GET,
          payload: data
        });
      } else {
        dispatch({
          type: ALL_TRANSACTION_GET,
          payload: data.Transactions
        });
      }
    }
  } catch (error) {
    dispatch({
      type: ALL_TRANSACTION_FAIL,
      payload: error
    });
  }
};

