import {
    ALL_FARMERS_REQUEST, ALL_FARMERS_FAIL, SET_ALL_FARMERS_REQUEST
} from '../actions/farmerActions';

const initialState = {
    farmers: { all: [], error: '', isLoading: false },

}
export default function farmersReducer(state = initialState, action) {
    switch (action.type) {
        case ALL_FARMERS_REQUEST:
            return { ...state, farmers: { all: state.farmers.all, error: '', isLoading: true } };
        case SET_ALL_FARMERS_REQUEST:
            return { ...state, farmers: { all: action.payload, error: '', isLoading: false } };
        case ALL_FARMERS_FAIL:
            return { ...state, farmers: { all: [], error: action.payload, isLoading: false } };
        default:
            return state;
    }

}