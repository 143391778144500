import axios from "axios";
import { boomiApi } from "./commonAxios";
export const SET_ALL_CONTENTS = "SET_ALL_CONTENTS"
export const CONTENTRESET = "CONTENTRESET";

/*=============================================================
                  Get All Categories Action
===============================================================*/

export const getAllContentManagementStaticAction = (formData, responseFunction) => async (dispatch) => {
    try {
        let { data } = await boomiApi.post(
            `/ContentManagement/Static_Get_Admin?PageNo=0&PageSize=200&SortBy=NAME&SortOrder=ASC`,
            formData,
        );
        var Content_Management_StaticData = data.Content_Management_Static[0]
        if (data) {
            responseFunction(Content_Management_StaticData);
            dispatch({
                type: SET_ALL_CONTENTS,
                payload: Content_Management_StaticData,
            });
        } else {
            responseFunction({});
        }
    } catch (error) {
        responseFunction({});

    }
};

/*=============================================================
                  Get Category By Id Action
===============================================================*/

export const getContentByIdAction = (contentid, callBackGetData) => async () => {
    try {
        const formData = {
            Records_Filter: "FILTER",
            Cms_Static_Id: contentid,
        };

        let { data } = await boomiApi.post(
            `/ContentManagement/Static_Get?PageNo=0&PageSize=200&SortBy=NAME&SortOrder=ASC`,
            formData,
        );
        if (data) {
            callBackGetData(data);
        }
    } catch (error) {
    }
};
export const contentreset = () => async (dispatch) => {
    dispatch({
        type: CONTENTRESET,
        payload: []
    });
}
