import { boomiApi, nodeGetApi, urlGenarator } from "../commonAxios";


export const getAllBannersAction = (
    pagination = { PageNo: 1, PageSize: 20, SortBy: "TITLE", SortOrder: "ASC" },
    formData = { Records_Filter: "All", Banner_Status: "Active" },
    responseFunction: any
) => async (dispatch: any) => {

    nodeGetApi.post(urlGenarator('/Home/Banner/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, [], data?.Pagination);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(error.message == "Network Error" ? "network" : false, error);
    });
};

export const getProductByIdAction = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {
    nodeGetApi.post(urlGenarator(`/Home/Single_Partner_Products_Get`, pagination)
        ,
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getProductsAction = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    nodeGetApi.post(urlGenarator('/Products/GetProducts', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getCouponsAction = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    nodeGetApi.post(urlGenarator('/Products/GetCoupons', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getDealsAction = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    nodeGetApi.post(urlGenarator('/Products/GetDeals', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getPartnersAction = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    nodeGetApi.post(urlGenarator('/Home/OnlineAndPhysical_Details', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getShopByStoresAction = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {
    nodeGetApi.post(urlGenarator('/Store_Type/User_Store_Type_Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getShopByCitiesAction = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    boomiApi.post(`/Shop_By_City/City_List_Get`,
        formData
    ).then(response => {
        let data = response.data;
        if (data.City_List_Get?.length <= 0) {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.City_List_Get, data?.Pagination || pagination);
            } catch (error) {
                responseFunction(true, "");
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getBrandsAction = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    nodeGetApi.post(urlGenarator('/Home/Brand/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getCategoryAction = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    nodeGetApi.post(urlGenarator('/Home/Categories/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getSubCategoryAction = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    nodeGetApi.post(urlGenarator('/Home/SubCategories/Get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getvisitedProducts = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    nodeGetApi.post(urlGenarator('/Home/Single_Partner_Product_Get_New', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getLocationByIpAddress = (pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    nodeGetApi.get(urlGenarator('/Home/ip-geolocation', pagination),
    ).then(response => {
        let data = response.data;
        responseFunction(data);
    }).catch(error => {
        responseFunction(error);
    });
};


export const getAllNotification = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    nodeGetApi.post(urlGenarator('/Home/Notification_Get_User', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


export const getSingleCartItemCheck = (
    formData = {}, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    nodeGetApi.post(urlGenarator('/Home/user_cart/check', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        responseFunction(data ? true : false, data)
    }).catch(error => {
        responseFunction(false, error);
    });
};