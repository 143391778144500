import { useEffect, useState } from "react";
import {  Image, Platform, Text, View } from "react-native";
import { useDispatch } from "react-redux";
import { EsiAutocompleteDropdown } from "../../../components/custom/DropDowns/AutoCompleteDropDown";
import { getBiddingVehicalComfortTypes } from "../../../state/actions/node-actions/bid-rides-actions";

export const ComfortTypesFilters = (props) => {
    const { filters, setFilters } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [brands, setBrands] = useState([]);


    const callBackGetData = async (status, data) => {
        setLoading(false);
        if (status) {
            setBrands(data.map(item => ({ "id": item, "title": item, image: item })));
        } else {
            setBrands([]);
        }
    }


    const apiCall = (q) => {
        setLoading(true);
        dispatch(getBiddingVehicalComfortTypes({
            "Records_Filter": "FILTER",
            "Search": q,
            "NotEquelBrands": filters.map((item) => item.id) || []

        },
            { PageNo: 1, PageSize: 10, SortBy: "VEHICLE_COMFORT_TYPE", SortOrder: "ASC" },
            callBackGetData
        ));
    }

    useEffect(() => {
        apiCall("");
    }, [filters]);


    return (
        <View style={[{ marginHorizontal: 5 }, Platform.select({ ios: { zIndex: 100 } })]}>
            <View style={{ flexDirection: "row" }}>
                <Image style={{ width: 25, height: 25, marginRight: 10 }}
                    source={require('../../../assets/images/favicon.png')}
                />
                <Text style={{
                    fontSize: 14, color: "#03dac4",
                    marginTop: 3
                }}>Comfort Types</Text>
            </View>
            <EsiAutocompleteDropdown key={"comfort-filter"}
                loading={loading}
                data={brands}
                label={"Search Comfort Types"}
                onSelect={(item) => {
                    if (item?.id) {
                        setFilters([item]);
                    } else {
                        setFilters([]);
                    }
                }}
                onClear={() => {
                    setFilters([])
                }}
                getSuggestions={(q) => {
                    apiCall(q);
                }}
            />
        </View>
    )
}