import {
    REFER_CREATE_REQUEST,
    REFER_CREATE_SUCCESS,
    REFER_CREATE_FAIL,
    REFER_REQUEST,
    REFER_SUCCESS,
    REFER_FAIL,
    REFER_GET_REQUEST,
    REFER_GET_SUCCESS,
    REFER_GET_FAIL
} from '../actions/referActions'

const initialState = {

    refers: { all: [], error: '', isLoading: false },
    refer: { refer: [], error: '', isLoading: false },
    referCreate: { refer: {}, error: '', isLoading: false },
};
export default function (state = initialState, action:any) {
    switch (action.type) {
        case REFER_CREATE_REQUEST:
            return { ...state, referCreate: { refer: {}, error: '', isLoading: true } };
        case REFER_CREATE_SUCCESS:
            return { ...state, referCreate: { refer: action.payload, error: '', isLoading: false } };
        case REFER_CREATE_FAIL:
            return { ...state, referCreate: { refer: {}, error: action.payload, isLoading: false } };
            case REFER_REQUEST:
                return { ...state, refers: { all: state.refers.all, error: '', isLoading: true } };
              case REFER_SUCCESS:
                return { ...state, refers: { all: action.payload, error: '', isLoading: false } };
              case REFER_FAIL:
                return { ...state, refers: { all: [], error: action.payload, isLoading: false } };
          
              case REFER_GET_REQUEST:
                return { ...state, refer: { refer: {}, error: '', isLoading: true } };
              case REFER_GET_SUCCESS:
                return { ...state, refer: { refer: action.payload, error: '', isLoading: false } };
              case REFER_GET_FAIL:
                return { ...state, refer: { refer: {}, error: action.payload, isLoading: false } };
          
        default:
            return state;
    }
}

