import axios from "axios";
import { boomiApi } from "./commonAxios";
import config from './config';
export const REFER_REQUEST = "REFER_REQUEST";
export const REFER_SUCCESS = "REFER_SUCCESS";
export const REFER_FAIL = "REFER_FAIL";
export const REFER_GET_REQUEST = "REFER_GET_REQUEST";
export const REFER_GET_SUCCESS = "REFER_GET_SUCCESS";
export const REFER_GET_FAIL = "REFER_GET_FAIL";
export const REFER_CREATE_REQUEST = "REFER_CREATE_REQUEST";
export const REFER_CREATE_SUCCESS = "REFER_CREATE_SUCCESS";
export const REFER_CREATE_FAIL = "REFER_CREATE_FAIL";


export const getRefferals = (EmailID: any) => async dispatch => {
  try {
    let formData = {
      "Records_Filter": "FILTER",
      "User_Email_Id": EmailID
    }
    dispatch({
      type: REFER_REQUEST
    });
    let { data, status } = await boomiApi.post(
      `/Refer_Earn/Get?PageSize=500`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: REFER_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: REFER_SUCCESS,
          payload: data.Refer_Earn
        });
      }
    }
  } catch (err) {
    dispatch({
      type: REFER_FAIL,
      payload: err
    });
  }
};


export const createRefer = (formData: any, navigation: any, toast: any, EmailID: any,resetForm:any,setReload:any) => async dispatch => {
  dispatch({
    type: REFER_CREATE_REQUEST
  });
  await boomiApi.post(`/Refer_Earn/Create`, formData, )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: REFER_CREATE_SUCCESS
        });
        resetForm();
        setReload(true);
        navigation.navigate('earn')
        toast.show({ message: 'Refer Created Successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          Records_Filter: "FILTER",
          User_Email_Id: EmailID,
        };
        dispatch(getRefferals(EmailID));
      } else {
        toast.show({ message: successResponse.UI_Display_Message, type: 'info', duration: 3000, position: 'top' });
        dispatch({
          type: REFER_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }


    })
    .catch(error => {
      toast.show({ message: "Currently server is not working.Please try sometime later.", type: 'info', duration: 3000, position: 'top' });
      dispatch({
        type: REFER_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};




