import axios from "axios";
import { boomiApi } from "./commonAxios";

export const ALL_FARMERS_REQUEST = "ALL_FARMERS_REQUEST";
export const ALL_FARMERS_FAIL = "ALL_FARMERS_FAIL";
export const SET_ALL_FARMERS_REQUEST = "SET_ALL_FARMERS_REQUEST";


//to get farmer details

export const getsingleFarmersAction = (formDatas: any, callback: any) => async (dispatch: any) => {
    try {
        let { data } = await boomiApi.post(
            `/Farmer/Get`,
            formDatas);
        if (data.results.length > 0) {
            callback(true, data.results)
        } else {
            callback(false, [])

        }
    } catch (error) {
    }
};

//to get all farmers 
export const getFarmersActions = (filter: any, pagination: any, callback: any) => async () => {
    try {

        let { data } = await boomiApi.post(
            `/Farmer/Get?PageNo=${pagination?.PageNo}&PageSize=${pagination?.PageSize}&SortBy=${pagination?.SortBy}&SortOrder=${pagination?.SortOrder}`,
            filter
        );
        if (data) {
            callback(true, data.results, data.Pagination)
        }
    } catch (error) {
    }
};
export const getFarmersAction = () => async (dispatch: any) => {
    try {
        dispatch({
            type: ALL_FARMERS_REQUEST,
        });
        let formData = {
            search: "",
            search_by_filter: "ALL"
        }
        let { data } = await boomiApi.post(
            `/Farmer/Get`,
            formData
        );
        if (data) {
            dispatch({
                type: SET_ALL_FARMERS_REQUEST,
                payload: data?.results || [],
                payload1: data?.pagination || []
            });
        }
    } catch (error) {
        dispatch({
            type: ALL_FARMERS_FAIL,
            payload: error
        });
    }
};


export const getFarmersProdActions = (filter: any, pagination: any, callback: any) => async () => {
    try {

        let { data } = await boomiApi.post(
            `/Farmer_Partner_Product/Get?PageNo=${pagination?.PageNo}&PageSize=${pagination?.PageSize}&SortBy=${pagination?.SortBy}&SortOrder=${pagination?.SortOrder}`,
            filter,
        );
        if (data) {
            callback(true, data.results, data.Pagination)
        }
    } catch (error) {
    }
};

//to get farmer associated products

export const getFarmersProductsAction = (formDatas: any, callback: any) => async (dispatch: any) => {
    try {
        let { data } = await boomiApi.post(
            `/Farmer_Partner_Product/Get`,
            formDatas,
        );
        if (data.results.length > 0) {
            callback(true, data.results)
        } else {
            callback(false, [])

        }
    } catch (error) {
    }
};