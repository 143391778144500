import axios from "axios";
import { boomiApi } from "./commonAxios";

export const CITY_REQUEST = "CITY_REQUEST";
export const CITY_REQUEST_FAIL = "CITY_REQUEST_FAIL";

export const ALL_CITY_DEALS_REQUEST = "ALL_CITY_DEALS_REQUEST";
export const ALL_CITY_DEALS_FAIL = "ALL_CITY_DEALS_FAIL";
export const ALL_CITY_DEALS = "ALL_CITY_DEALS";

export const ALL_CITY_PRODUCTS_REQUEST = "ALL_CITY_PRODUCTS_REQUEST";
export const ALL_CITY_PRODUCTS_FAIL = "ALL_CITY_PRODUCTS_FAIL";
export const ALL_CITY_PRODUCTS = "ALL_CITY_PRODUCTS";

export const ALL_CITY_COUPONS_REQUEST = "ALL_CITY_COUPONS_REQUEST";
export const ALL_CITY_COUPONS_FAIL = "ALL_CITY_COUPONS_FAIL";
export const ALL_CITY_COUPONS = "ALL_CITY_COUPONS";

export const ALL_CITIES_REQUEST = "ALL_CITIES_REQUEST";
export const ALL_CITIES_REQUEST_SUCCESS = "ALL_CITIES_REQUEST_SUCCESS";
export const ALL_CITIES_REQUEST_FAIL = "ALL_CITIES_REQUEST_FAIL";

export const PARTNERS_BY_CITY_REQUEST = "PARTNERS_BY_CITY_REQUEST";
export const PARTNERS_BY_CITY_REQUEST_SUCCESS = "PARTNERS_BY_CITY_REQUEST_SUCCESS";
export const PARTNERS_BY_CITY_REQUEST_FAIL = "PARTNERS_BY_CITY_REQUEST_FAIL";

export const DEALS_BY_CITY_REQUEST = "DEALS_BY_CITY_REQUEST";
export const DEALS_BY_CITY_REQUEST_SUCCESS = "DEALS_BY_CITY_REQUEST_SUCCESS";
export const DEALS_BY_CITY_REQUEST_FAIL = "DEALS_BY_CITY_REQUEST_FAIL";

export const COUPONS_BY_CITY_REQUEST = "COUPONS_BY_CITY_REQUEST";
export const COUPONS_BY_CITY_REQUEST_SUCCESS = "COUPONS_BY_CITY_REQUEST_SUCCESS";
export const COUPONS_BY_CITY_REQUEST_FAIL = "COUPONS_BY_CITY_REQUEST_FAIL";

export const PRODUCTS_BY_CITY_REQUEST = "PRODUCTS_BY_CITY_REQUEST";
export const PRODUCTS_BY_CITY_REQUEST_SUCCESS = "PRODUCTS_BY_CITY_REQUEST_SUCCESS";
export const PRODUCTS_BY_CITY_REQUEST_FAIL = "PRODUCTS_BY_CITY_REQUEST_FAIL";

export const CITY_PARTNERRESET = "CITY_PARTNERRESET";

export const NOTIFY_BY_CITY_REQUEST = "NOTIFY_BY_CITY_REQUEST";
export const NOTIFY_BY_CITY_REQUEST_SUCCESS = "NOTIFY_BY_CITY_REQUEST_SUCCESS";
export const NOTIFY_BY_CITY_REQUEST_FAIL = "NOTIFY_BY_CITY_REQUEST_FAIL";

export const ALL_CITIES_FAIL = "ALL_CITIES_FAIL";


/*=============================================================
                  Get All Cities Action
===============================================================*/

export const getAllCitiesAction = (formData: any) => async dispatch => {
    try {
        let formData = {

        };

        dispatch({
            type: ALL_CITIES_REQUEST
        });
        let { data, status } = await boomiApi.post(
            `/Shop_By_City/City_List_Get`, formData);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: ALL_CITIES_REQUEST_SUCCESS,
                    payload: []
                });
            } else {

                dispatch({

                    type: ALL_CITIES_REQUEST_SUCCESS,
                    payload: data.City_List_Get
                });
            }
        }
    } catch (err) {

        dispatch({
            type: ALL_CITIES_REQUEST_FAIL,
            payload: err
        });
    }
};

/*=============================================================
                Get All Partners by City Action
===============================================================*/

export const getAllPartnersbyCitiesAction = (city_name: any) => async (dispatch: any) => {
    try {
        let formData = {
            Records_Filter: 'FILTER',
            City_Name: city_name
        };
        dispatch({
            type: PARTNERS_BY_CITY_REQUEST
        });
        let { data, status } = await boomiApi.post(
            `/Shop_By_City/Partners_Get_By_City_Name`,
            formData);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: PARTNERS_BY_CITY_REQUEST_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: PARTNERS_BY_CITY_REQUEST_SUCCESS,
                    payload: data.Partner_Details
                });
            }
        }
    } catch (err) {
        dispatch({
            type: PARTNERS_BY_CITY_REQUEST_FAIL,
            payload: err
        });
    }
};




export const NotifyCreateAction = (formData) => async (dispatch) => {
    dispatch({
        type: NOTIFY_BY_CITY_REQUEST,
    });
    boomiApi.post(`/User/Notify_Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: NOTIFY_BY_CITY_REQUEST_SUCCESS
                });
                toast('Thank you, we will notify you once our Partners are available in this City.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            } else {

                dispatch({
                    type: NOTIFY_BY_CITY_REQUEST_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: NOTIFY_BY_CITY_REQUEST_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};





export const getCouponsbyCitiesAction = (callback: any) => async (dispatch: any) => {
    try {
        let formData = {
        };
        let { data, status } = await boomiApi.post(
            `/Shop_By_City/City_List_Get`, formData);
        if (data) {
            callback(data.City_List_Get);
        }
    } catch (error) {
        dispatch({
            type: ALL_CITIES_FAIL,
        });
    }
};


export const getCityLocationsByFilters = (formData, responseFunction) => async (dispatch) => {
    try {
        let { data } = await boomiApi.post(
            `/Location_For_City/Location_For_City_Get`,
            formData,
        );
        if (data) {
            if (data?.Success_Response?.Response_Status == "Success") {
                if (data?.Success_Response?.Is_Data_Exist == "1") {
                    if (data?.Success_Response?.Is_Data_Exist == "1") {
                        if (data?.Location_For_City) {
                            responseFunction(true, data?.Location_For_City);
                        } else {
                            responseFunction(false, [], "Sorry, we are not serviceable at this location.");
                        }
                    } else {
                        responseFunction(false, [], "Sorry, we are not serviceable at this location.");
                    }
                } else {
                    responseFunction(false, [], "Sorry, we are not serviceable at this location.");
                }
            } else {
                responseFunction(false, [], "Sorry, we are not serviceable at this location.");
            }
        }
    } catch (error) {
    }
};
