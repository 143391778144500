import axios from "axios";
import Base64 from "../../hooks/Base64";
import { SET_ALL_ORDERS } from "../types";
import { AnySchema } from "yup";
import { boomiApi } from "./commonAxios";
export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";
export const ORDER_RETURN_UPDATE_REQUEST = "ORDER_RETURN_UPDATE_REQUEST";
export const ORDER_RETURN_UPDATE_SUCCESS = "ORDER_RETURN_UPDATE_SUCCESS";
export const ORDER_RETURN_UPDATE_FAIL = "ORDER_RETURN_UPDATE_FAIL";
export const ORDER_STATUS_UPDATE_REQUEST = "ORDER_STATUS_UPDATE_REQUEST";
export const ORDER_STATUS_UPDATE_SUCCESS = "ORDER_STATUS_UPDATE_SUCCESS";
export const ORDER_STATUS_UPDATE_FAIL = "ORDER_STATUS_UPDATE_FAIL";
export const ORDER_TRACK_REQUEST = "ORDER_TRACK_REQUEST";
export const ORDER_TRACK_SUCCESS = "ORDER_TRACK_SUCCESS";
export const ORDER_TRACK_FAIL = "ORDER_TRACK_FAIL";
export const ALL_ORDERS_REQUEST = "ALL_ORDERS_REQUEST";
export const ALL_ORDERS_SUCCESS = "ALL_ORDERS_SUCCESS";
export const ALL_ORDERS_FAIL = "ALL_ORDERS_FAIL";

/*=============================================================
               Order return update
===============================================================*/
export const getAllOrdersAction = (orderformData: any) => async (dispatch) => {
    try {
        dispatch({
            type: ALL_ORDERS_REQUEST,
        });

        let { data } = await boomiApi.post(`/Order/Get_By_Product_Item?PageNo=0&PageSize=5000`,
            orderformData);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: ALL_ORDERS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: ALL_ORDERS_SUCCESS,
                    payload: data.Orders.Order_Data
                });
            }
        }
    }
    catch (error) {
        dispatch({
            type: ALL_ORDERS_FAIL,
            payload: error
        });
    }
};
export const getSingleOrdersAction = (orderformData: any, responseFunction: any) => async (dispatch) => {
    try {

        let { data } = await boomiApi.post(`/Order/Order_Item_Master_Get`, orderformData);
        if (data) {
            responseFunction(data);
        }
    }
    catch (error) {

    }
};

export const getSingleNonRetailOrdersAction = (orderformData: any, responseFunction: any) => async (dispatch) => {
    try {

        let { data } = await boomiApi.post(`/Food_Grocery_Single_order_Get/Food_Grocery_Single_order_Get`, orderformData);
        if (data) {
            if (data.Is_Data_Exist == "0") {

            } else {
                responseFunction(data);
            }
        }
    }
    catch (error) {

    }
};
/*=============================================================
               OrderStatusUpdate
===============================================================*/
export const OrderStatusUpdateAction = (formData: any, data: any, navigation: any, setSubmitReturn: any) => async (dispatch) => {
    dispatch({
        type: ORDER_STATUS_UPDATE_REQUEST,
    });
    boomiApi.put(`/Order/Status_Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: ORDER_STATUS_UPDATE_SUCCESS
                });
                if (formData.Status == "CANCELLED") {
                    setSubmitReturn(true, 'Your order cancelled successfully.');
                } else if (formData.Status == "RETURN") {
                    setSubmitReturn(true, 'Your return request is initiated.');
                } else {
                    setSubmitReturn(true, 'Order status updated successfully..');
                }
            } else {
                setSubmitReturn(false, successResponse.UI_Display_Message);
                dispatch({
                    type: ORDER_STATUS_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: ORDER_STATUS_UPDATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};
export const OrderReturnUpdateAction = (formData, updateStates, Id) => async (dispatch) => {
    dispatch({
        type: ORDER_RETURN_UPDATE_REQUEST,
    });
    boomiApi.put(`/Order/Status_Update`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: ORDER_RETURN_UPDATE_SUCCESS
                });
                let formvalues = {
                    Order_Id: Id,
                    Order_Status: "",
                    Records_Filter: "FILTER",
                    User_Email_Id: "",
                }
                dispatch(getAllOrdersAction(updateStates, formvalues));
            } else {

                dispatch({
                    type: ORDER_RETURN_UPDATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: ORDER_STATUS_UPDATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};

/*=============================================================
                  Get Tracking Details
===============================================================*/

export const getOrderTrackingDetails = (formData, updateStates) => async (dispatch) => {
    boomiApi.post(`/Order_Status/Order_Status_Tracking`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.Success_Response?.Response_Status == "Success") {
                updateStates(true, successResponse?.Order_Status);
            } else {
                updateStates(false, {});
            }
        })
        .catch(error => {
            updateStates(false, {});
        })
};

/*=============================================================
                  Get All Deals Action
===============================================================*/

export const getOrderdetailAction = (formData: any) => async (dispatch) => {
    try {
        dispatch({
            type: ORDER_DETAILS_REQUEST,
        });

        let { data } = await boomiApi.post(`/User/Address_Get`, formData);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                // toast('User addres not found for This order.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                dispatch({
                    type: ORDER_DETAILS_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: ORDER_DETAILS_SUCCESS,
                    payload: data.User_Address[0]
                });
            }
        }
    }
    catch (error) {
        dispatch({
            type: ORDER_DETAILS_FAIL,
            payload: error
        });
    }
};


/*=============================================================
                  Get Order tracking
===============================================================*/

export const getOrdertrackAction = (formData: any) => async (dispatch) => {
    try {
        dispatch({
            type: ORDER_TRACK_REQUEST,
        });

        let { data } = await boomiApi.post(`/Order/Status_Tracking`, formData);
        if (data) {
            if (data.Is_Data_Exist === '0') {
                dispatch({
                    type: ORDER_TRACK_SUCCESS,
                    payload: []
                });
            } else {
                dispatch({
                    type: ORDER_TRACK_SUCCESS,
                    payload: data.Order_Status[0]
                });
            }
        }
    }
    catch (error) {
        dispatch({
            type: ORDER_TRACK_FAIL,
            payload: error
        });
    }
};

export const OrderInvoiceAction = (formData: any, updateStates: any) => async (dispatch) => {
    boomiApi.post(`/Order/Invoice_Get`, formData)
        .then(response => {
            let successResponse = response.data;
            updateStates(true, successResponse);
        })
        .catch(error => {
            updateStates(false, null);
        })
};