import { nodeGetApi as meeapi, urlGenarator } from "../commonAxios";

export const getMfhZones = (
    pagination = { PageNo: 1, PageSize: 20, SortBy: "TITLE", SortOrder: "ASC" },
    formData = { Records_Filter: "All", Banner_Status: "Active" },
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/MFH/zones-get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, [], data?.Pagination);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(error.message == "Network Error" ? "network" : false, error);
    });
};

export const getMFHSThemes = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {
    meeapi.post(urlGenarator('/MFH/theme-get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getMFHProducts = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/MFH/GetProducts', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getMFHSubProducts = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/MFH/GetSubProducts', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};