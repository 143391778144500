import {
    USERUPI_REQUEST,
    USERUPI_SUCCESS,
    USERUPI_FAIL,
    USERUPI_GET_REQUEST,
    USERUPI_GET_SUCCESS,
    USERUPI_GET_FAIL,

    USERUPI_UPDATE_REQUEST,
    USERUPI_UPDATE_SUCCESS,
    USERUPI_UPDATE_FAIL,
} from '../actions/userupiActions';

const initialState = {

    userupis: { all: [], error: '', isLoading: false },
    userupi: { userupi: [], error: '', isLoading: false },
   upiUpdate: { userupi: {}, error: '', isLoading: false },
};

export default function (state = initialState, action: any) {
    switch (action.type) {
        case USERUPI_REQUEST:
            return { ...state, userupis: { all: state.userupis.all, error: '', isLoading: true } };
        case USERUPI_SUCCESS:
            return { ...state, userupis: { all: action.payload, error: '', isLoading: false } };
        case USERUPI_FAIL:
            return { ...state, userupis: { all: [], error: action.payload, isLoading: false } };

        case USERUPI_GET_REQUEST:
            return { ...state, userupi: { userupi: {}, error: '', isLoading: true } };
        case USERUPI_GET_SUCCESS:
            return { ...state, userupi: { userupi: action.payload, error: '', isLoading: false } };
        case USERUPI_GET_FAIL:
            return { ...state, userupi: { userupi: {}, error: action.payload, isLoading: false } };

        case USERUPI_UPDATE_REQUEST:
            return { ...state, upiUpdate: { userupi: {}, error: '', isLoading: true } };
            
        case USERUPI_UPDATE_SUCCESS:
            return { ...state, upiUpdate: { userupi: state.upiUpdate.userupi, error: '', isLoading: false } };
        case USERUPI_UPDATE_FAIL:
            return { ...state, upiUpdate: { userupi: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}