import axios from "axios";
import config from './config';
import { boomiApi, nodeCudApi } from "./commonAxios";
export const APPRATINGSANDREVIEWS_REQUEST = "APPRATINGSANDREVIEWS_REQUEST";
export const APPRATINGSANDREVIEWS_SUCCESS = "APPRATINGSANDREVIEWS_SUCCESS";
export const APPRATINGSANDREVIEWS_FAIL = "APPRATINGSANDREVIEWS_FAIL";
export const APPRATINGSANDREVIEWS_GET_REQUEST = "APPRATINGSANDREVIEWS_GET_REQUEST";
export const APPRATINGSANDREVIEWS_GET_SUCCESS = "APPRATINGSANDREVIEWS_GET_SUCCESS";
export const APPRATINGSANDREVIEWS_GET_FAIL = "APPRATINGSANDREVIEWS_GET_FAIL";
export const APPRATINGSANDREVIEWS_CREATE_REQUEST = "APPRATINGSANDREVIEWS_CREATE_REQUEST";
export const APPRATINGSANDREVIEWS_CREATE_SUCCESS = "APPRATINGSANDREVIEWS_CREATE_SUCCESS";
export const APPRATINGSANDREVIEWS_CREATE_FAIL = "APPRATINGSANDREVIEWS_CREATE_FAIL";
export const APPRATINGSANDREVIEWS_UPDATE_REQUEST = "APPRATINGSANDREVIEWS_UPDATE_REQUEST";
export const APPRATINGSANDREVIEWS_UPDATE_SUCCESS = "APPRATINGSANDREVIEWS_UPDATE_SUCCESS";
export const APPRATINGSANDREVIEWS_UPDATE_FAIL = "APPRATINGSANDREVIEWS_UPDATE_FAIL";

// ===============================
//  Get Reviews and Ratings
// ================================
export const getAllAppReviewsandRatingsAction = (formData: any) => async dispatch => {
  try {

    let formData = {
      "Records_Filter": "FILTER",
      "Status": "Active",
    };

    dispatch({
      type: APPRATINGSANDREVIEWS_REQUEST
    });
    let { data } = await boomiApi.post(
      `/AppRatingsAndReviews/Get?PageNo=0&PageSize=${10000}`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: APPRATINGSANDREVIEWS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: APPRATINGSANDREVIEWS_SUCCESS,
          payload: data.Testimonials
        });
      }
    }
  } catch (err) {
    dispatch({
      type: APPRATINGSANDREVIEWS_FAIL,
      payload: err
    });
  }
};

// ===============================
//  Create Reviews and Ratings
// ================================
export const CreateAppReviewsandRatings = (formData: any, navigation: any, returnResponse: Function) => async dispatch => {
  dispatch({
    type: APPRATINGSANDREVIEWS_CREATE_REQUEST
  });
  // await axios.post(`${config.url}/AppRatingsAndReviews/Create`, formData, {
  //   headers: headers,
  // })
  await nodeCudApi.post(`/AppRatingsAndReviews/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: APPRATINGSANDREVIEWS_CREATE_SUCCESS
        });
        returnResponse(true, "");
        let formData = {
          Record_Filter: "FILTER",
          Status: "Active",
        }
        dispatch(getAllAppReviewsandRatingsAction(formData));

      } else {
        const errors = {};
        returnResponse(false, successResponse.UI_Display_Message);
        dispatch({
          type: APPRATINGSANDREVIEWS_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: APPRATINGSANDREVIEWS_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};

// ================================
//  Update Reviews and Ratings
// ================================
export const UpdateAppReviewsandRatings = (formData: any, updateResponse: any) => async (dispatch: any) => {
  dispatch({
    type: APPRATINGSANDREVIEWS_UPDATE_REQUEST
  });
  // axios.put(`${config.url}/AppRatingsAndReviews/Update`, formData, {
  //   headers: headers,
  // })
  nodeCudApi.put(`/AppRatingsAndReviews/Update`, formData)
    .then(response => {
      let successResponse = response.data;

      if (successResponse.Response_Status == "Success") {
        updateResponse(true, "");
        dispatch({
          type: APPRATINGSANDREVIEWS_UPDATE_SUCCESS
        });
        let formData = {
          Record_Filter: "FILTER",
          Status: "Active",
        }
        dispatch(getAllAppReviewsandRatingsAction(formData));

      } else {
        updateResponse(false, successResponse.UI_Display_Message);
        dispatch({
          type: APPRATINGSANDREVIEWS_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      updateResponse(false, "Currently server is not working.Please try sometime later.");
      dispatch({
        type: APPRATINGSANDREVIEWS_UPDATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};