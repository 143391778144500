import {
    ALL_NEWSEVENTS_REQUEST,
    ALL_NEWSEVENTS_FAIL,
    GET_SINGLE_NEWSEVENT,
    GET_SINGLE_NEWSEVENT_REQUEST,
    GET_SINGLE_NEWSEVENT_FAIL,
    CONTENTRESET,
    SET_ALL_NEWSEVENTS,
    
} from '../actions/newsandeventsActions';

const initialState = {
    newsevents: { all: [], error: '', isLoading: false },
    newsevent: { newsevent: [], error: '', isLoading: false },

};

export default function newseventsReducer(state = initialState, action) {
    switch (action.type) {
        case ALL_NEWSEVENTS_REQUEST:
            return { ...state, newsevents: { all: state.newsevents.all, error: '', isLoading: true } };
        case SET_ALL_NEWSEVENTS:
            return { ...state, newsevents: { all: action.payload, error: '', isLoading: false } };
        case ALL_NEWSEVENTS_FAIL:
            return { ...state, newsevents: { all: [], error: action.payload, isLoading: false } };
        case GET_SINGLE_NEWSEVENT_REQUEST:
            return { ...state, newsevent: { newsevent: state.newsevent.newsevent, error: '', isLoading: true } };
        case GET_SINGLE_NEWSEVENT:
            return { ...state, newsevent: { newsevent: action.payload, error: '', isLoading: false } };
        case GET_SINGLE_NEWSEVENT_FAIL:
            return { ...state, newsevent: { newsevent: [], error: action.payload, isLoading: false } };
        case CONTENTRESET:
            return {
                newsevents: { all: [], error: '', isLoading: false },
                newsevent: { newsevent: [], error: '', isLoading: false },
            };
        default:
            return state;
    }
}
