import { SET_ALL_STORE_CATEGORIES, SET_ALL_STORE_TYPE, STORECATEGORYRESET, STORETYPERESET } from "../actions/shopbystoretypecategoriesAction";

const initialState = {
    allCategories: [],
    allStoretype: []
};

export default function storecategoriesReducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_ALL_STORE_CATEGORIES:
            return {
                ...state,
                allCategories: action.payload,
            };

        case STORECATEGORYRESET:
            return {
                ...state, allCategories: []
            };

        case SET_ALL_STORE_TYPE:
            return {
                ...state,
                allStoretype: action.payload,
            };

        case STORETYPERESET:
            return {
                ...state, allStoretype: []
            };

        default:
            return state;
    }
}