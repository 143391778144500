import axios from "axios";
import Base64 from "../../hooks/Base64";
import { SET_ALL_ORDERS } from "../types";
import { boomiApi } from "./commonAxios";

export const ALL_ORDERS_REQUEST = "ALL_ORDERS_REQUEST";
export const ALL_ORDERS_SUCCESS = "ALL_ORDERS_SUCCESS";
export const ALL_ORDERS_FAIL = "ALL_ORDERS_FAIL";
export const ALL_ORDERS_FILTER = "ALL_ORDERS_FILTER";
export const ORDERS_REQUEST = "ORDERS_REQUEST";
export const ORDERS_SUCCESS = "ORDERS_SUCCESS";
export const ORDERS_FAIL = "ORDERS_FAIL";
export const PRODUCT_REVIEW_CREATE_REQUEST = "PRODUCT_REVIEW_CREATE_REQUEST";
export const PRODUCT_REVIEW_CREATE_SUCCESS = "PRODUCT_REVIEW_CREATE_SUCCESS";
export const PRODUCT_REVIEW_CREATE_FAIL = "PRODUCT_REVIEW_CREATE_FAIL";
export const PRODUCT_REVIEW_UPDATE_REQUEST = "PRODUCT_REVIEW_UPDATE_REQUEST";
export const PRODUCT_REVIEW_UPDATE_SUCCESS = "PRODUCT_REVIEW_UPDATE_SUCCESS";
export const PRODUCT_REVIEW_UPDATE_FAIL = "PRODUCT_REVIEW_UPDATE_FAIL";
export const USERSREVIEW_REQUEST = "USERSREVIEW_REQUEST";
export const USERSREVIEW_SUCCESS = "USERSREVIEW_SUCCESS";
export const USERSREVIEW_FAIL = "USERSREVIEW_FAIL";


/*=============================================================
               get all user product reviews 
===============================================================*/

export const getAllUserReviewAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: USERSREVIEW_REQUEST,
    });
    let { data } = await boomiApi.post(`/Reviews/Product_Review_Get?PageNo=0&PageSize=1000&SortBy=NAME&SortOrder=ASC`, formData
    );
    if (data) {
      if (data.Is_Data_Exist === "0") {
        dispatch({
          type: USERSREVIEW_SUCCESS,
          payload: data,
        });
      }

      else {
        dispatch({
          type: USERSREVIEW_SUCCESS,
          payload: data.Product_Review,
        });
      }
    }
  } catch (error) {
    dispatch({
      type: USERSREVIEW_FAIL,
      payload: error
    });
  }
};
/*=============================================================
                  Get All Deals Action
===============================================================*/

export const getAllOrdersAction = (formData: any) => async (dispatch) => {
  try {
    dispatch({
      type: ALL_ORDERS_REQUEST,
    });

    let { data } = await boomiApi.post(`/Order/Get_By_Product_Item?PageNo=0&PageSize=5000`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ALL_ORDERS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: ALL_ORDERS_SUCCESS,
          payload: data.Orders.Order_Data
        });
      }
    }
  }
  catch (error) {
    dispatch({
      type: ALL_ORDERS_FAIL,
      payload: error
    });
  }
};


export const getFoodGroceriesOrdersAction = (formData: any, responseFunction: any) => async (dispatch) => {
  try {
    let { data } = await boomiApi.post(`/Order/Food_Grocery_Get?PageNo=0&PageSize=5000`, formData);
    if (data) {
      if (data.Success_Response.Is_Data_Exist == '1') {
        responseFunction(data?.FG_Orders || [])
      }
    }
  }
  catch (error) {

  }
};

/*=============================================================
                  Get single order Action
===============================================================*/

export const getSingleOrdersAction = (formData: any, responseFunction: any) => async (dispatch) => {
  try {
    let { data } = await boomiApi.post(`/Order/Get_By_Product_Item?PageNo=0&PageSize=5000`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        responseFunction(false, []);
      } else {
        responseFunction(true, data.Orders.Order_Data);
      }
    }
  }
  catch (error) {
    responseFunction(false, []);
  }
};

export const getOrdersAction =
  (updateStates, formData) => async (dispatch, state) => {
    try {
      dispatch({
        type: ORDERS_REQUEST,
      });

      let { data } = await boomiApi.post(`/Order/Get_By_Product_Item?PageNo=0&PageSize=1000&SortBy=NAME&SortOrder=ASC`, formData);
      if (data) {
        if (data.Is_Data_Exist === '0') {
          dispatch({
            type: ORDERS_SUCCESS,
            payload: []
          });
        } else {
          dispatch({
            type: ORDERS_SUCCESS,
            payload: data.Orders.Order_Data
          });
        }
      }
    }
    catch (error) {
      dispatch({
        type: ORDERS_FAIL,
        payload: error
      });
    }
  };
/*=============================================================
              product reviews and rating
===============================================================*/

export const createprodutctAction = (formData, state) => async dispatch => {

  dispatch({
    type: PRODUCT_REVIEW_CREATE_REQUEST,
  });
  boomiApi.post(`/Reviews/Product_Review_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PRODUCT_REVIEW_CREATE_SUCCESS
        });

        toast('Review added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
        dispatch(getAllOrdersAction());

      } else {

        dispatch({
          type: PRODUCT_REVIEW_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: PRODUCT_REVIEW_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

/*=============================================================
                update product user review action
===============================================================*/


export const updateUserAction = (formData, state, navigate, setErrorMessage, setErrors) => async dispatch => {
  dispatch({
    type: PRODUCT_REVIEW_UPDATE_REQUEST
  });
  setErrorMessage({ Mobile: { key: '', message: '' }, global: { key: '', message: '' } });
  boomiApi.put(`/Reviews/Product_Review_Update`, formData,
  )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: PRODUCT_REVIEW_UPDATE_SUCCESS
        });
        navigate('/app/product/user-reviews', state = { state });
      } else {
        const errors = {};
        if (successResponse.Mobile) {
          errors.Mobile = successResponse.UI_Display_Message;
        }
        setErrors(errors)
        setErrorMessage({ Mobile: { key: successResponse.Mobile ? successResponse.Mobile : '', message: successResponse.Mobile ? successResponse.UI_Display_Message : '' }, global: { key: '', message: !(successResponse.Mobile) ? successResponse.UI_Display_Message : '' } });

        dispatch({
          type: PRODUCT_REVIEW_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      setErrorMessage({ Mobile: { key: '', message: '' }, global: { key: '', message: "Currently server is not working. Please try again later." } });
      dispatch({
        type: PRODUCT_REVIEW_UPDATE_FAIL,
        payload: "Currently server is not working. Please try again later."
      });
    })
};



export const ordersFilter = (fType: any, value: any) => {
  return function (dispatch) {
    dispatch({
      type: ALL_ORDERS_FILTER,
      fType: fType,
      value: value,
    });
  }
}