import React, { useEffect, useState } from "react";
import { Dimensions, TouchableOpacity, View } from "react-native";
import { Surface } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import TrackingMap from "../../components/custom/map/TrackingMap/TrackingMap";
import { getOrderTrackingDetails } from "../../state/actions/orderDetailsAction";
import isEmpty from "../../state/validations/is-empty";
import Header from "../header";

export default function DeliveryTracking(props: any) {
    const dispatch = useDispatch();
    const EmailID = useSelector(state => state.auth.user?.Email_Id);
    const [loading, setLoading] = useState(false);
    const returnOrderTrackingDetails = (status, data) => {
        setLoading(false);
        let haveData = true;
        if (status) {
            let delTrack = data?.Delivery_Live_Location[0];
            let userTrack = { Latitude: data?.Shipping_Details[0]?.Latitude, Longitude: data?.Shipping_Details[0]?.Longitude };
            if (delTrack?.Latitude && delTrack?.Longitude) {
                setDeliveryTracking({ latitude: Number(delTrack?.Latitude), longitude: Number(delTrack?.Longitude) });
            } else {
                haveData = false;
            }
            if (isEmpty(userTracking)) {
                if (userTrack?.Latitude && userTrack?.Longitude) {
                    setUserTracking({ latitude: Number(userTrack.Latitude), longitude: Number(userTrack.Longitude) });
                } else if (delTrack?.Latitude && delTrack?.Longitude) {
                    setUserTracking({ latitude: Number(delTrack?.Latitude), longitude: Number(delTrack?.Longitude) });
                }
            }
        }
        setSeconds(5);
    }


    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : !loading ? getOrderTrackingDetailsLocal(userTrackingID) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);


    const [deliveryTracking, setDeliveryTracking] = useState({});
    const [userTracking, setUserTracking] = useState({});
    const [userTrackingID, setUserTrackingID] = useState("");

    const getOrderTrackingDetailsLocal = (id) => {
        setLoading(true);
        if (id.split("-").length == 2) {
            dispatch(getOrderTrackingDetails({
                "Order_ID": id
            }, returnOrderTrackingDetails));
        } else {
            dispatch(getOrderTrackingDetails({
                "Order_Line_Item_Id": id
            }, returnOrderTrackingDetails));
        }
        setSeconds(-1);

    }


    useEffect(() => {
        let data = props?.route?.params;
        if (data?.ID && !isEmpty(data?.ID) && !isEmpty(EmailID) && !loading) {
            getOrderTrackingDetailsLocal(data?.ID);
            setUserTrackingID(data?.ID);
        } else {
            setUserTrackingID("");
            props.navigation.navigate("Home");
        }
    }, [props?.route?.params])
    return (
        <Surface style={{ flex: 1 }} >
            <Header {...props} />
            {(!isEmpty(userTracking) && !isEmpty(deliveryTracking)) &&
                <TrackingMap origin={deliveryTracking} destination={userTracking} navigation={props.navigation} />}
        </Surface>
    )
}
