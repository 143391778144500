import { SET_ALL_PRODUCTS } from "../types";
import {
  ALL_PRODUCTS_REQUEST,
  ALL_PRODUCTS_FAIL,
  PRODUCTRESET,
  ALL_PRODUCTS_BRAND_REQUEST,
  ALL_PRODUCTS_PARTNER_REQUEST,
  SET_ALL_FILTER_PARTNER_PRODUCTS,
  SET_ALL_FILTER_BRAND_PRODUCTS,
  ALL_IMAGE_PRODUCTS_REQUEST,
  ALL_IMAGE_PRODUCTS,
  ALL_IMAGE_PRODUCTS_FAIL,
  ALL_USER_REVIEWS_REQUEST,
  ALL_USER_REVIEWS,
  ALL_USER_REVIEWS_FAIL,
  ALL_PARTNER_PRODUCTS_REQUEST,
  ALL_PARTNER_PRODUCTS,
  ALL_PARTNER_PRODUCTS_FAIL,
  SET_ALL_PARTNER_PRODUCTS,
  REVIEW_CREATE_REQUEST,
  REVIEW_CREATE_SUCCESS,
  REVIEW_CREATE_FAIL,
  REVIEW_UPDATE_REQUEST,
  REVIEW_UPDATE_SUCCESS,
  REVIEW_UPDATE_FAIL,
  SET_ALL_RELATED_PRODUCTS,
  ALL_RELATED_PRODUCTS_REQUEST,
  ALL_RELATED_PRODUCTS_FAIL,
  SET_ALL_RELATED,
  ALL_RELATED_REQUEST,
  ALL_RELATED_FAIL,
  ALL_COLOR_REQUEST,
  SET_ALL_COLOR,
  ALL_COLOR_FAIL,
  PRODUCTSIZERESET,
  ALL_SIZE_REQUEST,
  SET_ALL_SIZE,
  ALL_SIZE_FAIL,
  // ALL_PRODUCTS_SUB_CATEGORY_REQUEST,
  // SET_ALL_FILTER_SUB_CATEGORY_PRODUCTS,
  ALL_VISITED_REQUEST,
  SET_ALL_VISITED,
  ALL_VISITED_FAIL,
  VISITEDPRODUCTRESET,
  VISITED_PRODUCTS_SUCCESS,
  VISITED_PRODUCTS_REQUEST,
  ALL_PRODUCTS_BRAND_FAIL,

} from '../actions/productsActions'

const initialState = {
  products: { all: [], error: '', isLoading: false },
  brandproducts: { all: {}, error: '', isLoading: false },
  productImages: { all: [], error: '', isLoading: false },
  productReviews: { all: [], error: '', isLoading: false },
  partnerproducts: { all: [], error: '', isLoading: false },
  // subcategoryproduct: { all: [], error: '', isLoading: false },
  productsRelated: { all: [], error: '', isLoading: false },
  related: { all: [], error: '', isLoading: false },
  colors: { all: [], error: '', isLoading: false },
  reviewsCreate: { review: {}, error: '', isLoading: false },
  reviewsUpdate: { review: {}, error: '', isLoading: false },
  sizes: { all: [], error: '', isLoading: false },
  visited: { all: [], error: '', isLoading: false },
  vProducts: []

};

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_PRODUCTS_REQUEST:
      return { ...state, products: { all: [], error: '', isLoading: true } };
    case SET_ALL_PRODUCTS:
      return { ...state, products: { all: action.payload, error: '', isLoading: false } };
    case ALL_PRODUCTS_FAIL:
      return { ...state, products: { all: [], error: action.payload, isLoading: false } };
    case ALL_IMAGE_PRODUCTS_REQUEST:
      return { ...state, productImages: { all: state.productImages.all, error: '', isLoading: true } };
    case ALL_IMAGE_PRODUCTS:
      return { ...state, productImages: { all: action.payload, error: '', isLoading: false } };
    case ALL_IMAGE_PRODUCTS_FAIL:
      return { ...state, productImages: { all: [], error: action.payload, isLoading: false } };
    case ALL_USER_REVIEWS_REQUEST:
      return { ...state, productReviews: { all: state.productReviews.all, error: '', isLoading: true } };
    case ALL_USER_REVIEWS:
      return { ...state, productReviews: { all: action.payload, error: '', isLoading: false } };
    case ALL_USER_REVIEWS_FAIL:
      return { ...state, productReviews: { all: [], error: action.payload, isLoading: false } };
    case ALL_PARTNER_PRODUCTS_REQUEST:
      return { ...state, partnerproducts: { all: state.partnerproducts.all, error: '', isLoading: true } };
    case ALL_PARTNER_PRODUCTS:
      return { ...state, partnerproducts: { all: action.payload, error: '', isLoading: false } };
    case ALL_PARTNER_PRODUCTS_FAIL:
      return { ...state, partnerproducts: { all: [], error: action.payload, isLoading: false } };
    case SET_ALL_PARTNER_PRODUCTS:
      return { ...state, partnerproducts: { all: action.payload, error: '', isLoading: false } };
    case ALL_PRODUCTS_PARTNER_REQUEST:
      return { ...state, partnerproducts: { all: state.partnerproducts.all, error: '', isLoading: true } };
    case SET_ALL_FILTER_PARTNER_PRODUCTS:
      return { ...state, partnerproducts: { all: action.payload, error: '', isLoading: false } };
    case SET_ALL_FILTER_BRAND_PRODUCTS:
      return { ...state, brandproducts: { all: action.payload, error: '', isLoading: false } };
    case ALL_PRODUCTS_BRAND_REQUEST:
      return { ...state, brandproducts: { all: {}, error: '', isLoading: true } };
      case ALL_PRODUCTS_BRAND_FAIL:
        return { ...state, brandproducts: { all: {}, error: '', isLoading: false } };
    // case SET_ALL_FILTER_SUB_CATEGORY_PRODUCTS:
    //   return { ...state, subcategoryproducts: { all: action.payload, error: '', isLoading: false } };
    // case ALL_PRODUCTS_SUB_CATEGORY_REQUEST:
    //   return { ...state, subcategoryproducts: { all: state.subcategoryproducts.all, error: '', isLoading: true } };
    case ALL_RELATED_PRODUCTS_REQUEST:
      return { ...state, productsRelated: { all: state.productsRelated.all, error: '', isLoading: true } };
    case SET_ALL_RELATED_PRODUCTS:
      return { ...state, productsRelated: { all: action.payload, error: '', isLoading: false } };
    case ALL_RELATED_PRODUCTS_FAIL:
      return { ...state, productsRelated: { all: [], error: action.payload, isLoading: false } };
    case ALL_RELATED_REQUEST:
      return { ...state, related: { all: state.related.all, error: '', isLoading: true } };
    case SET_ALL_RELATED:
      return { ...state, related: { all: action.payload, error: '', isLoading: false } };
    case ALL_RELATED_FAIL:
      return { ...state, related: { all: [], error: action.payload, isLoading: false } };
    case ALL_COLOR_REQUEST:
      return { ...state, colors: { all: state.colors.all, error: '', isLoading: true } };
    case SET_ALL_COLOR:
      return { ...state, colors: { all: action.payload, error: '', isLoading: false } };
    case ALL_COLOR_FAIL:
      return { ...state, colors: { all: [], error: action.payload, isLoading: false } };
    case REVIEW_CREATE_REQUEST:
      return { ...state, reviewsCreate: { review: {}, error: '', isLoading: true } };
    case REVIEW_CREATE_SUCCESS:
      return { ...state, reviewsCreate: { review: state.reviewsCreate.review, error: '', isLoading: false } };
    case REVIEW_CREATE_FAIL:
      return { ...state, reviewsCreate: { review: {}, error: action.payload, isLoading: false } };
    case REVIEW_UPDATE_REQUEST:
      return { ...state, reviewsUpdate: { review: {}, error: '', isLoading: true } };
    case REVIEW_UPDATE_SUCCESS:
      return { ...state, reviewsUpdate: { review: state.reviewsUpdate.review, error: '', isLoading: false } };
    case REVIEW_UPDATE_FAIL:
      return { ...state, reviewsUpdate: { review: {}, error: action.payload, isLoading: false } };
    case ALL_SIZE_REQUEST:
      return { ...state, sizes: { all: state.sizes.all, error: '', isLoading: true } };
    case SET_ALL_SIZE:
      return { ...state, sizes: { all: action.payload, error: '', isLoading: false } };
    case ALL_SIZE_FAIL:
      return { ...state, sizes: { all: [], error: action.payload, isLoading: false } };

    case ALL_VISITED_REQUEST:
      return { ...state, visited: { all: state.visited.all, error: '', isLoading: true } };
    case SET_ALL_VISITED:
      return { ...state, visited: { all: action.payload, error: '', isLoading: false } };
    case ALL_VISITED_FAIL:
      return { ...state, visited: { all: [], error: action.payload, isLoading: false } };

    case VISITEDPRODUCTRESET:
      return { ...state, visited: { all: [], error: '', isLoading: false } };
    case PRODUCTRESET:
      return {
        ...state, products: { all: action.payload, error: '', isLoading: false }
      };
    case PRODUCTSIZERESET:
      return {
        ...state,
        sizes: { all: [], error: '', isLoading: false },
      };
    case VISITED_PRODUCTS_REQUEST:
      return {
        ...state,
        vProducts: [],
      };
    case VISITED_PRODUCTS_SUCCESS:
      return {
        ...state,
        vProducts: action.payload,
      };
    default:
      return state;
  }
}