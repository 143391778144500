import axios from 'axios';
import { nodeGetApi } from './commonAxios';

export const ALL_SEARCH_REQUEST = "ALL_SEARCH_REQUEST";
export const ALL_SEARCH__GET = "ALL_SEARCH__GET";
export const ALL_SEARCH__FAIL = "ALL_SEARCH__FAIL";

/*=============================================================
                 Profile Action
===============================================================*/


export const search_products_new = (formData: any, responseData: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: ALL_SEARCH_REQUEST,
        });
        let { data } = await nodeGetApi.post(
            `/Products/Search`,
            formData
        );

        //   let dataresponse = { data: data, form: formData, type: "Filters" }

        if (data) {
            responseData(data?.results || []);
            dispatch({
                type: ALL_SEARCH__GET,
                payload: data?.results || [],
            });
        }

    } catch (error) {
        responseData([]);
        dispatch({
            type: ALL_SEARCH__FAIL,
            payload: error
        });
    }
};

// export const search_products_new = (params) => {
// 	return _REQUEST2NODE_SERVER(`Products/Search`, params);
// }


export const searchFilter = (value: any) => {
    return function (dispatch: any) {
        dispatch({
            type: ALL_SEARCH_REQUEST,
            value: value,
        });
    }

}
