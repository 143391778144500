import {
    ALL_FAQS_REQUEST,
    ALL_FAQS_FAIL,
    SET_ALL_FAQS,
    GET_SINGLE_FAQ,
    GET_SINGLE_FAQ_REQUEST,
    GET_SINGLE_FAQ_FAIL,
    CONTENTRESET,
} from '../actions/faqActions'
// import { CONTENTRESET } from "../actions/faqActions"
const initialState = {
    faqs: { all: [], error: '', isLoading: false },
    faq: { faq: [], error: '', isLoading: false },
};
export default function faqReducer(state = initialState, action) {
    switch (action.type) {
        case ALL_FAQS_REQUEST:
            return { ...state, faqs: { all: state.faqs.all, error: '', isLoading: true } };
        case SET_ALL_FAQS:
            return { ...state, faqs: { all: action.payload, error: '', isLoading: false } };
        case ALL_FAQS_FAIL:
            return { ...state, faqs: { all: [], error: action.payload, isLoading: false } };
        case GET_SINGLE_FAQ_REQUEST:
            return { ...state, faq: { faq: state.faq.faq, error: '', isLoading: true } };
        case GET_SINGLE_FAQ:
            return { ...state, faq: { faq: action.payload, error: '', isLoading: false } };
        case GET_SINGLE_FAQ_FAIL:
            return { ...state, faq: { faq: [], error: action.payload, isLoading: false } };
        case CONTENTRESET:
            return {
                faqs: { all: [], error: '', isLoading: false },
                faq: { faq: [], error: '', isLoading: false },
            };
        default:
            return state;
    }
}
