import axios from "axios";
import { boomiApi, nodeCudApi } from "./commonAxios";
export const ALL_TICKET_REQUEST = "ALL_TICKET_REQUEST";
export const ALL_TICKET_SUCCESS = "ALL_TICKET_SUCCESS";
export const ALL_TICKET_FAIL = "ALL_TICKET_FAIL";

export const TICKET_REQUEST = "TICKET_REQUEST";
export const TICKET_SUCCESS = "TICKET_SUCCESS";
export const TICKET_FAIL = "TICKET_FAIL";

export const ALL_TICKET_FILTER = "ALL_TICKET_FILTER";

export const ALL_COMMENTS_REQUEST = "ALL_COMMENTS_REQUEST";
export const ALL_COMMENTS_SUCCESS = "ALL_COMMENTS_SUCCESS";
export const ALL_COMMENTS_FAIL = "ALL_COMMENTS_FAIL";

export const COMMENTS_REQUEST = "COMMENTS_REQUEST";
export const COMMENTS_SUCCESS = "COMMENTS_SUCCESS";
export const COMMENTS_FAIL = "COMMENTS_FAIL";

export const TICKET_CREATE_REQUEST = "TICKET_CREATE_REQUEST";
export const TICKET_CREATE_SUCCESS = "TICKET_CREATE_SUCCESS";
export const TICKET_CREATE_FAIL = "TICKET_CREATE_FAIL";

export const COMMENTS_CREATE_REQUEST = "COMMENTS_CREATE_REQUEST";
export const COMMENTS_CREATE_SUCCESS = "COMMENTS_CREATE_SUCCESS";
export const COMMENTS_CREATE_FAIL = "COMMENTS_CREATE_FAIL";

/*=============================================================
                  Get All Tickets Action
===============================================================*/
export const getalltickets = (formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: ALL_TICKET_REQUEST
    });
    let { data } = await boomiApi.post(`/User_Ticket/Get?PageNo=0&PageSize=1000&SortBy=TITLE&SortOrder=ASC`,
      formData);
    if (data) {

      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ALL_TICKET_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: ALL_TICKET_SUCCESS,
          payload: data.User_Ticket
        });
      }
    }
  } catch (err) {
    dispatch({
      type: ALL_TICKET_FAIL,
      payload: err
    });
  }
};


/*=============================================================
                  Get All Tickets Action
===============================================================*/
export const GetAllComments = (formData: any, callBackGetData: any) => async (dispatch: any) => {

  try {
    dispatch({
      type: ALL_COMMENTS_REQUEST
    });
    let { data } = await boomiApi.post(`/User_Ticket_Comment/Get?PageNo=0&PageSize=1000&SortBy=TITLE&SortOrder=ASC`,
      formData);
    if (data) {

      if (data.Is_Data_Exist === '0') {
        callBackGetData([]);
        dispatch({
          type: ALL_COMMENTS_SUCCESS,
          payload: []
        });
      } else {
        callBackGetData(data.User_Ticket_Comment)
        dispatch({
          type: ALL_COMMENTS_SUCCESS,
          payload: data.User_Ticket_Comment
        });
      }
    }
  } catch (err) {
    callBackGetData([]);
    dispatch({
      type: ALL_TICKET_FAIL,
      payload: err
    });
  }
};
/*=============================================================
                 Filter Action
===============================================================*/
export const ticketFilter = (fType: any, value: any) => {
  return function (dispatch) {
    dispatch({
      type: ALL_TICKET_FILTER,
      fType: fType,
      value: value,
    });
  }
}

export const getTicketsByIdAction = (getid: any, callBackGetData: any) => async dispatch => {
  try {
    const formData = {
      Records_Filter: "FILTER",
      Ticket_Id: getid,
    };

    let { data } = await boomiApi.post(
      `/User_Ticket_Comment/Get?PageNo=0&PageSize=1000&SortBy=TITLE&SortOrder=ASC`,
      formData,
    );
    if (data) {

      if (data.Is_Data_Exist === "0") {

        callBackGetData([])

      } else {

        callBackGetData(data.User_Ticket_Comment)

      }

    }
  } catch (error) {
    dispatch({
      type: ALL_TICKET_FAIL,
      payload: error
    });
  }

};
/*=============================================================
                 file upload Action
===============================================================*/
export const uploadAttachment = (imageData, fileData, callBackUploadFile) => async dispatch => {

  try {
    let { data } = await boomiApi.post(
      `/User_Upload/Upload?functionality=User_Tickets&fileextension=${fileData.ext}&filetype=document&filename=${fileData.fileName}`,

      imageData,
      {
        transformRequest: (d) => d,
      }
    );
    if (data) {

      callBackUploadFile(data);
    }
  } catch (err) {

  }
};


/*=============================================================
                Create Ticket Action
===============================================================*/
export const AddTicketsAction = (formData: any, navigation: any, toast: any, resetForm: any, EmailID: any) => async dispatch => {
  dispatch({
    type: TICKET_CREATE_REQUEST
  });
  // await axios.post(`${config.url}/User_Ticket/Create`, formData, {
  //   headers: headers,
  // })
  await nodeCudApi.post(`/User_Ticket/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: TICKET_CREATE_SUCCESS
        });
        resetForm();
        navigation.navigate('Ticket')
        toast.show({ message: 'Ticket Created Successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          Records_Filter: "FILTER",
          Created_By: EmailID
        };
        dispatch(getalltickets(formValue))
      } else {
        const errors = {};
        dispatch({
          type: TICKET_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: TICKET_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};


/*=============================================================
                Create Ticket comment Action
===============================================================*/
export const AddTicketcommentAction = (formData: any, getid: any, resetForm: any, setReload: any, callBackGetData: any) => async dispatch => {
  dispatch({
    type: COMMENTS_CREATE_REQUEST
  });
  // await axios.post(`${config.url}/User_Ticket_Comment/Create`, formData, {
  //   headers: headers,
  // })
  await nodeCudApi.post(`/User_Ticket_Comment/Create`, formData, )
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {

        dispatch({
          type: COMMENTS_CREATE_SUCCESS
        });
        resetForm();
        setReload(true);
        const formValue = {
          Records_Filter: "FILTER",
          Ticket_Id: getid
        };
        dispatch(GetAllComments(formValue, callBackGetData));
      } else {
        const errors = {};
        dispatch({
          type: COMMENTS_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {

      dispatch({
        type: COMMENTS_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};


export const getallTicketByIdAction = (formData, callBackData: any) => async () => {
  try {
    let { data } = await boomiApi.post(
      `/User_Ticket/Get?PageNo=0&PageSize=1000&SortBy=TITLE&SortOrder=ASC`,
      formData,
    );
    if (data) {
      if (data.Is_Data_Exist === "0") {
        callBackData([])
      } else {
        callBackData(data.User_Ticket)
      }
    }
  } catch (error) {

  }

};
