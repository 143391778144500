import axios from "axios";
import { boomiApi } from "./commonAxios";
import config from './config';

export const MY_RIDE_REQUEST = "MY_RIDE_REQUEST";
export const MY_RIDE_SUCCESS = "MY_RIDE_SUCCESS";
export const MY_RIDE_FAIL = "MY_RIDE_FAIL";

export const getMyRiders = (formData) => async (dispatch) => {
    dispatch({
        type: MY_RIDE_REQUEST
    });
    boomiApi.post(`/User_Ride/user_ride?PageNo=0&PageSize=1000&SortBy=USER_RIDE_ID&SortOrder=DESC`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.Success_Response?.Is_Data_Exist == "1") {
                dispatch({
                    type: MY_RIDE_SUCCESS,
                    payload: successResponse.User_Ride_Get_Response
                });
            } else {
                dispatch({
                    type: MY_RIDE_FAIL
                });
            }
        })
        .catch(error => {
            dispatch({
                type: MY_RIDE_FAIL
            });
        })
};

export const getSingleRideDetails = (formData, responseFunction) => async (dispatch) => {

    boomiApi.post(`/User_Ride/Single_Ride_Get`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.Success_Response?.Is_Data_Exist == "1") {
                responseFunction(true, successResponse);
            } else {
                responseFunction(false, {});
            }
        })
        .catch(error => {
            responseFunction(false, {});
        })
};

export const getRiders = (formData, responseFunction) => async (dispatch) => {

    boomiApi.post(`/User_Ride/Get_City_List`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.Success_Response?.Is_Data_Exist == "1") {
                responseFunction(true, successResponse.User_Ride_Get_City_List_Response)
            }
        })
        .catch(error => {

        })
};

export const addRide = (formData, responseFunction) => async (dispatch) => {

    boomiApi.post(`/User_Ride/User_Ride_Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.Response_Status == "Success") {
                responseFunction(true, successResponse);
            } else {

            }
        })
        .catch(error => {

        })
};

export const RideInvoiceAction = (formData: any, updateStates: any) => async (dispatch) => {
    boomiApi.post(`/User_Ride/Invoice_Generation`, formData)
        .then(response => {
            let successResponse = response.data;
            updateStates(true, successResponse);
        })
        .catch(error => {
            updateStates(false, null);
        })
};

//Get Reviews and Ratings
export const getRideRatingsAction = (formData: any, setAlldelireviewsandratings: any) => async (dispatch: any) => {
    try {
        let { data } = await boomiApi.post(
            `/User_Ride/User_Ride_Review_Get`,
            formData,
        );
        if (data) {
            if (data.Is_Data_Exist === "0") {
                setAlldelireviewsandratings({ status: false });
            } else {
                setAlldelireviewsandratings({ status: true, data: data?.Ride_Rating || [] });
            }
        }
    }
    catch (err) {

    }
};


//Create Reviews and Ratings
export const rideRiviewUpdateAction = (formData: any, toast: any, successCall: any) => async (dispatch: any) => {

    boomiApi.put(
        `/User_Ride/user_ride_update`,
        formData,
        )
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                toast.show({ message: 'Review added successfully.', type: 'info', duration: 3000, position: 'top' });
                successCall();
            } else {
                toast.show({ message: 'Failed to add Review, please try again.', type: 'info', duration: 3000, position: 'top' });
            }
        })
        .catch(error => {
            toast.show({ message: "Please try sometime later.Currently server is not working", type: 'info', duration: 3000, position: 'top' });
        })
};

//Create Reviews and Ratings
export const rideUpdateAction = (formData: any, successCall: any) => async (dispatch: any) => {

    boomiApi.put(
        `/User_Ride/user_ride_update`,
        formData,
      )
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                successCall(true, response.data);
            } else {
                successCall(false, response.data);
            }
        })
        .catch(error => {
            successCall(false, "");
        })
};

export const getRideTrackingDetails = (formData, updateStates) => async (dispatch) => {
    boomiApi.post(`/User_Ride/Status_Tracking_Get`, formData, {
     
    })
        .then(response => {
            let successResponse = response.data;
            if (successResponse?.Success_Response?.Response_Status == "Success") {
                updateStates(true, successResponse);
            } else {
                updateStates(false, {});
            }
        })
        .catch(error => {
            updateStates(false, {});
        })
};