import { MY_RIDE_FAIL, MY_RIDE_REQUEST, MY_RIDE_SUCCESS } from "../actions/ridesAction";

const initialState = { all: [], isLoading: false };
export default function ridesReducer(state = initialState, action: any) {
    switch (action.type) {
        case MY_RIDE_REQUEST:
            return { ...state, isLoading: true };
        case MY_RIDE_SUCCESS:
            return { ...state, isLoading: false, all: action.payload };
        case MY_RIDE_FAIL:
            return { ...state, isLoading: false };
        default:
            return state;
    }
}