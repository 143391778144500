import React from "react";
import { View } from "react-native";
import { Text } from "../../components/Themed";

export default function NoDataFound({ text }) {
  return (
    <View className="ec-no-data-found-text-block">
      <Text className="poppins-font-18-bold">{text}</Text>
    </View>
  );
}

NoDataFound.defaultProps = {
  text: "No Data Found",
};
