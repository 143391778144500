import {
    ADDRESS_CREATE_REQUEST,
    ADDRESS_CREATE_SUCCESS,
    ADDRESS_CREATE_FAIL,
    ADDRESS_UPDATE_REQUEST,
    ADDRESS_UPDATE_SUCCESS,
    ADDRESS_UPDATE_FAIL,
    ADDRESS_DELETE_REQUEST,
    ADDRESS_DELETE_SUCCESS,
    ADDRESS_DELETE_FAIL,
    ADDRESS_REQUEST,
    ADDRESS_SUCCESS,
    ADDRESS_FAIL,
    ADDRESS_GET_REQUEST,
    ADDRESS_GET_SUCCESS,
    ADDRESS_GET_FAIL
} from '../actions/addressActions'

const initialState = {
    addresses: { all: [], error: '', isLoading: false },
    address: { address: [], error: '', isLoading: false },
    addressCreate: { address: {}, error: '', isLoading: false },
    addressUpdate: { address: {}, error: '', isLoading: false },
};
export default function (state = initialState, action: any) {
    switch (action.type) {

        case ADDRESS_CREATE_REQUEST:
            return { ...state, addressCreate: { address: {}, error: '', isLoading: true } };
        case ADDRESS_CREATE_SUCCESS:
            return { ...state, addressCreate: { address: action.payload, error: '', isLoading: false } };
        case ADDRESS_CREATE_FAIL:
            return { ...state, addressCreate: { address: {}, error: action.payload, isLoading: false } };

        case ADDRESS_UPDATE_REQUEST:
            return { ...state, addressUpdate: { address: {}, error: '', isLoading: true } };
        case ADDRESS_UPDATE_SUCCESS:
            return { ...state, addressUpdate: { address: action.payload, error: '', isLoading: false } };
        case ADDRESS_UPDATE_FAIL:
            return { ...state, addressUpdate: { address: {}, error: action.payload, isLoading: false } };

        case ADDRESS_DELETE_REQUEST:
            return { ...state, addressDelete: { address: {}, error: '', isLoading: true } };
        case ADDRESS_DELETE_SUCCESS:
            return { ...state, addressDelete: { address: action.payload, error: '', isLoading: false } };
        case ADDRESS_DELETE_FAIL:
            return { ...state, addressDelete: { address: {}, error: action.payload, isLoading: false } };

        case ADDRESS_REQUEST:
            return { ...state, addresses: { all: [], error: '', isLoading: true } };
        case ADDRESS_SUCCESS:
            return { ...state, addresses: { all: action.payload, error: '', isLoading: false } };
        case ADDRESS_FAIL:
            return { ...state, addresses: { all: [], error: action.payload, isLoading: false } };

        case ADDRESS_GET_REQUEST:
            return { ...state, address: { address: {}, error: '', isLoading: true } };
        case ADDRESS_GET_SUCCESS:
            return { ...state, address: { address: action.payload, error: '', isLoading: false } };
        case ADDRESS_GET_FAIL:
            return { ...state, address: { address: {}, error: action.payload, isLoading: false } };

        default:
            return state;
    }
}


