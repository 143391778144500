import { useEffect, useState } from "react";
import { ActivityIndicator, Image, Platform, Text, View } from "react-native";
import { useDispatch } from "react-redux";
import { EsiAutocompleteDropdown } from "../../../components/custom/DropDowns/AutoCompleteDropDown";
import { getCategoryAction, getSubCategoryAction } from "../../../state/actions/node-actions/home-actions";

export const CategoryFilters = (props) => {
    const { filters, setFilters } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState([]);
    const [selected, setSelected] = useState(null);

    const [subCategory, setSubCategory] = useState([]);
    const [loadingS, setLoadingS] = useState(false);
    const [selectedS, setSelectedS] = useState(null);

    const callBackGetData = async (status, data) => {
        setLoading(false);
        if (status) {
            setCategory(data.map(item => ({ "id": item.Category_Id, "title": item.Category_Name })));
        } else {
            setCategory([]);
        }
        if (seconds == -2) {
            setSelected(data.map(item => ({ "id": item.Category_Id, "title": item.Category_Name }))[0]);
            setSeconds(2);
        }
    }

    const [seconds, setSeconds] = useState(-1);
    useEffect(() => {
        if (seconds != -2) {
            const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
            return () => clearTimeout(timer)
        }
        else {
            setLoading(true);
            dispatch(getCategoryAction({
                "Records_Filter": "FILTER",
                "Category_Id": filters?.categoryId
            },
                { PageNo: 1, PageSize: 10, SortBy: "CATEGORY_NAME", SortOrder: "ASC" },
                callBackGetData
            ));
        }
    }, [seconds]);

    const [secondsS, setSecondsS] = useState(-1);
    useEffect(() => {
        if (secondsS != -2) {
            const timer = setTimeout(() => secondsS > 0 ? setSecondsS(secondsS - 1) : setSecondsS(-1), 1000)
            return () => clearTimeout(timer)
        }
        else {
            setLoadingS(true);
            setLoadingS(true);
            dispatch(getSubCategoryAction({
                "Records_Filter": "FILTER",
                "Category_Id": "",
                "Sub_Category_Id": filters.subCategoryId
            },
                { PageNo: 1, PageSize: 10, SortBy: "SUB_CATEGORY_NAME", SortOrder: "ASC" },
                callBackSubGetData
            ));
        }

    }, [secondsS]);

    const callBackSubGetData = async (status, data) => {
        setLoadingS(false);
        if (status) {
            setSubCategory(data.map(item => ({ "id": item.Sub_Category_Id, "title": item.Sub_Category_Name, "cid": item.Category_Id, "ctitle": item.Category_Name || "Meepaisa" })));
        } else {
            setSubCategory([]);
        }
        if (secondsS == -2) {
            setSelectedS(data.map(item => ({ "id": item.Sub_Category_Id, "title": item.Sub_Category_Name, "cid": item.Category_Id, "ctitle": item.Category_Name || "Meepaisa" }))[0]);
            setSecondsS(2);
        }
    }

    const apiCall = (q) => {
        setLoading(true);
        dispatch(getCategoryAction({
            "Records_Filter": "FILTER",
            "Search_Category_Name": q
        },
            { PageNo: 1, PageSize: 10, SortBy: "CATEGORY_NAME", SortOrder: "ASC" },
            callBackGetData
        ));
    }
    const apiCallS = (q) => {
        setLoadingS(true);
        dispatch(getSubCategoryAction({
            "Records_Filter": "FILTER",
            "Search_Sub_Category_Name": q,
            "Category_Id": selected ? selected.id : ""
        },
            { PageNo: 1, PageSize: 10, SortBy: "SUB_CATEGORY_NAME", SortOrder: "ASC" },
            callBackSubGetData
        ));
    }

    useEffect(() => {
        if (filters?.subCategoryId != selectedS?.id && filters?.subCategoryId) {
            setSecondsS(-2);
        }
        else if (filters?.categoryId != selected?.id && filters?.categoryId) {
            setSeconds(-2);
        } else {
            if (category.length <= 0 || !(selected?.id))
                apiCall("");
            if (subCategory.length <= 0 || !(selectedS?.id))
                apiCallS("");
        }
    }, [filters]);



    return (
        <View style={[{ marginHorizontal: 5 }, Platform.select({ ios: { zIndex: 100 } })]}>
            <View style={{ flexDirection: "row" }}>
                <Image style={{ width: 25, height: 25, marginRight: 10 }}
                    source={require('../../../assets/images/favicon.png')}
                />
                <Text style={{
                    fontSize: 14, color: "#03dac4",
                    marginTop: 3
                }}>Category</Text>
            </View>
            {seconds <= 0 ? <EsiAutocompleteDropdown
                loading={loading}
                data={category}
                label={"Search Category"}
                onSelect={(item) => {
                    if (item?.id != selected?.id) {
                        setSelectedS(null);
                        setSecondsS(2);
                        apiCallS("");

                    }
                    setSelected(item);
                    if (item?.id) {
                        if (item?.id != selected?.id)
                            setFilters((props => {
                                return { ...props, subCategoryId: "", categoryId: item.id }
                            }))
                    } else {

                    }

                }}
                onClear={() => {
                    setFilters((props => {
                        return { ...props, subCategoryId: "", categoryId: "" }
                    }))
                }}
                selected={selected}
                getSuggestions={(q) => {
                    apiCall(q);
                }}
            /> : <ActivityIndicator />}
            <View style={{ marginTop: 5 }} >
                <View style={{ flexDirection: "row" }}>
                    <Image style={{ width: 25, height: 25, marginRight: 10 }}
                        source={require('../../../assets/images/favicon.png')}
                    />
                    <Text style={{
                        fontSize: 14, color: "#03dac4",
                        marginTop: 3
                    }}>Sub Category</Text>
                </View>
                {secondsS <= 0 ?
                    <EsiAutocompleteDropdown
                        loading={loadingS}
                        data={subCategory}
                        label={"Search Sub Category"}
                        onSelect={(item) => {
                            setSelectedS(item);
                            if (!selected?.id && item) {
                                setSeconds(2);
                                setCategory([{ id: item.cid, title: item.ctitle }]);
                                setSelected({ id: item.cid, title: item.ctitle });
                            }
                            if (item?.id) {
                                setFilters((props => {
                                    return { ...props, subCategoryId: item.id, categoryId: item.cid }
                                }))
                            }
                        }}
                        selected={selectedS}
                        getSuggestions={(q) => {
                            apiCallS(q);
                        }}
                        onClear={() => {
                            setFilters((props => {
                                return { ...props, subCategoryId: "" }
                            }))
                        }}
                    /> : <ActivityIndicator />}
            </View>
        </View>
    )
}