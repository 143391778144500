import {
    ALL_CASHBACK_REQUEST,
    ALL_CASHBACK_SUCCESS,
    ALL_CASHBACK_FAIL,

    CASHBACK_REQUEST,
    CASHBACK_SUCCESS,
    CASHBACK_FAIL,

    ALL_CASHBACK_FILTER,
    
    ALL_VISITORS_REQUEST,
    ALL_VISITORS_SUCCESS,
    ALL_VISITORS_FAIL,

    ALL_VISITOR_REQUEST,
    ALL_VISITOR_SUCCESS,
    ALL_VISITOR_FAIL,
    TICKET_CREATE_REQUEST,
     TICKET_CREATE_SUCCESS,
    TICKET_CREATE_FAIL,
} from '../actions/missingcashbackActions'


const initialState = {
    Cashbacks: { all: [], error: '', isLoading: false },
    Cashback: { all: [], error: '', isLoading: false },
    cashbackFilter: { select: 'All', search: '' },
    partners: { partner: [], error: '', isLoading: false },
    partner: { partners: [], error: '', isLoading: false },
    CashbackCreate: { partners: {}, error: '', isLoading: false },

};

export default function cashbackReducer(state = initialState, action) {
    switch (action.type) {
       
        case TICKET_CREATE_REQUEST:
            return { ...state, CashbackCreate: { partners: {}, error: '', isLoading: true } };
        case TICKET_CREATE_SUCCESS:
            return { ...state, CashbackCreate: { partners: action.payload, error: '', isLoading: false } };
        case TICKET_CREATE_FAIL:
            return { ...state, CashbackCreate: { partners: {}, error: action.payload, isLoading: false } };
 
            case ALL_VISITORS_REQUEST:
            return { ...state, partners: { partner: state.partners.partner, error: '', isLoading: true }, };
          case ALL_VISITORS_SUCCESS:
            return { ...state, partners: { partner: action.payload, error: '', isLoading: false } };
          case ALL_VISITORS_FAIL:
            return { ...state, partners: { partner: [], error: action.payload, isLoading: false } };    
            case ALL_VISITOR_REQUEST:
                return { ...state, partner: { partners: state.partner.partners, error: '', isLoading: true }, };
              case ALL_VISITOR_SUCCESS:
                return { ...state, partner: { partners: action.payload, error: '', isLoading: false } };
              case ALL_VISITOR_FAIL:
                return { ...state, partner: { partners: [], error: action.payload, isLoading: false } };    
            
            case ALL_CASHBACK_REQUEST:
            return { ...state, Cashbacks: { all: state.Cashbacks.all, error: '', isLoading: true } };
        case ALL_CASHBACK_SUCCESS:
            return { ...state, Cashbacks: { all: action.payload, error: '', isLoading: false } };
        case ALL_CASHBACK_FAIL:
            return { ...state, Cashbacks: { all: [], error: action.payload, isLoading: false } };
        case CASHBACK_REQUEST:
            return { ...state, Cashback: { all: state.Cashback.all, error: '', isLoading: true } };
        case CASHBACK_SUCCESS:
            return { ...state, Cashback: { all: action.payload, error: '', isLoading: false } };
        case CASHBACK_FAIL:
            return { ...state, Cashback: { all: [], error: action.payload, isLoading: false } };

        case ALL_CASHBACK_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, cashbackFilter: { search: state.cashbackFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, cashbackFilter: { select: state.cashbackFilter.select, search: action.value } };
            }
            return res;
        default:
            return state;
    }
}