import * as React from 'react';
import { Avatar, Button, Colors, HelperText, ProgressBar, Surface, Text, TextInput } from 'react-native-paper';
import { material } from 'react-native-typography';
import { Linking, View, Dimensions, ScrollView, Platform, ActivityIndicator, TouchableOpacity } from 'react-native';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import Modal from "react-native-modal";
import { setPasswordAction,tokenActivateAccountAction,tokenVarivicationAction } from '../../state/actions/authActions';
import Header from '../header';
import isEmpty from '../../state/validations/is-empty';

// import { TouchableOpacity } from 'react-native-gesture-handler';
// import {logo} from '../../assets/images/img/logo/meePaisa_logo.png'

function Copyright() {
    return (

        <View style={{
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Text onPress={() => {
                Linking.openURL("https://www.easystepin.com/").catch((err) =>
                    console.error('An error occurred', err),
                );
            }} style={[material.caption, {}]}>{'Copyright © '}
                EasyStepIn
                {' 2016 - '}
                {new Date().getFullYear()}
                {'.'}
            </Text>
        </View>

    );
}


const ActivateAccount = (props: any) => {
    const [isSubmited, setIsSubmited] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const dispatch = useDispatch();


    const [seconds, setSeconds] = React.useState(-1);
    React.useEffect(() => {
        const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
        return () => clearTimeout(timer)
    }, [seconds]);

    const [errorMessage, setErrorMessage] = React.useState('');
    const [modelMessage, setModelMessage] = React.useState('');
    const setPasswordCallResponse = (status: any, errorMessage: any, response: any) => {
        setErrorMessage(errorMessage);
        setIsSubmited(false);
        if (!status) {
            setSeconds(3);
        } else {
            setModelMessage(errorMessage);
            setTokenRequestStatusError(true);
        }
    }

    // password token realted one
    const [tokenRequestStatus, setTokenRequestStatus] = React.useState(false);
    const [tokenRequestStatusError, setTokenRequestStatusError] = React.useState(false);
    const [dimensions, setDimensions] = React.useState(Dimensions.get('window'));
    React.useEffect(() => {
        if (isEmpty(props?.route?.params?.token)) {
            setModelMessage(`Unauthorised url access, please try with valid url.`);
            setTokenRequestStatusError(true);
        } else {
            setTokenRequestStatus(true);
            dispatch(tokenActivateAccountAction({ Token: props?.route?.params?.token }, setTokenCallResponse));
        }

    }, [props?.route?.params?.token]);

    const setTokenCallResponse = (status: any, errorMessage: any, response: any) => {
        setTokenRequestStatus(false);
        if (status) {
            setModelMessage("User account activated successfully, please try login.");
            setTokenRequestStatusError(true);
        } else {
            setModelMessage(errorMessage);
            setTokenRequestStatusError(true);
        }
    }


    return (
        <Surface style={{
            flex: 1
        }} >
            <Header navigation={props.navigation} />
            {tokenRequestStatus ? <ActivityIndicator color='#27b6cc' /> :
                <ScrollView keyboardShouldPersistTaps='always' style={{

                }} showsVerticalScrollIndicator={false} >

                    <View style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 20
                    }}>
                        <Avatar.Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} size={60} style={{ marginBottom: 10 }} />
                        <Text style={material.headline}>Acount Activation</Text>
                    </View>
                </ScrollView>}
            <Copyright />
            <Modal animationIn={"slideInDown"}
                deviceWidth={dimensions.width}
                deviceHeight={dimensions.height}
                style={{ alignItems: 'center' }} isVisible={tokenRequestStatusError}>
                <View style={{ flex: 1, flexDirection: "column", padding: 15, maxWidth: dimensions.width - 100, minWidth: 300, minHeight: 100, maxHeight: 250, borderRadius: 10, backgroundColor: Colors.white }}>
                    <Avatar.Image source={require("../../assets/images/img/logo/meePaisa_logo.png")} size={60} style={{ marginBottom: 10, alignSelf: "center", borderColor: '#ffffff' }} />

                    <Text style={{ alignSelf: "center" }} >{modelMessage}</Text>

                    <Button style={{ marginTop: 15, maxWidth: 150, backgroundColor: '#27b6cc', alignSelf: "center" }} mode="contained" onPress={() => {
                        setTokenRequestStatusError(false);
                        props.navigation.navigate("Home");
                    }}>
                        Okay
                    </Button>
                </View>
            </Modal>
        </Surface>
    );
};

export default ActivateAccount;
