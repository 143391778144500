import { SET_ALL_ORDERS } from "../../store/types";
import {
    ALL_ORDERS_REQUEST,
    ALL_ORDERS_SUCCESS,
    ALL_ORDERS_FAIL,
    ORDER_DETAILS_REQUEST,
    ORDER_DETAILS_SUCCESS,
    ORDER_DETAILS_FAIL,
    ORDER_STATUS_UPDATE_REQUEST,
    ORDER_STATUS_UPDATE_SUCCESS,
    ORDER_STATUS_UPDATE_FAIL,
    ORDER_RETURN_UPDATE_REQUEST,
    ORDER_RETURN_UPDATE_SUCCESS,
    ORDER_RETURN_UPDATE_FAIL,
    ORDER_TRACK_REQUEST,
    ORDER_TRACK_SUCCESS,
    ORDER_TRACK_FAIL,

} from '../actions/orderDetailsAction'
const initialState = {
    Orders: { all: [], error: '', isLoading: false },
    Orderdetails: { all: [], error: '', isLoading: false },
    Ordertrack: { all: [], error: '', isLoading: false },
    orderStatusUpdate: { order: {}, error: '', isLoading: false },
    orderReturnUpdate: { order: {}, error: '', isLoading: false },
};

export default function orderdetailsReducer(state = initialState, action) {
    switch (action.type) {
        case ALL_ORDERS_REQUEST:
            return { ...state, Orders: { all: state.Orders.all, error: '', isLoading: true } };
        case ALL_ORDERS_SUCCESS:
            return { ...state, Orders: { all: action.payload, error: '', isLoading: false } };
        case ALL_ORDERS_FAIL:
            return { ...state, Orders: { all: [], error: action.payload, isLoading: false } };
        case ORDER_DETAILS_REQUEST:
            return { ...state, Orderdetails: { all: state.Orderdetails.all, error: '', isLoading: true } };
        case ORDER_DETAILS_SUCCESS:
            return { ...state, Orderdetails: { all: action.payload, error: '', isLoading: false } };
        case ORDER_DETAILS_FAIL:
            return { ...state, Orderdetails: { all: [], error: action.payload, isLoading: false } };
        case ORDER_TRACK_REQUEST:
            return { ...state, Ordertrack: { all: state.Ordertrack.all, error: '', isLoading: true } };
        case ORDER_TRACK_SUCCESS:
            return { ...state, Ordertrack: { all: action.payload, error: '', isLoading: false } };
        case ORDER_TRACK_FAIL:
            return { ...state, Ordertrack: { all: [], error: action.payload, isLoading: false } };
        case ORDER_STATUS_UPDATE_REQUEST:
            return { ...state, orderStatusUpdate: { orders: {}, error: '', isLoading: true } };
        case ORDER_STATUS_UPDATE_SUCCESS:
            return { ...state, orderStatusUpdate: { orders: action.payload, error: '', isLoading: false } };
        case ORDER_STATUS_UPDATE_FAIL:
            return { ...state, orderStatusUpdate: { orders: {}, error: action.payload, isLoading: false } };
        case ORDER_RETURN_UPDATE_REQUEST:
            return { ...state, orderReturnUpdate: { orders: {}, error: '', isLoading: true } };
        case ORDER_RETURN_UPDATE_SUCCESS:
            return { ...state, orderReturnUpdate: { orders: action.payload, error: '', isLoading: false } };
        case ORDER_RETURN_UPDATE_FAIL:
            return { ...state, orderReturnUpdate: { orders: {}, error: action.payload, isLoading: false } };
        default:
            return state;
    }
}
