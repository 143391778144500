import React, { useState } from 'react';
import { Rating } from "react-native-ratings";
import { useSelector } from 'react-redux';

const EsiRating = (props: any) => {
  const esiColor = useSelector(state => state.theme);

    // props variable consist all react text field properties along with valueUpdate function.
    // valueUpdate function will return onChange value of date field.

    // valueUpdate function is a custom property as it dont have in react native textfield properties.
    const { tintColor, ...rest } = props

    return (
        esiColor.TintColor ? <Rating {...props} /> : <Rating {...rest} />
    );
};

export default EsiRating;