import { useCallback, useEffect, useState } from "react";
import { Image, Platform, Text, View } from "react-native";
import EsiCheckbox from "../../../components/custom/checkbox";

export const PriceFilter = (props) => {
    const { filters, setFilters } = props;

    const data  = useCallback([
        { value: "0-500", label: "Under 500", chacked: filters?.price == "0-500" || false },
        {
            label: "Rs 501-1000",
            value: "501-1000", chacked: filters?.price == "501-1000" || false
        },
        { value: "1001-5000", label: "Rs 1001- 5000", chacked: filters?.price == "1001-5000" || false },
        { value: "5001-10000", label: "Rs 5001-10000", chacked: filters?.price == "5001-10000" || false },
        { value: "10001", label: "Rs 10001 or more", chacked: filters?.price == "10001" || false },
    ],[filters]);

    const onchange = res => {
        setFilters(props => {
            return { ...props, price: res.value==props.price?"": res.value }
        });
    }

    return (
        <View style={[{ marginHorizontal: 5 }, Platform.select({ ios: { zIndex: 100 } })]}>
            <View style={{ flexDirection: "row" }}>
                <Image style={{ width: 25, height: 25, marginRight: 10 }}
                    source={require('../../../assets/images/favicon.png')}
                />
                <Text style={{
                    fontSize: 14, color: "#03dac4",
                    marginTop: 3,
                }}>Price</Text>
            </View>
            <EsiCheckbox data={data} onchange={onchange} />
        </View>
    )
}