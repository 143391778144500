import axios from "axios";
import { boomiApi } from "./commonAxios";
export const SET_ALL_DEALS = "SET_ALL_DEALS";
export const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";
export const ALL_PRODUCTS_REQUEST = "ALL_PRODUCTS_REQUEST";
export const ALL_PRODUCTS_FAIL = "ALL_PRODUCTS_FAIL";
export const ALL_DEALS_REQUEST = "ALL_DEALS_REQUEST";
export const ALL_DEALS_FAIL = "ALL_DEALS_FAIL";
export const SET_ALL_FILTER_BRAND_DEALS = "SET_ALL_FILTER_BRAND_DEALS";
export const ALL_DEALS_BRAND_REQUEST = "ALL_DEALS_BRAND_REQUEST";
export const ALL_DEALS_BRAND_FAIL = "ALL_DEALS_BRAND_FAIL";
export const SET_ALL_FILTER_PARTNER_DEALS = "SET_ALL_FILTER_PARTNER_DEALS";
export const ALL_DEALS_PARTNER_REQUEST = "ALL_DEALS_PARTNER_REQUEST";
export const ALL_DEALS_PARTNER_FAIL = "ALL_DEALS_PARTNER_FAIL";

export const DEALRESET = "DEALRESET";
export const PARTIALDEALRESET = "PARTIALDEALRESET";

export const CBDEALS_REQUEST = "CBDEALS_REQUEST";
export const CBDEALS_SUCCESS = "CBDEALS_SUCCESS";
export const CBDEALS_FAIL = "CBDEALS_FAIL";
export const WHATSHOT_DEALS_REQUEST = "WHATSHOT_DEALS_REQUEST";
export const WHATSHOT_DEALS_SUCCESS = "WHATSHOT_DEALS_SUCCESS";
export const WHATSHOT_DEALS_FAIL = "WHATSHOT_DEALS_FAIL";

export const RESULT_CBDEALS_REQUEST = "RESULT_CBDEALS_REQUEST";
export const RESULT_CBDEALS_SUCCESS = "RESULT_CBDEALS_SUCCESS";
export const RESULT_CBDEALS_FAIL = "RESULT_CBDEALS_FAIL";
export const RESULT_WHATSHOT_DEALS_REQUEST = "RESULT_WHATSHOT_DEALS_REQUEST";
export const RESULT_WHATSHOT_DEALS_SUCCESS = "RESULT_WHATSHOT_DEALS_SUCCESS";
export const RESULT_WHATSHOT_DEALS_FAIL = "RESULT_WHATSHOT_DEALS_FAIL";

export const GET_DEAL_REQUEST = "GET_DEAL_REQUEST";
export const GET_DEAL_FAIL = "GET_DEAL_FAIL";
export const GET_DEAL_SUCCESS = "GET_DEAL_SUCCESS";

export const TOP_DEAL = "TOP_DEAL";
export const TOP_DEAL_FAIL = "TOP_DEAL_FAIL";
export const TOP_DEAL_SUCCESS = "TOP_DEAL_SUCCESS";

export const VISITOR_CREATE_REQUEST = "VISITOR_CREATE_REQUEST";
export const VISITOR_CREATE_SUCCESS = "VISITOR_CREATE_SUCCESS";
export const VISITOR_REVIEW_CREATE_FAIL = "VISITOR_REVIEW_CREATE_FAIL";


/*=============================================================
                 adduservisitAction
===============================================================*/

export const adduservisitAction = (formData:any) => async (dispatch:any) => {

    dispatch({
        type: VISITOR_CREATE_REQUEST,
    });
    boomiApi.post(`/Offers_Visitor/Create`, formData)
        .then(response => {
            let successResponse = response.data;
            if (successResponse.Response_Status == "Success") {
                dispatch({
                    type: VISITOR_CREATE_SUCCESS
                });
            } else {

                dispatch({
                    type: VISITOR_REVIEW_CREATE_FAIL,
                    payload: successResponse.UI_Display_Message
                });
            }
        })
        .catch(error => {
            dispatch({
                type: VISITOR_REVIEW_CREATE_FAIL,
                payload: "Please try sometime later.Currently server is not working"
            });
        })
};


/*=============================================================
                getProductByIdAction
===============================================================*/
export const getProductByIdAction = (formData:any) => async (dispatch:any) => {
    try {
        let { data } = await boomiApi.post(
            `/Products/Single_Partner_Products_Get?PageNo=0&PageSize=10000&SortBy=PRODUCT_NAME&SortOrder=ASC`,
            formData,
        );
        if (data) {
            dispatch({
                type: SET_ALL_PRODUCTS,
                payload: data.Product,
            });
        }
    } catch (error) {
        dispatch({
            type: ALL_PRODUCTS_FAIL,
            payload: error
        });
    }
};

