import {
    ALL_PRODUCTS_REQUEST,
    ALL_PRODUCTS_FAIL,
    ALL_DEALS_REQUEST,
    SET_ALL_DEALS,
    ALL_DEALS_FAIL,
    ALL_DEALS_BRAND_REQUEST,
    SET_ALL_FILTER_BRAND_DEALS,
    ALL_DEALS_BRAND_FAIL,
    ALL_DEALS_PARTNER_REQUEST,
    SET_ALL_FILTER_PARTNER_DEALS,
    ALL_DEALS_PARTNER_FAIL,
    DEALRESET,
    PARTIALDEALRESET,
    SET_ALL_PRODUCTS,
    CBDEALS_REQUEST,
    CBDEALS_SUCCESS,
    CBDEALS_FAIL,
    WHATSHOT_DEALS_REQUEST,
    WHATSHOT_DEALS_SUCCESS,
    WHATSHOT_DEALS_FAIL,
    RESULT_CBDEALS_REQUEST,
    RESULT_CBDEALS_SUCCESS,
    RESULT_CBDEALS_FAIL,
    RESULT_WHATSHOT_DEALS_REQUEST,
    RESULT_WHATSHOT_DEALS_SUCCESS,
    RESULT_WHATSHOT_DEALS_FAIL,
    GET_DEAL_REQUEST,
    GET_DEAL_FAIL,
    GET_DEAL_SUCCESS,
    TOP_DEAL,
    TOP_DEAL_FAIL,
    TOP_DEAL_SUCCESS,
    VISITOR_CREATE_REQUEST ,
    VISITOR_CREATE_SUCCESS,
    VISITOR_REVIEW_CREATE_FAIL
  } from '../actions/dealActions'
  
  
  const initialState = {
    products: { all: [], error: '', isLoading: false },
    deals: { all: [], error: '', isLoading: false },
    branddeals: { all: [], error: '', isLoading: false },
    partnerdeals: { all: [], error: '', isLoading: false },
    whatshotdeal: { all: [], error: '', isLoading: false },
    cashbackdeals: { all: [], error: '', isLoading: false },
    whdeal: { all: [], error: '', isLoading: false },
    cbdeals: { all: [], error: '', isLoading: false },
    deal: { deal: [], error: '', isLoading: false },
    topdeal: { topdeal: {}, error: '', isLoading: false },
    visitorCreate: { visitor: {}, error:'',isLoading:false},
  };
  
  export default function dealsReducer(state = initialState, action) {
    switch (action.type) {
      case ALL_PRODUCTS_REQUEST:
        return { ...state, products: { all: state.products.all, error: '', isLoading: true } };
      case SET_ALL_PRODUCTS:
        return { ...state, products: { all: action.payload, error: '', isLoading: false } };
      case ALL_PRODUCTS_FAIL:
        return { ...state, products: { all: [], error: action.payload, isLoading: false } };
      
        case ALL_DEALS_REQUEST:
        return { ...state, deals: { all: state.deals.all, error: '', isLoading: true } };
      case SET_ALL_DEALS:
        return { ...state, deals: { all: action.payload, error: '', isLoading: false } };
      case ALL_DEALS_FAIL:
        return { ...state, deals: { all: [], error: action.payload, isLoading: false } };
  
      case GET_DEAL_REQUEST:
        return { ...state, deal: { deal: state.deal.deal, error: '', isLoading: true } };
      case GET_DEAL_SUCCESS:
        return { ...state, deal: { deal: action.payload, error: '', isLoading: false } };
      case GET_DEAL_FAIL:
        return { ...state, deal: { deal: [], error: action.payload, isLoading: false } };
  
      case ALL_DEALS_BRAND_REQUEST:
        return { ...state, branddeals: { all: state.branddeals.all, error: '', isLoading: true } };
      case SET_ALL_FILTER_BRAND_DEALS:
        return { ...state, branddeals: { all: action.payload, error: '', isLoading: false } };
      case ALL_DEALS_BRAND_FAIL:
        return { ...state, branddeals: { all: [], error: action.payload, isLoading: false } };
      case ALL_DEALS_PARTNER_REQUEST:
        return { ...state, partnerdeals: { all: state.partnerdeals.all, error: '', isLoading: true } };
      case SET_ALL_FILTER_PARTNER_DEALS:
        return { ...state, partnerdeals: { all: action.payload, error: '', isLoading: false } };
      case ALL_DEALS_PARTNER_FAIL:
        return { ...state, partnerdeals: { all: [], error: action.payload, isLoading: false } };
  
      case WHATSHOT_DEALS_REQUEST:
        return { ...state, whatshotdeal: { all: state.whatshotdeal.all, error: '', isLoading: true } };
      case WHATSHOT_DEALS_SUCCESS:
        return { ...state, whatshotdeal: { all: action.payload, error: '', isLoading: false } };
      case WHATSHOT_DEALS_FAIL:
        return { ...state, whatshotdeal: { all: [], error: action.payload, isLoading: false } };
  
      case CBDEALS_REQUEST:
        return { ...state, cashbackdeals: { all: state.cashbackdeals.all, error: '', isLoading: true } };
      case CBDEALS_SUCCESS:
        return { ...state, cashbackdeals: { all: action.payload, error: '', isLoading: false } };
      case CBDEALS_FAIL:
        return { ...state, cashbackdeals: { all: [], error: action.payload, isLoading: false } };
  
      case RESULT_WHATSHOT_DEALS_REQUEST:
        return { ...state, whdeal: { all: state.whdeal.all, error: '', isLoading: true } };
      case RESULT_WHATSHOT_DEALS_SUCCESS:
        return { ...state, whdeal: { all: action.payload, error: '', isLoading: false } };
      case RESULT_WHATSHOT_DEALS_FAIL:
        return { ...state, whdeal: { all: [], error: action.payload, isLoading: false } };
  
      case RESULT_CBDEALS_REQUEST:
        return { ...state, cbdeals: { all: state.cbdeals.all, error: '', isLoading: true } };
      case RESULT_CBDEALS_SUCCESS:
        return { ...state, cbdeals: { all: action.payload, error: '', isLoading: false } };
      case RESULT_CBDEALS_FAIL:
        return { ...state, cbdeals: { all: [], error: action.payload, isLoading: false } };
  
      case TOP_DEAL:
        return { ...state, topdeal: { topdeal: state.topdeal.topdeal, error: '', isLoading: true } };
      case TOP_DEAL_SUCCESS:
        return { ...state, topdeal: { topdeal: action.payload, error: '', isLoading: false } };
      case TOP_DEAL_FAIL:
        return { ...state, topdael: { topdeal: [], error: action.payload, isLoading: false } };
      case VISITOR_CREATE_REQUEST:
            return { ...state, visitorCreate: { all: state.visitorCreate.visitor, error: '', isLoading: true } };
      case VISITOR_CREATE_SUCCESS:
          return { ...state, visitorCreate: { all: action.payload, error: '', isLoading: false } };
      case VISITOR_REVIEW_CREATE_FAIL:
          return { ...state, visitorCreate: { all: [], error: action.payload, isLoading: false } };
      case DEALRESET:
        return {
          ...state, deals: { all: action.payload, error: "", isLoading: false },
          branddeals: { all: action.payload, error: '', isLoading: false },
          partnerdeals: { all: action.payload, error: '', isLoading: false },
          whdeal: { all: [], error: '', isLoading: false },
          cbdeals: { all: [], error: '', isLoading: false },
          topdeal: { topdeal: {}, error: '', isLoading: false }
        };
  
      case PARTIALDEALRESET:
        return {
          ...state, branddeals: { all: action.payload, error: '', isLoading: false },
          partnerdeals: { all: action.payload, error: '', isLoading: false },
          whdeal: { all: [], error: '', isLoading: false },
          cbdeals: { all: [], error: '', isLoading: false },
          topdeal: { topdeal: {}, error: '', isLoading: false }
        };
  
      default:
        return state;
    }
  }