import { useEffect, useState } from "react";
import { ActivityIndicator } from "react-native";
import { Image, Platform, Text, View } from "react-native";
import { useDispatch } from "react-redux";
import { EsiAutocompleteDropdown } from "../../../components/custom/DropDowns/AutoCompleteDropDown";
import { getBrandsAction } from "../../../state/actions/node-actions/home-actions";

export const BrandFilters = (props) => {
    const { filters, setFilters } = props;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [brands, setBrands] = useState([]);
    const [selected, setSelected] = useState(null);

    const callBackGetData = async (status, data) => {
        setLoading(false);
        if (status) {
            setBrands(data.map(item => ({ "id": item.Brand_Id, "title": item.Brand_Name })));
        } else {
            setBrands([]);
        }
        if (seconds == -2) {
            setSelected(data.map(item => ({ "id": item.Brand_Id, "title": item.Brand_Name }))[0]);
            setSeconds(2);
        }
    }

    const [seconds, setSeconds] = useState(-1);
    useEffect(() => {
        if (seconds != -2) {
            const timer = setTimeout(() => seconds > 0 ? setSeconds(seconds - 1) : setSeconds(-1), 1000)
            return () => clearTimeout(timer)
        } else {
            setLoading(true);
            dispatch(getBrandsAction({
                "Records_Filter": "FILTER",
                "Brand_Id": filters?.brandId
            },
                { PageNo: 1, PageSize: 10, SortBy: "NAME", SortOrder: "ASC" },
                callBackGetData
            ));
        }
    }, [seconds]);
    useEffect(() => {
        if (filters?.brandId != selected?.id && filters?.brandId) {
            setSeconds(-2);
        } else {
            if (brands.length <= 0 && !(selected?.id))
                apiCall("");
        }
    }, [filters]);
    const apiCall = (q) => {
        setLoading(true);
        dispatch(getBrandsAction({
            "Records_Filter": "FILTER",
            "Search_Brand_Name": q
        },
            { PageNo: 1, PageSize: 10, SortBy: "NAME", SortOrder: "ASC" },
            callBackGetData
        ));
    }

    return (
        <View style={[{ marginHorizontal: 5 }, Platform.select({ ios: { zIndex: 100 } })]}>
            <View style={{ flexDirection: "row" }}>
                <Image style={{ width: 25, height: 25, marginRight: 10 }}
                    source={require('../../../assets/images/favicon.png')}
                />
                <Text style={{
                    fontSize: 14, color: "#03dac4",
                    marginTop: 3
                }}>Brands</Text>
            </View>
            {seconds <= 0 ?
                <EsiAutocompleteDropdown
                    loading={loading}
                    data={brands}
                    label={"Search Brands"}
                    onSelect={(item) => {
                        setSelected(item);
                        if(item?.id)
                        setFilters((props => {
                            return { ...props, brandId: item.id }
                        }))
                    }}
                    selected={selected}
                    getSuggestions={(q) => {
                        apiCall(q);
                    }}
                    onClear={()=>{
                        setFilters((props => {
                            return { ...props, brandId: "" }
                        }))
                    }}
                /> : <ActivityIndicator />}
        </View>
    )
}