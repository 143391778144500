import { MY_SERVICES_FAIL, MY_SERVICES_REQUEST, MY_SERVICES_SUCCESS } from "../actions/servicesAction";

const initialState = { all: [], isLoading: false };
export default function serviceReducer(state = initialState, action: any) {
    switch (action.type) {
        case MY_SERVICES_REQUEST:
            return { ...state, isLoading: true };
        case MY_SERVICES_SUCCESS:
            return { ...state, isLoading: false, all: action.payload };
        case MY_SERVICES_FAIL:
            return { ...state, isLoading: false };
        default:
            return state;
    }
}