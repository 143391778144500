import axios from 'axios';
import { boomiApi } from './commonAxios';
export const SET_ALL_COUPONS = "SET_ALL_COUPONS";
export const ALL_COUPONS_REQUEST = "ALL_COUPONS_REQUEST";
export const ALL_COUPONS_FAIL = "ALL_COUPONS_FAIL";
export const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";
export const ALL_PRODUCTS_REQUEST = "ALL_PRODUCTS_REQUEST";
export const ALL_PRODUCTS_FAIL = "ALL_PRODUCTS_FAIL";

export const COUPONS_FILTER_REQUEST="COUPONS_FILTER_REQUEST";
export const ALL_COUPONS_FILTER="ALL_COUPONS_FILTER";

export const ALL_COUPONS_PARTNER_REQUEST = "ALL_COUPONS_PARTNER_REQUEST";
export const ALL_COUPONS_BRAND_REQUEST = "ALL_COUPONS_BRAND_REQUEST";
export const SET_ALL_FILTER_PARTNER_COUPONS = "SET_ALL_FILTER_PARTNER_COUPONS";
export const SET_ALL_FILTER_BRAND_COUPONS = "SET_ALL_FILTER_BRAND_COUPONS";

export const VISITOR_CREATE_REQUEST = "VISITOR_CREATE_REQUEST";
export const VISITOR_CREATE_SUCCESS = "VISITOR_CREATE_SUCCESS";
export const VISITOR_REVIEW_CREATE_FAIL = "VISITOR_REVIEW_CREATE_FAIL";

export const PARTIALCOUPONRESET = "PARTIALCOUPONRESET";
export const GET_COUPON_REQUEST = "GET_COUPON_REQUEST";
export const GET_COUPON_FAIL = "GET_COUPON_FAIL";
export const GET_COUPON_SUCCESS = "GET_COUPON_SUCCESS";
export const TOP_COUPON = "TOP_COUPON";



/////////**User Visitors Create */
export const adduservisitAction = (formData: any) => async (dispatch: any) => {
  dispatch({
    type: VISITOR_CREATE_REQUEST,
  });
  boomiApi.post(`/Offers_Visitor/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: VISITOR_CREATE_SUCCESS
        });
        // toast('Visitor added successfully.', { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      } else {
        dispatch({
          type: VISITOR_REVIEW_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: VISITOR_REVIEW_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};
