import React, { useState } from 'react';

// import all the components we are going to use
import { SafeAreaView, StyleSheet, Text, View } from 'react-native';

//import DatePicker from the package we installed
import DatePicker from 'react-native-datepicker';
import DateTimePicker from '@react-native-community/datetimepicker';
import { TextInput } from 'react-native-paper';
import MaskInput from 'react-native-mask-input';

const EsiTimePicker = (props: any) => {

  // props variable consist all react text field properties along with valueUpdate function.
  // valueUpdate function will return onChange value of date field.

  // valueUpdate function is a custom property as it dont have in react native textfield properties.
  const { valueUpdate, ...rest } = props;
  // var dateString = props.value,
  //   dateTimeParts = dateString.split(' '),
  //   timeParts = dateTimeParts[1].split(':'),
  //   dateParts = dateTimeParts[0].split('-'),
  //   date;


  // try {
  //   date=props.value?new Date():new Date();
  // } catch (error) {
  //   date=new Date();
  // }
  return (
    <View>
      <TextInput selectionColor='rgba(0, 0, 0, 0.5)'
        style={{ width: "100%" }}
        mode='outlined'
        value={props.value}
        placeholder="HH:MM"
        onChangeText={(value) => {
          let response = "";
          if (value) {
            let splitValue = value?.split(":");
            let valueLength = splitValue?.length;
            if (valueLength == 2) {
              if (Number(splitValue[0]) > 23) {
                response = "23:00";
              } else {
                response += splitValue[0];
                if (Number(splitValue[1]) >= 60) {
                  response += ":59";
                } else {
                  response += ":" + splitValue[1];
                }
              }
            } else {
              if (Number(splitValue[0]) > 23) {
                response = "23:59";
              } else {
                response = value;
              }
            }
            props.valueUpdate(response);
          } else {
            props.valueUpdate(value);
          }
        }}

        onEndEditing={() => {
          try {
            let response = "";
            if (props.value) {
              let splitValue = props.value?.split(":");
              let valueLength = splitValue?.length;
              if (valueLength == 2) {
                if (Number(splitValue[0]) > 23) {
                  response = "23:59:99";
                } else {
                  response += splitValue[0];
                  if (Number(splitValue[1]) >= 60) {
                    response += ":59:99";
                  } else {
                    response += ":" + splitValue[1] + ":00";
                  }
                }
              } else {
                if (Number(splitValue[0]) > 23) {
                  response = "23:59";
                } else {
                  response = props.value;
                }
              }
              props.valueUpdate(response);
            }
          } catch (error) {
          }
        }}
        {...rest}
        render={props =>
          <MaskInput
            {...props}
            mask={[/[0-2]/, /\d/, ':', /[0-5]/, /\d/]}
          />
        }
      />
    </View>
  );
};

export default EsiTimePicker;