import { ALL_SEARCH_REQUEST, ALL_SEARCH__FAIL, ALL_SEARCH__GET, } from "../actions/globalSearch";

const initialState = {
    recentSearch: { all: [], error: '', isLoading: false },
};

export default function (state = initialState, action: any) {
    switch (action.type) {

        // case ALL_SEARCH_REQUEST:
        //     return { ...state, recentSearch: action.value };

        case ALL_SEARCH_REQUEST:
            return { ...state, recentSearch: { all: state.recentSearch.all, error: '', isLoading: true } };
        case ALL_SEARCH__GET:
            return { ...state, recentSearch: { all: action.payload, error: '', isLoading: false } };
        case ALL_SEARCH__FAIL:
            return { ...state, recentSearch: { all: [], error: action.payload, isLoading: false } };

        default:
            return state;
    }

}