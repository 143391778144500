import axios from 'axios';
import config from './config';
import { boomiApi, nodeCudApi } from './commonAxios';


/*=============================================================
                 Profile Action
===============================================================*/


export const getUserProfileAction =
  (updateStates, formData) => async (dispatch) => {
    try {
      let { data } = await boomiApi.post(`/User/Profile_Get`, formData);
      if (data) {
        updateStates(data, false, false);
      }
    } catch (error) {
      updateStates([], false, true);
    }
  };

export const createProfileAction =
  (updateStates, formData) => async (dispatch) => {
    try {
      let { data } = await boomiApi.post(`/User/Profile_Create`, formData);

      if (data) {
        updateStates(data, false);
      }
    } catch (error) {
    }
  };

export const uploadProfileAction =
  (formData, fileData, callBackUploadImage) => async (dispatch) => {
    try {

      let { data } = await boomiApi.post(
        `/User_Upload/Upload?functionality=User&fileextension=${fileData.ext}&filetype=Images&filename=${fileData.fileName}`,
        formData,
        {
          transformRequest: (d) => d,
        }
      );
      if (data) {
        callBackUploadImage(data);
      }
    } catch (error) {
    }
  };
export const editProfileAction =
  (updateStates, formData) => async (dispatch) => {
    try {
      let { data } = await boomiApi.put(`/User/Profile_Update`, formData);
      if (data) {
        if (data.Response_Status === "Success") {
          updateStates(data, true);
        } else {
          updateStates(data.UI_Display_Message, false);
        }
      }
    } catch (error) {
    }
  };


export const changePasswordAction =
  (formData, changePasswordResponse, resetForm: any, toast: any) => async (dispatch) => {
    try {
      // let { data } = await axios.post(`${config.url}/User/Change_Password`, formData, {
      //   headers: headers,
      //   // auth: auth,
      // });
      let { data } = await nodeCudApi.post(`/User/Change_Password`, formData);

      if (data) {
        if (data.Response_Status == "Success") {
          changePasswordResponse(true, "");
          resetForm();
          toast.show({ message: 'Password is changed successfully.', type: 'info', duration: 3000, position: 'top' });
        } else {
          changePasswordResponse(false, data.UI_Display_Message);
        }
      }
    } catch (error) {
      changePasswordResponse(true, "Please try again.");
    }
  };