/*============================================================
              PRODUCTS
============================================================*/

export const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";
export const GET_ALL_BANNERS = "GET_ALL_BANNERS";
export const SET_ALL_ORDERS = "SET_ALL_ORDERS";

export const SEARCH = "SEARCH";
export const SEARCH_REMOVE = "SEARCH_REMOVE";
export const SEARCH_ADD = "SEARCH_ADD";
export const SEARCH_UPDATE = "SEARCH_UPDATE";