import { ALL_FINANCIAL_TRANSACTION_FAIL, ALL_FINANCIAL_TRANSACTION_GET, ALL_FINANCIAL_TRANSACTION_REQUEST, ALL_FINANCIAL_TRANSACTION_FILTER } from "../actions/myfinancialtransactionActions";

const initialState = {
    financialTransaction: { all: [], error: '', isLoading: false },
    financialTransactionFilter: { select: 'All', search: '' },

};
export default function (state = initialState, action: any) {
    switch (action.type) {
        case ALL_FINANCIAL_TRANSACTION_REQUEST:
            return { ...state, financialTransaction: { all: state.financialTransaction.all, error: '', isLoading: true } };
        case ALL_FINANCIAL_TRANSACTION_GET:
            return { ...state, financialTransaction: { all: action.payload, error: '', isLoading: false } };
        case ALL_FINANCIAL_TRANSACTION_FAIL:
            return { ...state, financialTransaction: { all: [], error: action.payload, isLoading: false } };
        case ALL_FINANCIAL_TRANSACTION_FILTER:
            let res = { ...state };
            if (action.fType === "select") {
                res = { ...state, financialTransactionFilter: { search: state.financialTransactionFilter.search, select: action.value } }
            }
            else if (action.fType === "search") {
                res = { ...state, financialTransactionFilter: { select: state.financialTransactionFilter.select, search: action.value } };
            }
            return res;
        default:
            return state;
    }
}