import axios from "axios";
import { boomiApi } from "./commonAxios";

export const WALLET_REQUEST = "WALLET_REQUEST";
export const WALLET_SUCCESS = "WALLET_SUCCESS";
export const WALLET_FAIL = "WALLET_FAIL";
export const WALLET_GET_REQUEST = "WALLET_GET_REQUEST";
export const WALLET_GET_SUCCESS = "WALLET_GET_SUCCESS";
export const WALLET_GET_FAIL = "WALLET_GET_FAIL";
export const WALLET_CREATE_REQUEST = "WALLET_CREATE_REQUEST";
export const WALLET_CREATE_SUCCESS = "WALLET_CREATE_SUCCESS";
export const WALLET_CREATE_FAIL = "WALLET_CREATE_FAIL";
export const WALLET_HISTORY_REQUEST = "WALLET_HISTORY_REQUEST";
export const WALLET_HISTORY_SUCCESS = "WALLET_HISTORY_SUCCESS";
export const WALLET_HISTORY_FAIL = "WALLET_HISTORY_FAIL";
export const UPI_CREATE_REQUEST = "UPI_CREATE_REQUEST";
export const UPI_CREATE_SUCCESS = "UPI_CREATE_SUCCESS";
export const UPI_CREATE_FAIL = "UPI_CREATE_FAIL";


/*=============================================================
                  Get Wallet Action
 ===============================================================*/

export const getWallet = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: WALLET_REQUEST
    });
    let { data, status } = await boomiApi.post(
      `/User_Wallet/User_Wallet_Get`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: WALLET_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: WALLET_SUCCESS,
          payload: data.Wallet
        });
      }
    }
  } catch (err) {
    dispatch({
      type: WALLET_FAIL,
      payload: err
    });
  }
};

/*=============================================================
                  Get Wallet History Action
 ===============================================================*/

export const getWalletHistory = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: WALLET_HISTORY_REQUEST
    });
    let { data, status } = await boomiApi.post(
      `/User_Wallet/User_Wallet_History_Get`, formData,);
    if (data) {

      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: WALLET_HISTORY_SUCCESS,
          payload: data
        });
      } else {

        dispatch({
          type: WALLET_HISTORY_SUCCESS,
          payload: data.Wallet_History
        });
      }
    }
  } catch (err) {
    dispatch({
      type: WALLET_HISTORY_FAIL,
      payload: err
    });
  }
};

/*=============================================================
             ADD Wallet Action
 ===============================================================*/

export const createWallet = (formData: any, navigation: any, toast: any, resetForm: any, successResponseFunction: any) => async dispatch => {

  dispatch({
    type: WALLET_CREATE_REQUEST
  });
  await boomiApi.post(`/User_Wallet/User_Withdraw_Request`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {

        dispatch({
          type: WALLET_CREATE_SUCCESS
        });
        successResponseFunction();
        resetForm();
      } else {
        toast.show({ message: successResponse.Response_Message, type: 'info', duration: 3000, position: 'top' });
        const errors = {};
        dispatch({
          type: WALLET_CREATE_FAIL,
          payload: successResponse.Response_Message
        });
      }
    })
    .catch(error => {
      toast.show({ message: "Currently server is not working.Please try sometime later.", type: 'info', duration: 3000, position: 'top' });

      dispatch({
        type: WALLET_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};

/*=============================================================
             Create UPI Action
 ===============================================================*/

export const createupi = (formData: any, navigation: any, toast: any,) => async dispatch => {
  dispatch({
    type: UPI_CREATE_REQUEST
  });
  await boomiApi.post(`/User_Upi/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: UPI_CREATE_SUCCESS
        });
        toast.show({ message: 'UPI Created Successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          Records_Filter: "All",
          "User_Id": EmailID,
          "Wallet_Id": "",
        };
        dispatch(getWallet(formValue))

      } else {
        const errors = {};
        dispatch({
          type: UPI_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: UPI_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};
export const getWalletByUser = (updateStates: any, formData: any) => async (dispatch: any) => {
  try {
    let { data } = await boomiApi.post(
      `/User_Wallet/User_Wallet_Get`,
      formData,
      
    );

    if (data) {
      updateStates(data, false);
    }
  } catch (error) {
  }
};

export const getUserSettings = (key: any, updateStates: any, isNumber = true) => async (dispatch: any) => {
  try {
    let { data } = await boomiApi.post(
      `/Setting/GET`,
      {
        "Field_Name": key,
        "Records_Filter": "FILTER",
      }
    );

    if (data) {
      if (data?.Success_Response?.Is_Data_Exist == "1") {
        if (isNumber) {
          updateStates(Number(data?.Setting[0]?.Field_Value));
        }else{
          updateStates(data?.Setting[0]?.Field_Value);
        }
      }
    }
  } catch (error) {
  }
};