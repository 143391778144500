import Base64 from '../../hooks/Base64';
import config from "./config";
import axios from 'axios';
const _REQUEST2SERVER = (url = null, params = null, type = null) => {
	const isGet = (params == null);
	return new Promise(function (resolve, reject) {
		fetch(`${config.url}${url}`, {
			method: type ? type : isGet ? 'get' : 'post',
			headers: config.headersCommon,
			...(!isGet && { body: JSON.stringify(params) })
		})
			.then(res => res.json())
			.then(res => {

				if (res?.Response_Status == 'Success' || res?.Success_Response?.Response_Status == "Success") resolve(res);
				else reject(res);
			})
			.catch(err => {
				reject(err)
			});
	});
}

const _REQUEST2NODE_SERVER = (url = null, params = null, type = null) => {
	const isGet = (params == null);
	return new Promise(function (resolve, reject) {
		fetch(`${config.nodeUserUrl}${url}`, {
			method: type ? type : isGet ? 'get' : 'post',
			headers: config.nodeUserHeadersCommon,
			...(!isGet && { body: JSON.stringify(params) })
		})
			.then(res => res.json())
			.then(res => {

				if (res?.Response_Status == 'Success' || res?.Success_Response?.Response_Status == "Success") resolve(res);
				else reject(res);
			})
			.catch(err => {
				reject(err)
			});
	});
}

export const get_wish_list = (params, page_no = 0, page_size = 200, order_by = 'ASC', sort_by = 'USER_EMAIL_ID') => {
	return _REQUEST2SERVER(`/User_Wishlist/Get?PageNo=${page_no}&PageSize=${page_size}&SortBy=${sort_by}&SortOrder=${order_by}`, params);
}

export const get_my_wish_list = (params, page_no = 0, page_size = 200, order_by = 'ASC', sort_by = 'USER_EMAIL_ID') => {
	return _REQUEST2SERVER(`/Products/WishGetForProducts?PageNo=${page_no}&PageSize=${page_size}&SortBy=${sort_by}&SortOrder=${order_by}`, params);
}

export const create_wish_list = (params) => {
	return _REQUEST2SERVER(`/User_Wishlist/Create`, params);
}
export const update_wish_list = (params) => {
	return _REQUEST2SERVER(`/User_Wishlist/Update`, params);
}
export const delete_wish_list = (params) => {
	return _REQUEST2SERVER(`/User_Wishlist/Delete`, params);
}



export const api = axios.create({
    // baseURL: BASE_URL,
    baseURL:config.delurl,
    headers:config.headersCommon,
    
    // transformRequest: [function (data) {
    //   // Do whatever you want to transform the data
  
    //   return data;
    // }],

});


// Add a request interceptor
api.interceptors.request.use(
    
    request => {
        return request;
    },
    error => {
      // Do something with response error
    //   console.log('Interceptor Request API ERR:', error.message);
    //   console.log('Interceptor Request API ERR:', error.code);
    //   console.log('Interceptor Request API ERR:', error.status);


      return Promise.reject(error);
    },
  );
  
  // Add a response interceptor
  api.interceptors.response.use(
    response => {
        return response;
    },
    
    error => {
      // Do something with response error
    //   console.log('Interceptor response API ERR:', error.message);
    //   console.log('Interceptor response API ERR:', error.code);
    //   console.log('Interceptor response API ERR:', error.status);
      return Promise.reject(error);
    },
  );

export default api;
