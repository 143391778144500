
import {
    CITY_REQUEST,
    CITY_REQUEST_FAIL,
    ALL_CITIES_REQUEST,
    ALL_CITIES_REQUEST_SUCCESS,
    ALL_CITIES_REQUEST_FAIL,
    PARTNERS_BY_CITY_REQUEST,
    PARTNERS_BY_CITY_REQUEST_SUCCESS,
    PARTNERS_BY_CITY_REQUEST_FAIL,
    DEALS_BY_CITY_REQUEST,
    DEALS_BY_CITY_REQUEST_SUCCESS,
    DEALS_BY_CITY_REQUEST_FAIL,
    COUPONS_BY_CITY_REQUEST,
    COUPONS_BY_CITY_REQUEST_SUCCESS,
    COUPONS_BY_CITY_REQUEST_FAIL,
    PRODUCTS_BY_CITY_REQUEST,
    PRODUCTS_BY_CITY_REQUEST_SUCCESS,
    PRODUCTS_BY_CITY_REQUEST_FAIL,
    ALL_CITY_DEALS_REQUEST,
    ALL_CITY_DEALS_FAIL,
    ALL_CITY_DEALS,
    ALL_CITY_PRODUCTS_REQUEST,
    ALL_CITY_PRODUCTS_FAIL,
    ALL_CITY_PRODUCTS,
    ALL_CITY_COUPONS_REQUEST,
    ALL_CITY_COUPONS_FAIL,
    ALL_CITY_COUPONS,
    CITY_PARTNERRESET,
    NOTIFY_BY_CITY_REQUEST,
    NOTIFY_BY_CITY_REQUEST_SUCCESS,
    NOTIFY_BY_CITY_REQUEST_FAIL
} from '../actions/shopbycityActions'

const initialState = {
    Cities: { all: [], error: '', isLoading: false },
    allCities: { all: [], error: '', isLoading: false },
    CityPartners: { all: [], error: '', isLoading: false },
    CityDeals: { all: [], error: '', isLoading: false },
    CityCoupons: { all: [], error: '', isLoading: false },
    CityProducts: { all: [], error: '', isLoading: false },
    CityNotify: { all: [], error: '', isLoading: false },
};

export default function shopbycityReducer(state = initialState, action) {
    switch (action.type) {
        case CITY_REQUEST:
            return { ...state, Cities: { all: state.Cities.all, error: '', isLoading: true } };
        // case SET_ALL_CITIES:
        //     return { ...state, Cities: { all: action.payload, error: '', isLoading: false } };
        case CITY_REQUEST_FAIL:
            return { ...state, Cities: { all: [], error: action.payload, isLoading: false } };

        case ALL_CITIES_REQUEST:
            return { ...state, allCities: { all: state.allCities.all, error: '', isLoading: true } };
        case ALL_CITIES_REQUEST_SUCCESS:
            return { ...state, allCities: { all: action.payload, error: '', isLoading: false } };
        case ALL_CITIES_REQUEST_FAIL:
            return { ...state, allCities: { all: [], error: action.payload, isLoading: false } };

        case PARTNERS_BY_CITY_REQUEST:
            return { ...state, CityPartners: { all: state.CityPartners.all, error: '', isLoading: true } };
        case PARTNERS_BY_CITY_REQUEST_SUCCESS:
            return { ...state, CityPartners: { all: action.payload, error: '', isLoading: false } };
        case PARTNERS_BY_CITY_REQUEST_FAIL:
            return { ...state, CityPartners: { all: [], error: action.payload, isLoading: false } };

        case DEALS_BY_CITY_REQUEST:
            return { ...state, CityDeals: { all: state.CityDeals.all, error: '', isLoading: true } };
        case DEALS_BY_CITY_REQUEST_SUCCESS:
            return { ...state, CityDeals: { all: action.payload, error: '', isLoading: false } };
        case DEALS_BY_CITY_REQUEST_FAIL:
            return { ...state, CityDeals: { all: [], error: action.payload, isLoading: false } };

        case COUPONS_BY_CITY_REQUEST:
            return { ...state, CityCoupons: { all: state.CityCoupons.all, error: '', isLoading: true } };
        case COUPONS_BY_CITY_REQUEST_SUCCESS:
            return { ...state, CityCoupons: { all: action.payload, error: '', isLoading: false } };
        case COUPONS_BY_CITY_REQUEST_FAIL:
            return { ...state, CityCoupons: { all: [], error: action.payload, isLoading: false } };

        case PRODUCTS_BY_CITY_REQUEST:
            return { ...state, CityProducts: { all: state.CityProducts.all, error: '', isLoading: true } };
        case PRODUCTS_BY_CITY_REQUEST_SUCCESS:
            return { ...state, CityProducts: { all: action.payload, error: '', isLoading: false } };
        case PRODUCTS_BY_CITY_REQUEST_FAIL:
            return { ...state, CityProducts: { all: [], error: action.payload, isLoading: false } };

        case ALL_CITY_PRODUCTS_REQUEST:
            return { ...state, CityProducts: { all: state.CityProducts.all, error: '', isLoading: true } };
        case ALL_CITY_PRODUCTS:
            return { ...state, CityProducts: { all: action.payload, error: '', isLoading: false } };
        case ALL_CITY_PRODUCTS_FAIL:
            return { ...state, CityProducts: { all: [], error: action.payload, isLoading: false } };

        case DEALS_BY_CITY_REQUEST:
            return { ...state, CityDeals: { all: state.CityDeals.all, error: '', isLoading: true } };
        case ALL_CITY_DEALS:
            return { ...state, CityDeals: { all: action.payload, error: '', isLoading: false } };
        case ALL_CITY_DEALS_FAIL:
            return { ...state, CityDeals: { all: [], error: action.payload, isLoading: false } };

        case ALL_CITY_COUPONS_REQUEST:
            return { ...state, CityCoupons: { all: state.CityCoupons.all, error: '', isLoading: true } };
        case ALL_CITY_COUPONS:
            return { ...state, CityCoupons: { all: action.payload, error: '', isLoading: false } };
        case ALL_CITY_COUPONS_FAIL:
            return { ...state, CityCoupons: { all: [], error: action.payload, isLoading: false } };

        case NOTIFY_BY_CITY_REQUEST:
            return { ...state, CityNotify: { all: state.CityNotify.all, error: '', isLoading: true } };
        case NOTIFY_BY_CITY_REQUEST_SUCCESS:
            return { ...state, CityNotify: { all: action.payload, error: '', isLoading: false } };
        case NOTIFY_BY_CITY_REQUEST_FAIL:
            return { ...state, CityNotify: { all: [], error: action.payload, isLoading: false } };

        case CITY_PARTNERRESET:
            return {
                ...state, CityPartners: { all: [], error: '', isLoading: false },
            };


        default:
            return state;
    }
}