import { Feather } from '@expo/vector-icons';
import React, { FC, memo, useCallback, useMemo, useRef, useState } from 'react'
import { Dimensions, Platform, Text, View } from 'react-native'
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown'
import { useSelector } from 'react-redux';

interface Props {
  label: string;
  data: Array<{ id: string; title: string }>;
  onSelect: (item: { id: string; title: string }) => void;
  selected: { id: string; title: string };
  loading: boolean;
  getSuggestions: (q: string) => void;
  onClear: () => void;
  maxWidth:number
}

export const EsiAutocompleteDropdown: FC<Props> = ({ label, data, onSelect, selected, loading, getSuggestions, onClear,maxWidth=null }) => {

  const searchRef = useRef(null);
  const dropdownController = useRef(null);
  const esiColor = useSelector(state => state.theme);

  const onClearPress = useCallback(() => {
    onSelect(null);
    getSuggestions("");
    onClear();
  }, [])
  const onOpenSuggestionsList = useCallback(isOpened => { }, [])
  const acdd = useMemo(() => {
    return <AutocompleteDropdown
      ref={searchRef}
      closeOnBlur={false}
      clearOnFocus={false}
      initialValue={selected?.id || ""}
      direction={Platform.select({ ios: 'down' })}
      controller={controller => {
        dropdownController.current = controller
      }}
      dataSet={data}
      onChangeText={getSuggestions}
      onSelectItem={item => {
        item && onSelect(item)
      }}
      debounce={600}
      suggestionsListMaxHeight={Dimensions.get('window').height * 0.3}
      onClear={onClearPress}
      // onSubmit={e => console.log(e.nativeEvent.text)}
      onOpenSuggestionsList={onOpenSuggestionsList}
      loading={loading}
      useFilter={false} // prevent rerender twice
      textInputProps={{
        placeholder: label,
        autoCorrect: false,
        autoCapitalize: 'none',
        // editable: false,
        onChange:(()=>{}),
        onChangeText:(()=>{}),
        style: {
          borderRadius: 6,
          color: esiColor.Text,
          paddingLeft: 18,
          marginRight: -10,
          height: 50
        },
      }}
      rightButtonsContainerStyle={{
        height: 50,
        alignSelf: 'center'
      }}
      inputContainerStyle={{
        borderRadius: 6,
        paddingRight: Platform.OS == "android" ? 0 : 10,
        // backgroundColor: '#fff',
        backgroundColor:esiColor.BackgroundColor,

        borderWidth: 1,
        borderColor: esiColor.BorderColor,
        shadowColor: '#00000099',
        shadowOffset: {
          width: 0,
          height: 5,
        },
        shadowOpacity: 0.3,
        shadowRadius: 8.46,

        elevation: 13
      }}
      suggestionsListContainerStyle={maxWidth?{
        position: "relative",
        marginTop: -30,
        // marginBottom: 50
        maxWidth:maxWidth
      }:{
        position: "relative",
        marginTop: -30
      }
      }
      containerStyle={{
        flexGrow: 1,
        //  flexShrink: 1
      }}
      renderItem={(item, text) => {
        return <Text style={{ color: esiColor.Text, padding: 15,
        backgroundColor:esiColor.BackgroundColor,
        borderColor:'gray',
        borderWidth:0.5
      
      }}>{item.title}</Text>
      }}
      ChevronIconComponent={<Feather name="chevron-down" size={20} style={{ marginRight: 5, marginLeft: -5 }} color="#383b42" />}
      ClearIconComponent={<Feather name="x-circle" size={18} color="#383b42" />}
      inputHeight={50}
    // showChevron={false}
    showClear={false}
    />
  }, [selected, data])

  return (<>
    <View
      style={[
        { flex: 1, flexDirection: 'row', alignItems: 'center' },
        Platform.select({ ios: { zIndex: 1 } }),
      ]}>
      {acdd}
    </View>
  </>
  )
}