import axios from "axios";
import { boomiApi } from "./commonAxios";
export const USERUPI_REQUEST = "USERUPI_REQUEST";
export const USERUPI_SUCCESS = "USERUPI_SUCCESS";
export const USERUPI_FAIL = "USERUPI_FAIL";
export const USERUPI_GET_REQUEST = "USERUPI_GET_REQUEST";
export const USERUPI_GET_SUCCESS = "USERUPI_GET_SUCCESS";
export const USERUPI_GET_FAIL = "USERUPI_GET_FAIL";

export const USERUPI_UPDATE_REQUEST = "USERUPI_UPDATE_REQUEST";
export const USERUPI_UPDATE_SUCCESS = "USERUPI_UPDATE_SUCCESS";
export const USERUPI_UPDATE_FAIL = "USERUPI_UPDATE_FAIL";


export const getUserUPIAction = (formData: any) => async dispatch => {
  try {
    dispatch({
      type: USERUPI_REQUEST
    });
    let { data, status } = await boomiApi.post(
      `/User_Upi/Get`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: USERUPI_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: USERUPI_SUCCESS,
          payload: data.USER_UPI[0],
        });
      }
    }
  } catch (err) {
    dispatch({
      type: USERUPI_FAIL,
      payload: err
    });
  }
};


export const UpdateUPIAction = (formData: any, EmailID: any) => async (dispatch: any) => {
  dispatch({
    type: USERUPI_UPDATE_REQUEST
  });
  await boomiApi.post(`/User_Upi/Update`, {
    User_Email_Id: EmailID,
    Paypal_Id: formData.Paypal_Id,
    Upi_Id: formData.Upi_Id,
    User_Upi_Id: formData.User_Upi_Id
  })
    .then(response => {
      let successResponse = response.data;

      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: USERUPI_UPDATE_SUCCESS
        });
        let formValue = {
          Records_Filter: "FILTER",
          USER_EMAIL_ID: EmailID,
        };

        dispatch(getUserUPIAction(formValue));
      } else {
        const errors = {};
        dispatch({
          type: USERUPI_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: USERUPI_UPDATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};