import axios from "axios";
import { boomiApi } from "./commonAxios";
export const SET_ALL_NEWSEVENTS = "SET_ALL_NEWSEVENTS";
export const ALL_NEWSEVENTS_REQUEST = "ALL_NEWSEVENTS_REQUEST";
export const ALL_NEWSEVENTS_FAIL = "ALL_NEWSEVENTS_FAIL";
export const GET_SINGLE_NEWSEVENT = "GET_SINGLE_FAQ";
export const GET_SINGLE_NEWSEVENT_REQUEST = "GET_SINGLE_NEWSEVENT_REQUEST";
export const GET_SINGLE_NEWSEVENT_FAIL = "GET_SINGLE_NEWSEVENT_FAIL";
export const CONTENTRESET = "CONTENTRESET";
/*=============================================================
                  Get All Categories Action
===============================================================*/

export const getAllNewseventsAction = () => async (dispatch: any) => {
    try {
        dispatch({
            type: ALL_NEWSEVENTS_REQUEST,
        });
        const formData = {
            "Author": "",
            "News_Events_Id": "",
            "Records_Filter": "",
            "Status": "",
            "Title": ""
        };

        let { data } = await boomiApi.post(
            `/News/News_Event_Get?PageNo=0&PageSize=100&SortBy=TITLE&SortOrder=ASC`,
            formData
        );

        if (data) {
            dispatch({
                type: SET_ALL_NEWSEVENTS,
                payload: data.results
            });

        }
    }
    catch (err) {
        dispatch({
            type: ALL_NEWSEVENTS_FAIL,
            payload: err
        });
    }
};
/*=============================================================
                  Get Newsevent By Id Action
===============================================================*/

export const getNewseventByIdAction = (newseventId: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: GET_SINGLE_NEWSEVENT_REQUEST,
        });
        const formData = {
            Records_Filter: "FILTER",
            News_Events_Id: "newseventId",
        };

        let { data } = await boomiApi.post(
            `/News/News_Event_Get?PageNo=0&PageSize=100&SortBy=TITLE&SortOrder=ASC`,
            formData,
        );
        if (data) {
            dispatch({
                type: GET_SINGLE_NEWSEVENT,
                payload: data,
            });
        }
    } catch (error) {
        dispatch({
            type: GET_SINGLE_NEWSEVENT_FAIL,
        });
    }
};
export const contentreset = () => async (dispatch: any) => {
    dispatch({
        type: CONTENTRESET,
        payload: []
    });
}
