import {
    DASHBOARD_REQUEST,
    DASHBOARD_SUCCESS,
    DASHBOARD_FAIL,
    DASHBOARD_GET_REQUEST,
    DASHBOARD_GET_SUCCESS,
    DASHBOARD_GET_FAIL
} from '../actions/dashboardActions'

const initialState = {

   dashboards: { all: [], error: '', isLoading: false },
    dashboard: { dashboard: [], error: '', isLoading: false },
};
export default function (state = initialState, action:any) {
    switch (action.type) {
            case DASHBOARD_REQUEST:
                return { ...state, dashboards: { all: state.dashboards.all, error: '', isLoading: true } };
              case DASHBOARD_SUCCESS:
                return { ...state, dashboards: { all: action.payload, error: '', isLoading: false } };
              case DASHBOARD_FAIL:
                return { ...state, dashboards: { all: [], error: action.payload, isLoading: false } };
          
              case DASHBOARD_GET_REQUEST:
                return { ...state, dashboard: { dashboard: {}, error: '', isLoading: true } };
              case DASHBOARD_GET_SUCCESS:
                return { ...state, dashboard: { dashboard: action.payload, error: '', isLoading: false } };
              case DASHBOARD_GET_FAIL:
                return { ...state, dashboard: { dashboard: {}, error: action.payload, isLoading: false } };
          
        default:
            return state;
    }
}

