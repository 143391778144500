// import { toast } from 'react-toastify';
// import base64 from 'react-native-base64'
import axios from "axios";
import Base64 from "../../hooks/Base64";
import { SET_ALL_PRODUCTS } from "../types";
import isEmpty from "../validations/is-empty";
import { boomiApi } from "./commonAxios";

export const ALL_PRODUCTS_REQUEST = "ALL_PRODUCTS_REQUEST";
export const ALL_PRODUCTS_FAIL = "ALL_PRODUCTS_FAIL";
export const PRODUCTRESET = "PRODUCTRESET";
export const PRODUCTSIZERESET = "PRODUCTSIZERESET";
export const ALL_IMAGE_PRODUCTS_REQUEST = "ALL_IMAGE_PRODUCTS_REQUEST";
export const ALL_IMAGE_PRODUCTS = "ALL_IMAGE_PRODUCTS";
export const ALL_IMAGE_PRODUCTS_FAIL = "ALL_IMAGE_PRODUCTS_FAIL";
export const ALL_USER_REVIEWS_REQUEST = "ALL_USER_REVIEWS_REQUEST";
export const ALL_USER_REVIEWS = "ALL_USER_REVIEWS";
export const ALL_USER_REVIEWS_FAIL = "ALL_USER_REVIEWS_FAIL";

export const ALL_PRODUCTS_PARTNER_REQUEST = "ALL_PRODUCTS_PARTNER_REQUEST";
export const ALL_PRODUCTS_BRAND_REQUEST = "ALL_PRODUCTS_BRAND_REQUEST";
export const SET_ALL_FILTER_PARTNER_PRODUCTS = "SET_ALL_FILTER_PARTNER_PRODUCTS";
export const SET_ALL_FILTER_BRAND_PRODUCTS = "SET_ALL_FILTER_BRAND_PRODUCTS";

export const REVIEW_CREATE_REQUEST = "REVIEW_CREATE_REQUEST";
export const REVIEW_CREATE_SUCCESS = "REVIEW_CREATE_SUCCESS";
export const REVIEW_CREATE_FAIL = "REVIEW_CREATE_FAIL";
export const REVIEW_UPDATE_REQUEST = "REVIEW_UPDATE_REQUEST";
export const REVIEW_UPDATE_SUCCESS = "REVIEW_UPDATE_SUCCESS";
export const REVIEW_UPDATE_FAIL = "REVIEW_UPDATE_FAIL";
export const ALL_PARTNER_PRODUCTS_REQUEST = "ALL_PARTNER_PRODUCTS_REQUEST";
export const ALL_PARTNER_PRODUCTS = "ALL_PARTNER_PRODUCTS";
export const ALL_PARTNER_PRODUCTS_FAIL = "ALL_PARTNER_PRODUCTS_FAIL";
export const SET_ALL_PARTNER_PRODUCTS = "SET_ALL_PARTNER_PRODUCTS";
export const ALL_RELATED_PRODUCTS_REQUEST = "ALL_RELATED_PRODUCTS_REQUEST";
export const SET_ALL_RELATED_PRODUCTS = "SET_ALL_RELATED_PRODUCTS";
export const ALL_RELATED_PRODUCTS_FAIL = "ALL_RELATED_PRODUCTS_FAIL";
export const ALL_RELATED_REQUEST = "ALL_RELATED_REQUEST";
export const SET_ALL_RELATED = "SET_ALL_RELATED";
export const ALL_RELATED_FAIL = "ALL_RELATED_FAIL";
export const ALL_COLOR_REQUEST = "ALL_COLOR_REQUEST";
export const SET_ALL_COLOR = "SET_ALL_COLOR";
export const ALL_COLOR_FAIL = "ALL_COLOR_FAIL";
export const ALL_SIZE_REQUEST = "ALL_SIZE_REQUEST";
export const SET_ALL_SIZE = "SET_ALL_SIZE";
export const ALL_SIZE_FAIL = "ALL_SIZE_FAIL";
export const VISITOR_CREATE_REQUEST = "VISITOR_CREATE_REQUEST";
export const VISITOR_CREATE_SUCCESS = "VISITOR_CREATE_SUCCESS";
export const VISITOR_REVIEW_CREATE_FAIL = "VISITOR_REVIEW_CREATE_FAIL";
// export const ALL_PRODUCTS_BRAND_REQUEST = "ALL_PRODUCTS_BRAND_REQUEST";
export const ALL_FILTER_BRAND_PRODUCTS = "ALL_FILTER_BRAND_PRODUCTS";
export const ALL_PRODUCTS_BRAND_FAIL = "ALL_PRODUCTS_BRAND_FAIL";
export const TOP_PRODUCT = "TOP_PRODUCT";
export const TOP_PRODUCT_FAIL = "TOP_PRODUCT_FAIL";
export const TOP_PRODUCT_SUCCESS = "TOP_PRODUCT_SUCCESS";
export const ALL_VISITED_REQUEST = "ALL_VISITED_REQUEST";
export const SET_ALL_VISITED = "SET_ALL_VISITED";
export const ALL_VISITED_FAIL = "ALL_VISITED_FAIL";
export const VISITEDPRODUCTRESET = "VISITEDPRODUCTRESET";
export const ALL_SUBCATEGORY_PRODUCTS_REQUEST = "ALL_SUBCATEGORY_PRODUCTS_REQUEST";
export const ALL_SUBCATEGORY_PRODUCTS = "ALL_SUBCATEGORY_PRODUCTS";
export const ALL_SUBCATEGORY_PRODUCTS_FAIL = "ALL_SUBCATEGORY_PRODUCTS_FAIL";
export const VISITED_PRODUCTS_REQUEST = "VISITED_PRODUCTS_REQUEST";
export const VISITED_PRODUCTS_SUCCESS = "VISITED_PRODUCTS_SUCCESS";

/*=============================================================
                  Get All Products Action
===============================================================*/

export const adduservisitAction = (formData: any) => async (dispatch: any) => {

  dispatch({
    type: VISITOR_CREATE_REQUEST,
  });
  boomiApi.post(`/Offers_Visitor/Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: VISITOR_CREATE_SUCCESS
        });
        // dispatch(getvisitedProductByIdAction(formData.User_Email_Id));
      } else {

        dispatch({
          type: VISITOR_REVIEW_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: VISITOR_REVIEW_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};



export const getProductByIdAction = (
  productId,
  callBackGetData
) => async () => {
  try {
    const formData = {
      Record_Filter: "FILTER",
      Product_Id: productId,
    };

    let { data } = await boomiApi.post(`/Products/Single_Partner_Products_Get?PageNo=0&PageSize=1000&SortBy=PRODUCT_NAME&SortOrder=ASC`, formData);
    if (data) {
      callBackGetData(data);
    }
  } catch (error) {
  }

};

export const getProductsImageAction = (ProductId) => async (dispatch) => {
  try {
    dispatch({
      type: ALL_IMAGE_PRODUCTS_REQUEST,
    });
    const formData = {
      Product_Id: ProductId,
      Records_Filter: "FILTER"
    }
    let { data } = await boomiApi.post(`/Products/Product_Image_Get?PageNo=0&PageSize=1000&SortBy=CREATED_BY&SortOrder=ASC`, formData,);

    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ALL_IMAGE_PRODUCTS,
          payload: data
        });
      } else {
        dispatch({
          type: ALL_IMAGE_PRODUCTS,
          payload: data.Images,
        });

      }
    }
  } catch (error) {
    dispatch({
      type: ALL_IMAGE_PRODUCTS_FAIL,
      payload: error
    });
  }
};

export const getUserReviewsbyIdAction = (ProductId) => async (dispatch) => {

  try {
    dispatch({
      type: ALL_USER_REVIEWS_REQUEST,
    });
    const formData = {
      Product_Id: ProductId,
      Records_Filter: "FILTER"
    }
    let { data } = await boomiApi.post(
      `/Reviews/Product_Review_Get?PageNo=0&PageSize=10000&SortBy=NAME&SortOrder=ASC`,
      formData,
    );
    if (data) {
      dispatch({
        type: ALL_USER_REVIEWS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: ALL_USER_REVIEWS_FAIL,
      payload: error
    });
  }
};

export const getPartnerproductsbyIdAction = (ProductId) => async (dispatch) => {
  try {
    dispatch({
      type: ALL_PARTNER_PRODUCTS_REQUEST,
    });
    const formData = {
      Records_Filter: "FILTER",
      Product_Id: ProductId,
      Status_Condition: "Active"
    };


    let { data } = await boomiApi.post(
      `/Partners/Partner_Product_Online_Physical`,
      formData,

    );
    if (data) {
      dispatch({
        type: SET_ALL_PARTNER_PRODUCTS,
        payload: data.Partner_Product,
      });
    }
  } catch (error) {
    dispatch({
      type: ALL_PARTNER_PRODUCTS_FAIL,
      payload: error
    });
  }
};

export const getRelatedProductByIdAction = (
  Id
) => async (dispatch) => {
  try {
    dispatch({
      type: ALL_RELATED_REQUEST,
    });
    const formData = {
      Records_Filter: "FILTER",
      Product_Id: Id,
    };

    let { data } = await boomiApi.post(
      `/Products/Related_Product_Get`,
      formData,
    );
    if (data) {
      dispatch({
        type: SET_ALL_RELATED,
        payload: data.Products,
      });
    }
  } catch (error) {
    dispatch({
      type: ALL_RELATED_FAIL,
      payload: error
    });
  }
};

export const getRelatedProductAction = (ID) => async (dispatch) => {
  try {
    dispatch({
      type: ALL_RELATED_PRODUCTS_REQUEST,
    });
    let formData = {
      Record_Filter: "FILTER",
      Product_Id: ID
    }
    let { data } = await boomiApi.post(
      `/Products/Single_Partner_Products_Get?PageNo=0&PageSize=1000&SortBy=PRODUCT_NAME&SortOrder=ASC`,
      formData,
    );
    if (data) {
      dispatch({
        type: SET_ALL_RELATED_PRODUCTS,
        payload: data.Product,
      });
    }
  } catch (error) {
    dispatch({
      type: ALL_RELATED_PRODUCTS_FAIL,
      payload: error
    });
  }
};



export const getProductColorsAction = (masterId) => async (dispatch) => {
  let formData = {
    Product_Master_Id: masterId
  }

  try {
    // dispatch({
    //   type: ALL_COLOR_REQUEST,
    // });
    let { data } = await boomiApi.post(
      `/Products/Product_Color_Variant`,
      formData,
    );

    if (data) {
      if (data.Is_Data_Exist === "0") {
        dispatch({
          type: SET_ALL_COLOR,
          payload: data.Color_Variant.Color_Variant,
        });
      }
      else {
        dispatch({
          type: SET_ALL_COLOR,
          payload: data.Color_Variant.Color_Variant,
        });
      }
    }

  } catch (error) {
    dispatch({
      type: ALL_COLOR_FAIL,
      payload: error
    });
  }
};

export const getProductSizeAction = (pid, pdid) => async (dispatch) => {
  let formData = {
    Partner_Details_Id: pdid,
    Product_Id: pid
  }
  try {
    dispatch({
      type: ALL_SIZE_REQUEST,
    });
    let { data } = await boomiApi.post(
      `/Products/User_Product_Size_Get`,
      formData,
    );

    if (data) {
      if (data.Is_Data_Exist === "0") {
        dispatch({
          type: SET_ALL_SIZE,
          payload: data,
        });
      }

      else {
        dispatch({
          type: SET_ALL_SIZE,
          payload: data.Product_Size.Product_Size,
        });
      }

    }
  } catch (error) {
    dispatch({
      type: ALL_SIZE_FAIL,
      payload: error
    });
  }
};

export const getProductSizeActionWishList = (pid, pdid, wishlistId, setResponse) => async (dispatch) => {
  let formData = {
    Partner_Details_Id: pdid,
    Product_Id: pid
  }
  try {
    let { data } = await boomiApi.post(
      `/Products/User_Product_Size_Get`,
      formData,

    );

    if (data) {
      if (data.Is_Data_Exist === "0") {
        setResponse(true, [], wishlistId, data.UI_Display_Message);
      }
      else {
        setResponse(true, data.Product_Size.Product_Size[0].Product_Size.Product_Size, wishlistId, "Success response.")
      }

    }
  } catch (error) {
    setResponse(false, [], wishlistId, "Please try again later.")
  }
};

export const productsizereset = () => async (dispatch) => {
  dispatch({
    type: PRODUCTSIZERESET,
    payload: []
  });
}


export const addProductReviewAction = (formData, ProductId, responseFunction) => async (dispatch) => {

  dispatch({
    type: REVIEW_CREATE_REQUEST,
  });
  boomiApi.post(`/Reviews/Product_Review_Create`, formData,)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: REVIEW_CREATE_SUCCESS
        });
        dispatch(getUserReviewsbyIdAction(ProductId));
        responseFunction(true, "Review added successfully.");
      } else {
        responseFunction(false, successResponse.UI_Display_Message);
        dispatch({
          type: REVIEW_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      responseFunction(false, "Please try sometime later.Currently server is not working");
      dispatch({
        type: REVIEW_CREATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

export const editProductReviewAction = (formData, Product_Id, responseFunctionUpdate) => async (dispatch) => {

  dispatch({
    type: REVIEW_UPDATE_REQUEST,
  });
  boomiApi.put(`/Reviews/Product_Review_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: REVIEW_UPDATE_SUCCESS
        });
        responseFunctionUpdate(true, 'Review updated successfully.');
        dispatch(getUserReviewsbyIdAction(Product_Id));
      } else {
        responseFunctionUpdate(true, successResponse.UI_Display_Message);
        dispatch({
          type: REVIEW_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      responseFunctionUpdate(true, "Please try sometime later.Currently server is not working");
      dispatch({
        type: REVIEW_UPDATE_FAIL,
        payload: "Please try sometime later.Currently server is not working"
      });
    })
};

export const resetRecentlyProducts = () => async (dispatch) => {
  dispatch({
    type: VISITEDPRODUCTRESET,
    payload: []
  });
}

export const getvisitedProductByIdAction = (EmailID) => async (dispatch) => {
  try {
    dispatch({
      type: ALL_VISITED_REQUEST,
    });
    const formData = {
      User_Email_Id: EmailID,
    };
    let { data } = await boomiApi.post(
      `/Products/Single_Partner_Product_Get_New`,
      formData
    );

    if (data) {

      if (data?.Success_Response?.Is_Data_Exist === "1") {
        dispatch({
          type: SET_ALL_VISITED,
          payload: data?.Product || [],
        });
      } else {
        dispatch({
          type: SET_ALL_VISITED,
          payload: [],
        });
      }
    }

  } catch (error) {
    dispatch({
      type: ALL_VISITED_FAIL,
      payload: error
    });
  }

};

export const getProductForVisit = (productId) => async (dispatch: any) => {
  dispatch({
    type: VISITED_PRODUCTS_REQUEST,
    payload: []
  });
  try {
    const formData = {
      Record_Filter: "FILTER",
      Product_Id: productId,
    };

    let { data } = await boomiApi.post(
      `/Products/Single_Partner_Products_Get?PageNo=0&PageSize=1000&SortBy=PRODUCT_NAME&SortOrder=ASC`,
      formData
    );
    if (data) {
      if (data.Success_Response.Response_Status === "Success") {
        dispatch({
          type: VISITED_PRODUCTS_SUCCESS,
          payload: data.Product
        });
      } else {
        dispatch({
          type: VISITED_PRODUCTS_SUCCESS,
          payload: []
        });
      }
    }
  } catch (error) {
  }

};
