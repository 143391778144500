import { useCallback, useEffect, useState } from "react";
import { Image, Platform, Text, View } from "react-native";
import EsiCheckbox from "../../../components/custom/checkbox";

export const ExtraCashBackFilter = (props) => {
    const { filters, setFilters } = props;

    const data = useCallback([{ value: 1, label: "Extra Cashback", chacked: filters?.extra || false }],[filters]);

    const onchange = res => {
        setFilters(props=>{
            return {...props,extra:!props.extra}});
    }

    return (
        <View style={[{ marginHorizontal: 5 }, Platform.select({ ios: { zIndex: 100 } })]}>
            <View style={{ flexDirection: "row" }}>
                <Image style={{ width: 25, height: 25, marginRight: 10 }}
                    source={require('../../../assets/images/favicon.png')}
                />
                <Text style={{
                    fontSize: 14, color: "#03dac4",
                    marginTop: 3,
                }}>Extra Cashback</Text>
            </View>
            <EsiCheckbox data={data} onchange={onchange} />
        </View>
    )
}