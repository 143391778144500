import Base64 from "../../hooks/Base64";
import getEnvVars from '../../../environment';
const { BOOMI_HOST, BOOMI_HOST_DEL, USERNAME_BOOMI, PASSWORD_BOOMI, NODE_USER_HOST, USERNAME_USER_NODE, PASSWORD_USER_NODE, xApiKey, NODE_CUD_HOST, USERNAME_CUD_NODE, PASSWORD_CUD_NODE, CRYPTO_KEY } = getEnvVars();
export const PROD_SERVER = true;
export const DEVELOP_MEDE = false;
export const SERVER_HOST = PROD_SERVER ? BOOMI_HOST : NODE_USER_HOST;
export const AUTH_USERNAME = USERNAME_BOOMI;
export const AUTH_PASSWORD = PASSWORD_BOOMI;
const config = {
    // url
    url: BOOMI_HOST,
    delurl: BOOMI_HOST_DEL,
    // auth
    auth: {
        username: USERNAME_BOOMI,
        password: PASSWORD_BOOMI,
    },
    // headersCommon
    headersCommon: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        'Authorization': "Basic " + Base64.btoa(USERNAME_BOOMI + ":" + PASSWORD_BOOMI),
        // 'x-api-key':xApiKey
    },

    // node-user-auth
    nodeUserUrl: NODE_USER_HOST,
    nodeUserAuth: {
        username: USERNAME_USER_NODE,
        password: PASSWORD_USER_NODE,
    },
    //node-user-headers
    nodeUserHeadersCommon: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        // 'Authorization': "Basic " + Base64.btoa(USERNAME_USER_NODE + ":" + PASSWORD_USER_NODE)
    },
    nodeCUDUrl: NODE_CUD_HOST,
    nodeCUDUserHeadersCommon: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        // 'Authorization': "Basic " + Base64.btoa(USERNAME_CUD_NODE + ":" + PASSWORD_CUD_NODE)
    },
    cryptoKay: CRYPTO_KEY,
};
export default config;