import axios from "axios";
import { Console } from "console";
import config from './config';

export const EVENT_REQUEST = "EVENT_REQUEST";
export const EVENT_SUCCESS = "EVENT_SUCCESS";
export const EVENT_FAIL = "EVENT_FAIL";
export const HALL_REQUEST = "HALL_REQUEST";
export const HALL_SUCCESS = "HALL_SUCCESS";
export const HALL_FAIL = "HALL_FAIL";
export const ALL_EVENT_FILTER = "ALL_EVENT_FILTER";
export const EVENT_CREATE_REQUEST = "EVENT_CREATE_REQUEST";
export const EVENT_CREATE_SUCCESS = "EVENT_CREATE_SUCCESS";
export const EVENT_CREATE_FAIL = "EVENT_CREATE_FAIL";
export const EVENT_UPDATE_REQUEST = "EVENT_UPDATE_REQUEST";
export const EVENT_UPDATE_SUCCESS = "EVENT_UPDATE_SUCCESS";
export const EVENT_UPDATE_FAIL = "EVENT_UPDATE_FAIL";


/*=============================================================
                  Get All Brands Action
===============================================================*/

const headers = config.headersCommon;

export const getFunctionHallsAction = (formData: any) => async (dispatch: any) => {
  try {
    let { data } = await axios.post(
      `${config.url}/Function_Hall/GET?PageNo=0&PageSize=500&SortBy=NAME&SortOrder=ASC`,
      formData,
      {
        headers: headers
        // auth: auth,
      },
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: HALL_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: HALL_SUCCESS,
          payload: data[0].Entry1
        });
      }
    }
  } catch (error) {
    dispatch({
      type: EVENT_FAIL,
    });
  }
};

export const getEventsAction = (formData: any) => async (dispatch: any) => {
  try {
    let { data } = await axios.post(
      `${config.url}/User_Event/Get?PageNo=0&PageSize=500&SortBy=NAME&SortOrder=ASC`,
      formData,
      {
        headers: headers
        // auth: auth,
      },
    );
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: EVENT_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: EVENT_SUCCESS,
          payload: data[0].Entry1
        });
      }
    }
  } catch (error) {
    dispatch({
      type: EVENT_FAIL,
    });
  }
};

export const eventsFilter = (fType: any, value: any) => {
  return function (dispatch) {
    dispatch({
      type: ALL_EVENT_FILTER,
      fType: fType,
      value: value,
    });
  }
}

export const addEventsAction = (formData: any, navigation: any, EmailID: any, toast: any) => async dispatch => {
  dispatch({
    type: EVENT_CREATE_REQUEST
  });
  await axios.post(`${config.url}/User_Event/Create`, formData, {
    headers: headers,
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {

        dispatch({
          type: EVENT_CREATE_SUCCESS
        });
        navigation("AllEvents");
        toast.show({ message: 'Event Created Successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          Records_Filter: "FILTER",
          User_Email_Id: formData.USER_EMAIL_ID,
        };
        dispatch(getEventsAction(formValue))
      } else {
        toast.show({ message: successResponse.UI_Display_Message, type: 'info', duration: 3000, position: 'top' });
        const errors = {};
        dispatch({
          type: EVENT_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      toast.show({ message: 'Network error please try again.', type: 'info', duration: 3000, position: 'top' });
      dispatch({
        type: EVENT_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
}

export const updateEventsAction = (formData: any, navigation: any, EmailID: any, toast: any) => async dispatch => {
  dispatch({
    type: EVENT_UPDATE_REQUEST
  });
  await axios.put(`${config.url}/User_Event/Update`, formData, {
    headers: headers,
  })
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {

        dispatch({
          type: EVENT_UPDATE_SUCCESS
        });
        navigation("AllEvents");
        toast.show({ message: 'Address Created Successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          Records_Filter: "FILTER",
          User_Email_Id: formData.User_Email_ID,
        };
        dispatch(getEventsAction(formValue))
      } else {
        toast.show({ message: successResponse.UI_Display_Message, type: 'info', duration: 3000, position: 'top' });
        const errors = {};
        dispatch({
          type: EVENT_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      toast.show({ message: 'Network error please try again.', type: 'info', duration: 3000, position: 'top' });
      dispatch({
        type: EVENT_UPDATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
}

export const getHallIdAction = (formData: any, callBackGetDealData: any) => async (dispatch: any) => {
  try {
    let { data } = await axios.post(
      `${config.url}/Function_Hall/GET?PageNo=0&PageSize=500&SortBy=NAME&SortOrder=ASC`,
      formData,
      {
        headers: headers,
      }
    );
    if (data) {
      callBackGetDealData(data[0].Entry1[0]);
    }
  } catch (error) {

  }
};


export const getEventIdAction = (formData: any, callBackGetDealData: any) => async (dispatch: any) => {
  try {
    let { data } = await axios.post(
      `${config.url}/User_Event/Get?PageNo=0&PageSize=500&SortBy=NAME&SortOrder=ASC`,
      formData,
      {
        headers: headers,
      }
    );
    if (data) {
      callBackGetDealData(data[0].Entry1[0]);
    }
  } catch (error) {

  }
};
