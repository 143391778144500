import { Caption } from 'react-native-paper';
import {
  SET_ALL_COUPONS,
  SET_ALL_PRODUCTS,
  ALL_PRODUCTS_FAIL,
  ALL_COUPONS_REQUEST,
  ALL_COUPONS_PARTNER_REQUEST,
  ALL_COUPONS_BRAND_REQUEST,
  SET_ALL_FILTER_PARTNER_COUPONS,
  SET_ALL_FILTER_BRAND_COUPONS,
  VISITOR_CREATE_REQUEST,
  VISITOR_CREATE_SUCCESS,
  ALL_PRODUCTS_REQUEST,
  VISITOR_REVIEW_CREATE_FAIL,
  ALL_COUPONS_FAIL,
  // COUPONS_FILTER_REQUEST,
  // ALL_COUPONS_FILTER,
  // ALL_COUPONS_FAIL,
} from '../actions/couponsActions'

const initialState = {
  products: { all: [], error: '', isLoading: false },
  coupons: { all: [], error: '', isLoading: false },
  brandcoupons: { all: [], error: '', isLoading: false },
  partnercoupons: { all: [], error: '', isLoading: false },
  visitorCreate: { visitor: {}, error: '', isLoading: false },
  // couponFilters: { all: {}, error: '', isLoading: false },
};

export default function couponsReducer(state = initialState, action: any) {
  switch (action.type) {
    case ALL_PRODUCTS_REQUEST:
      return { ...state, products: { all: state.products.all, error: '', isLoading: true } };
    case SET_ALL_PRODUCTS:
      return { ...state, products: { all: action.payload, error: '', isLoading: false } };
    case ALL_PRODUCTS_FAIL:
      return { ...state, products: { all: [], error: action.payload, isLoading: false } };
    case SET_ALL_COUPONS:
      return { ...state, coupons: { all: action.payload, error: '', isLoading: false } };
    case ALL_COUPONS_REQUEST:
      return { ...state, coupons: { all: action.payload, error: '', isLoading: true } };
    case ALL_COUPONS_FAIL:
      return { ...state, coupons: { all: [], error: '', isLoading: false } };
    case ALL_COUPONS_PARTNER_REQUEST:
      return { ...state, partnercoupons: { all: state.partnercoupons.all, error: '', isLoading: true } };
    case ALL_COUPONS_BRAND_REQUEST:
      return { ...state, brandcoupons: { all: state.brandcoupons.all, error: '', isLoading: true } };
    case SET_ALL_FILTER_BRAND_COUPONS:
      return { ...state, brandcoupons: { all: action.payload, error: '', isLoading: false } };
    case SET_ALL_FILTER_PARTNER_COUPONS:
      return { ...state, partnercoupons: { all: action.payload, error: '', isLoading: false } };
    case VISITOR_CREATE_REQUEST:
      return { ...state, visitorCreate: { all: state.visitorCreate.visitor, error: '', isLoading: true } };
    case VISITOR_CREATE_SUCCESS:
      return { ...state, visitorCreate: { all: action.payload, error: '', isLoading: false } };
    case VISITOR_REVIEW_CREATE_FAIL:
      return { ...state, visitorCreate: { all: [], error: action.payload, isLoading: false } };
    // case COUPONS_FILTER_REQUEST:
    //   return { ...state, couponFilters: { all: state.couponFilters.all, error: '', isLoading: true } };
    // case ALL_COUPONS_FILTER:
    //   return { ...state, couponFilters: { all: action.payload, error: '', isLoading: false } };
    // case ALL_COUPONS_FAIL:
    //   return { ...state, couponFilters: { all: [], error: action.payload, isLoading: false } };
    default:
      return state;
  }
}