
import axios from 'axios';
import { nodeCudApi } from './commonAxios';

export const NOTIFICATION_REQUEST = "NOTIFICATION_REQUEST";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_FAIL = "NOTIFICATION_FAIL";


export const updateNotificationAction = (formData: any, callBackData: any, email_id: any) => async (dispatch: any) => {
  dispatch({
    type: NOTIFICATION_REQUEST
  });
  // axios.put(`${config.url}/Notifications/Notification_Update_Flow`, formData,
  //   {
  //     headers: headers
  //   })
  nodeCudApi.put(`/Notifications/Notification_Update_Flow`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: NOTIFICATION_SUCCESS
        });
        callBackData();
      } else {
        const errors = {};
        dispatch({
          type: NOTIFICATION_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      dispatch({
        type: NOTIFICATION_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};