import { nodeGetApi as meeapi,urlGenarator } from "../commonAxios";


export const getMyBidRidesAction = (
    pagination = { PageNo: 1, PageSize: 29, SortBy: "TITLE", SortOrder: "ASC" },
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/bidding/rides/bided-rides', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, [], data?.Pagination);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(error.message == "Network Error" ? "network" : false, error);
    });
};

export const availabileBiddingVehicals = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/bidding/rides/vehicle-get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};


export const getBiddingVehicalBrands = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/bidding/rides/brands-get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (data.Success_Response.Is_Data_Exist == "0") {
            responseFunction(true, []);
        } else {
            try {
                responseFunction(true, data.results, data.Pagination);
            } catch (error) {
                responseFunction(true, data.results);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getBiddingVehicalComfortTypes = (
    formData = { Records_Filter: "All" }, pagination = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/bidding/rides/comfort-types-get', pagination),
        formData,
    ).then(response => {
        let data = response.data;
        if (Array.isArray(data)) {
            responseFunction(true, data);
        } else {
            responseFunction(true, []);
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getBiddingRidesCart = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/bidding/rides/bided-vehicle-cart', {}),
        formData,
    ).then(response => {
        let data = response.data;
        if (formData?.getCount) {
            try {
                responseFunction(true, data[0]);
            } catch (error) {

                responseFunction(true,
                    {
                        "Counts": 0
                    }
                );
            }
        } else {
            try {
                responseFunction(true, data);
            } catch (error) {
                responseFunction(true, []);
            }
        }
    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getBiddingRideDetails = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/bidding/rides/bided-ride-details', {}),
        formData,
    ).then(response => {
        let data = response.data;
        try {
            responseFunction(true, data);
        } catch (error) {
            responseFunction(true, {});
        }

    }).catch(error => {
        responseFunction(false, error);
    });
};

export const getBiddingPartnerRideDetails = (
    formData = {},
    responseFunction: any
) => async (dispatch: any) => {

    meeapi.post(urlGenarator('/bidding/rides/bided-delivery-ride-details', {}),
        formData,
    ).then(response => {
        let data = response.data;
        try {
            responseFunction(true, data);
        } catch (error) {
            responseFunction(true, {});
        }

    }).catch(error => {
        responseFunction(false, error);
    });
};