import axios from "axios";
import { boomiApi } from "./commonAxios";
export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAIL = "DASHBOARD_FAIL";
export const DASHBOARD_GET_REQUEST = "DASHBOARD_GET_REQUEST";
export const DASHBOARD_GET_SUCCESS = "DASHBOARD_GET_SUCCESS";
export const DASHBOARD_GET_FAIL = "DASHBOARD_GET_FAIL";


export const getdashboard = (formData: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: DASHBOARD_REQUEST
    });
    let { data, status } = await boomiApi.post(
      `/Dashboard/User_Dashboard_Get`,
      formData);

    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: DASHBOARD_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: DASHBOARD_SUCCESS,
          payload: data.Dashboard
        });
      }
    }
  } catch (err) {
    dispatch({
      type: DASHBOARD_FAIL,
      payload: err
    });
  }
};

export const addStatistics = (formData: any) => async (dispatch: any) => {
  try {
    let { data, status } = await boomiApi.post(
      `/User_Statistics/Create`,
      formData);

    if (data) {
    }
  } catch (err) {
  }
};