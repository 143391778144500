import axios from "axios";
import { boomiApi, nodeCudApi } from "./commonAxios";

export const ADDRESS_REQUEST = "ADDRESS_REQUEST";
export const ADDRESS_SUCCESS = "ADDRESS_SUCCESS";
export const ADDRESS_FAIL = "ADDRESS_FAIL";
export const ADDRESS_GET_REQUEST = "ADDRESS_GET_REQUEST";
export const ADDRESS_GET_SUCCESS = "ADDRESS_GET_SUCCESS";
export const ADDRESS_GET_FAIL = "ADDRESS_GET_FAIL";
export const ADDRESS_CREATE_REQUEST = "ADDRESS_CREATE_REQUEST";
export const ADDRESS_CREATE_SUCCESS = "ADDRESS_CREATE_SUCCESS";
export const ADDRESS_CREATE_FAIL = "ADDRESS_CREATE_FAIL";
export const ADDRESS_UPDATE_REQUEST = "ADDRESS_UPDATE_REQUEST";
export const ADDRESS_UPDATE_SUCCESS = "ADDRESS_UPDATE_SUCCESS";
export const ADDRESS_UPDATE_FAIL = "ADDRESS_UPDATE_FAIL";
export const ADDRESS_DELETE_REQUEST = "ADDRESS_DELETE_REQUEST";
export const ADDRESS_DELETE_SUCCESS = "ADDRESS_DELETE_SUCCESS";
export const ADDRESS_DELETE_FAIL = "ADDRESS_DELETE_FAIL";

/*=============================================================
                ADD Address Action
===============================================================*/

export const AddAddressAction = (formData: any, navigationStatus: any, EmailID: any, toast: any) => async dispatch => {
  dispatch({
    type: ADDRESS_CREATE_REQUEST
  });
  // await nodeCudApi.post(`/User/Address_Create`, formData, {
  //   headers: config.nodeCUDUserHeadersCommon,
  // })
  await nodeCudApi.post(`/User/Address_Create`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {

        dispatch({
          type: ADDRESS_CREATE_SUCCESS
        });
        navigationStatus();
        toast.show({ message: 'Address Created Successfully.', type: 'info', duration: 3000, position: 'top' });
        const formValue = {
          Records_Filter: "FILTER",
          User_Email_Id: EmailID,
        };
        dispatch(GetAllAddressAction(formValue))
      } else {
        toast.show({ message: successResponse.UI_Display_Message, type: 'info', duration: 3000, position: 'top' });
        const errors = {};
        dispatch({
          type: ADDRESS_CREATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      toast.show({ message: 'Network error please try again.', type: 'info', duration: 3000, position: 'top' });
      dispatch({
        type: ADDRESS_CREATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};
/*=============================================================
                Edit Address Action
===============================================================*/
export const EditAddressAction = (formData: any, navigation: any, EmailID: any, toast: any) => async dispatch => {
  dispatch({
    type: ADDRESS_UPDATE_REQUEST
  });
  // await axios.put(`${config.url}/User/Address_Update`, formData, {
  //   headers: headers,
  // })
  await nodeCudApi.put(`/User/Address_Update`, formData)
    .then(response => {
      let successResponse = response.data;
      if (successResponse.Response_Status == "Success") {
        dispatch({
          type: ADDRESS_UPDATE_SUCCESS
        });
        navigation.navigate('UserAddresses');
        toast.show({ message: 'Address Updated Successfully.', type: 'info', duration: 5000, position: 'top' });
        const formValue = {
          Records_Filter: "FILTER",
          User_Email_Id: EmailID,
        };
        dispatch(GetAllAddressAction(formValue))
      } else {

        toast.show({ message: successResponse.UI_Display_Message, type: 'info', duration: 5000, position: 'top' });
        dispatch({
          type: ADDRESS_UPDATE_FAIL,
          payload: successResponse.UI_Display_Message
        });
      }
    })
    .catch(error => {
      toast.show({ message: "Currently server is not working.Please try sometime later.", type: 'info', duration: 3000, position: 'top' });
      dispatch({
        type: ADDRESS_UPDATE_FAIL,
        payload: "Currently server is not working.Please try sometime later."
      });
    })
};

/*=============================================================
                GET All Address Action
===============================================================*/
export const getAllCartAddressAction =
  (updateStates: any, formData: any) => async (dispatch: any) => {
    try {
      let { data } = await boomiApi.post("/User/Address_Get", formData);
      if (data) {
        updateStates(data?.User_Address ? data?.User_Address : [], false);
      }
    } catch (error) {
    }
  };
export const GetAllAddressAction = (formData: any) => async dispatch => {

  try {
    dispatch({
      type: ADDRESS_REQUEST
    });
    let { data } = await boomiApi.post(`User/Address_Get?PageNo=0&PageSize=1000&SortBy=TITLE&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        dispatch({
          type: ADDRESS_SUCCESS,
          payload: []
        });
      } else {
        dispatch({
          type: ADDRESS_SUCCESS,
          payload: data.User_Address
        });
      }
    }
  } catch (err) {
    dispatch({
      type: ADDRESS_FAIL,
      payload: err
    });
  }
};

export const GetSingleAddressAction = (formData: any, responseFunction: Function) => async dispatch => {

  try {
    let { data } = await boomiApi.post(`/User/Address_Get?PageNo=0&PageSize=1000&SortBy=TITLE&SortOrder=ASC`, formData);
    if (data) {
      if (data.Is_Data_Exist === '0') {
        responseFunction(true, {}, "No address found.Please try again.");
      } else {
        responseFunction(true, data?.User_Address[0], "No address found.Please try again.");
      }
    }
  } catch (err) {
    responseFunction(false, {}, "Something went wrong, please try again.");
  }
};

/*=============================================================
                Delete Address Action
===============================================================*/
export const deleteAddressAction = (formData: any, EmailID: any, toast: any) => async (dispatch: any) => {
  try {
    // let { data } = await axios.post(`${config.url}/User/Address_Delete`, formData, {
    //   headers: headers,
    // });
    let { data } = await nodeCudApi.post(`/User/Address_Delete`, formData);
    if (data) {
      if (data.Response_Status === "Success") {
        const formData = {
          Records_Filter: "FILTER",
          User_Email_Id: EmailID,
        };
        dispatch(GetAllAddressAction(formData));
        toast.show({ message: 'User address deleted successfully.', type: 'info', duration: 3000, position: 'top' });
      } else {
        toast.show({ message: data.UI_Display_Message, type: 'info', duration: 3000, position: 'top' });
      }
    } else {
      toast.show({ message: data.UI_Display_Message, type: 'info', duration: 3000, position: 'top' });
    }
  } catch (err) {
    toast.show({ message: 'Unable to delete address, please try again.', type: 'info', duration: 3000, position: 'top' });
  }
};

