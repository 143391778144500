import React from "react";
import isEmpty from "../../state/validations/is-empty";
import NonRetailOrderDetails from "./fgOrderDetails";
import RetailOrderDetails from "./retailOrderDetails";


export default function MyOrderDetails(props) {
    const { orderId, isRetail } = props?.route?.params || "";
    isEmpty(orderId) && props.navigation.navigate('MyOrders')
    return (
        isRetail == "false" ? <NonRetailOrderDetails {...props} /> : <RetailOrderDetails {...props} />
    )
}