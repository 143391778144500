import {
  EVENT_REQUEST,
  EVENT_SUCCESS,
  EVENT_FAIL,
  HALL_FAIL,
  HALL_SUCCESS,
  HALL_REQUEST,
  ALL_EVENT_FILTER
} from '../actions/eventsActions'

const initialState = {
  allfunctionhalls: { all: [], error: '', isLoading: false },
  functionhall: { halls: [], error: '', isLoading: false },
  allevents: { all: [], error: '', isLoading: false },
  eventsfilter: { select: 'All', search: '' },
  events: { events: [], error: '', isLoading: false },
}
export default function eventsReducer(state = initialState, action) {
  switch (action.type) {
    case HALL_REQUEST:
      return { ...state, allfunctionhalls: { all: state.allfunctionhalls.all, error: '', isLoading: true } };
    case HALL_SUCCESS:
      return { ...state, allfunctionhalls: { all: action.payload, error: '', isLoading: false } };
    case HALL_FAIL:
      return { ...state, allfunctionhalls: { all: [], error: action.payload, isLoading: false } };
    case EVENT_REQUEST:
      return { ...state, allevents: { all: state.allevents.all, error: '', isLoading: true } };
    case EVENT_SUCCESS:
      return { ...state, allevents: { all: action.payload, error: '', isLoading: false } };
    case EVENT_FAIL:
      return { ...state, allevents: { all: [], error: action.payload, isLoading: false } };
      case ALL_EVENT_FILTER:
        let res = { ...state };
        if (action.fType === "select") {
          res = { ...state, eventsfilter: { search: state.eventsfilter.search, select: action.value } }
        }
        else if (action.fType === "search") {
          res = { ...state, eventsfilter: { select: state.eventsfilter.select, search: action.value } };
        }
        return res;
    default:
      return state;
  }
}