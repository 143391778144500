import axios from "axios";
import { boomiApi } from "./commonAxios";
export const SET_ALL_FAQS = "SET_ALL_FAQS ";
export const ALL_FAQS_REQUEST = "ALL_FAQS_REQUEST";
export const ALL_FAQS_FAIL = "ALL_FAQS_FAIL";
export const GET_SINGLE_FAQ = "GET_SINGLE_FAQ";
export const GET_SINGLE_FAQ_REQUEST = "GET_SINGLE_FAQ_REQUEST";
export const GET_SINGLE_FAQ_FAIL = "GET_SINGLE_FAQ_FAIL";
export const CONTENTRESET = "CONTENTRESET";


/*=============================================================
                  Get All Categories Action
===============================================================*/

export const getAllFaqAction = () => async (dispatch: any) => {
    try {
        dispatch({
            type: ALL_FAQS_REQUEST,
        });
        const formData = {
            "Feq_Asked_Quest_Id": "",
            "Question": "",
            "Records_Filter": "FILTER",
            "Status": "Active",
            "Module_Type":"User"
        };
        let { data } = await boomiApi.post(
            `/FAQ/Get?PageNo=0&PageSize=1000&SortBy=QUESTION&SortOrder=ASC`,
            formData
        );
        if (data) {
            dispatch({
                type: SET_ALL_FAQS,
                payload: data.FAQ
            });

        }
    }
    catch (err) {
        dispatch({
            type: ALL_FAQS_FAIL,
            payload: err
        });
    }
};
/*=============================================================
                  Get Brand By Id Action
===============================================================*/

export const getFaqByIdAction = (faqId: any) => async (dispatch: any) => {
    try {
        dispatch({
            type: GET_SINGLE_FAQ_REQUEST,
        });
        const formData = {
            Records_Filter: "FILTER",
            Feq_Asked_Quest_Id: "faqId",
        };
        let { data } = await boomiApi.post(
            `/FAQ/Get?PageNo=0&PageSize=1000&SortBy=QUESTION&SortOrder=ASC`,
            formData
        );
        if (data) {
            dispatch({
                type: GET_SINGLE_FAQ,
                payload: data,
            });
        }
    } catch (error) {
        dispatch({
            type: GET_SINGLE_FAQ_FAIL,
        });
    }
};
export const contentreset = () => async (dispatch: any) => {
    dispatch({
        type: CONTENTRESET,
        payload: []
    });
}
