import { ActionType } from "../actions/shopbycityFilterAction";


const initialState = {
  maincities: [
    {
      label: "All Products",
      value: "products",
      type: "All_Product",
      expanded: false,
      key: '1',
      children: [
        {
          label: "Popular Products",
          value: "Is_Popular",
          type: "All_Product",
          checked: "unchecked",
          key: '1-1',
        },
        {
          label: "Top Products",
          value: "Is_Top_Product",
          type: "All_Product",
          checked: "unchecked",
          key: '1-2',
        },
        {
          label: "Today's Hot Products",
          value: "Whats_Hot",
          type: "All_Product",
          checked: "unchecked",
          key: '1-3',
        },
        {
          label: "Upcoming Products",
          value: "Is_Upcoming",
          type: "All_Product",
          checked: "unchecked",
          key: '1-4',
        },
        {
          label: "Verified Products",
          value: "Is_Verified",
          type: "All_Product",
          checked: "unchecked",
          key: '1-5',
        },
      ],
    },
    {
      label: "All Coupons",
      value: "coupons",
      type: "All_Coupons",
      expanded: false,
      key: '2',
      children: [
        {
          label: "Verified",
          value: "Verified",
          type: "All_Coupons",
          checked: "unchecked",
          key: '2-1'
        },
        {
          label: "Exclusive",
          value: "Exclusive",
          type: "All_Coupons",
          checked: "unchecked",
          key: '2-2'
        },
        {
          label: "Top Coupons",
          value: "Is_Top_Coupon",
          type: "All_Coupons",
          checked: "unchecked",
          key: '2-3'
        },
        {
          label: "Todays Hot Coupons",
          value: "Whats_Hot",
          type: "All_Coupons",
          checked: "unchecked",
          key: '2-4'
        },
        {
          label: "Upcoming Coupons",
          value: "Is_Upcoming",
          type: "All_Coupons",
          checked: "unchecked",
          key: '2-5'
        },

      ],

    },
    {
      label: "All Deals",
      value: "deals",
      type: "All_Deals",
      expanded: false,
      key: '3',
      children: [
        {
          label: "Verified",
          value: "Verified_Deal",
          type: "All_Deals",
          checked: "unchecked",
          key: '3-1',
        },
        {
          label: "Exclusive",
          value: "Exclusive",
          type: "All_Deals",
          checked: "unchecked",
          key: '3-2',
        },
        {
          label: "Is_Top_Deal",
          value: "Is_Top_Deal",
          type: "All_Deals",
          checked: "unchecked",
          key: '3-3',
        },
        {
          label: "Whats_Hot",
          value: "Whats_Hot",
          type: "All_Deals",
          checked: "unchecked",
          key: '3-4',
        },
      ],
    },
  ],
  partners: [],
  categories: [],
  brands: [],
  cashback: [{
    label: "Extra Cashback",
    value: "ExtraCashback",
    checked: "unchecked",
    key: '1',
  }],
  cities: [
    // label: "City Name",
    // value: "City_Id",
    // checked: "unchecked",
  ],
  discount: [
    {
      label: "1 - 25%",
      value: "1-25",
      type: "Discount",
      checked: "unchecked",
      key: '1',
    },
    {
      label: "25 - 35%",
      value: "25-35",
      type: "Discount",
      checked: "unchecked",
      key: '2',
    },
    {
      label: "35 - 50%",
      value: "35-50",
      type: "Discount",
      checked: "unchecked",
      key: '3',
    },
    {
      label: "50 - 70%",
      value: "50-70",
      type: "Discount",
      checked: "unchecked",
      key: '4',
    },
  ],
  price: [
    {
      label: "Under 500",
      value: "0-500",
      type: "Amount",
      checked: "unchecked",
      key: '1',
    },
    {
      label: "Rs 501-1000",
      value: "501-1000",
      type: "Amount",
      checked: "unchecked",
      key: '2',
    },

    {
      label: "Rs 1001- 5000",
      value: "1001-5000",
      type: "Amount",
      checked: "unchecked",
      key: '3',
    },
    {
      label: "Rs 5001-10000",
      value: "5001-10000",
      type: "Amount",
      checked: "unchecked",
      key: '4',
    },
    {
      label: "Rs 10001 or more",
      value: "10001",
      type: "Amount",
      checked: "unchecked",
      key: '5',
    }
  ]
};

export default function shopbycityFilterReducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.CITY_FILTER_DATA:
        return { ...state, cities: action.payload };
    case ActionType.MAIN_FILTER_DATA:
      return { ...state, maincities: action.payload };
    case ActionType.PARTNER_FILTER_DATA:
      return { ...state, partners: action.payload };
    case ActionType.CATEGORY_FILTER_DATA:
      return { ...state, categories: action.payload };
    case ActionType.BRAND_FILTER_DATA:
      return { ...state, brands: action.payload };
    case ActionType.CASHBACK_FILTER_DATA:
      return { ...state, cashback: action.payload };
    case ActionType.DISCOUNT_FILTER_DATA:
      return { ...state, discount: action.payload };
    case ActionType.DISCOUNT_PRICE_DATA:
      return { ...state, price: action.payload };    
    default:
      return state;
  }
}
