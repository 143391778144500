import { useCallback, useEffect, useState } from "react";
import { Image, Platform, Text, View } from "react-native";
import EsiCheckbox from "../../../components/custom/checkbox";

export const DiscountFilters = (props) => {
    const { filters, setFilters } = props;

    const data = useCallback([
        {
            label: "1 - 25%",
            value: "1-25", chacked: filters?.discount == "1-25" || false
        },
        {
            label: "25 - 35%",
            value: "25-35", chacked: filters?.discount == "25-35" || false
        },
        {
            label: "35 - 50%",
            value: "35-50", chacked: filters?.discount == "35-50" || false
        },
        {
            label: "50 - 70%",
            value: "50-70", chacked: filters?.discount == "50-70" || false
        }
    ], [filters]);

    const onchange = res => {
        setFilters(props => {
            return { ...props, discount: res.value==props.discount?"": res.value }
        });
    }

    return (
        <View style={[{ marginHorizontal: 5 }, Platform.select({ ios: { zIndex: 100 } })]}>
            <View style={{ flexDirection: "row" }}>
                <Image style={{ width: 25, height: 25, marginRight: 10 }}
                    source={require('../../../assets/images/favicon.png')}
                />
                <Text style={{
                    fontSize: 14, color: "#03dac4",
                    marginTop: 3,
                }}>Discount's</Text>
            </View>
            <EsiCheckbox data={data} onchange={onchange} />
        </View>
    )
}