import {
  SET_ALL_PARTNERS,
  ALL_PARTNERS_REQUEST,
  ALL_PARTNERS_FAIL,
  PARTNER_REVIEW_CREATE_REQUEST,
  PARTNER_REVIEW_CREATE_SUCCESS,
  PARTNER_REVIEW_CREATE_FAIL,
  PARTNER_REVIEW_UPDATE_REQUEST,
  PARTNER_REVIEW_UPDATE_SUCCESS,
  PARTNER_REVIEW_UPDATE_FAIL,
  TOP_PARTNER,
  TOP_PARTNER_FAIL,
  TOP_PARTNER_SUCCESS,
  ALL_PARTNER_USER_REVIEWS_REQUEST,
  ALL_PARTNER_USER_REVIEWS,
  ALL_PARTNER_USER_REVIEWS_FAIL,
  ALL_PARTNER_REQUEST,
  ALL_PARTNER_GET,
  ALL_PARTNER_FAIL
} from '../actions/partnerActions'
const initialState = {
  partners: { all: [], error: '', isLoading: false },
  partner: { all: [], error: '', isLoading: false },
  partnerReviews: { all: [], error: '', isLoading: false },
  reviewsCreate: { review: {}, error: '', isLoading: false },
  reviewsUpdate: { review: {}, error: '', isLoading: false },
  toppartner: { toppartner: {}, error: '', isLoading: false }

};

export default function partnersReducer(state = initialState, action) {
  switch (action.type) {
    case ALL_PARTNERS_REQUEST:
      return { ...state, partners: { all: state.partners.all, error: '', isLoading: true } };
    case SET_ALL_PARTNERS:
      return { ...state, partners: { all: action.payload, error: '', isLoading: false } };
    case ALL_PARTNERS_FAIL:
      return { ...state, partners: { all: [], error: action.payload, isLoading: false } };

    case ALL_PARTNER_REQUEST:
      return { ...state, partner: { all: state.partner.all, error: '', isLoading: true } };
    case ALL_PARTNER_GET:
      return { ...state, partner: { all: action.payload, error: '', isLoading: false } };
    case ALL_PARTNER_FAIL:
      return { ...state, partner: { all: [], error: action.payload, isLoading: false } };

    case ALL_PARTNER_USER_REVIEWS_REQUEST:
      return { ...state, partnerReviews: { all: [], error: '', isLoading: true } };
    case ALL_PARTNER_USER_REVIEWS:
      return { ...state, partnerReviews: { all: action.payload, error: '', isLoading: false } };
    case ALL_PARTNER_USER_REVIEWS_FAIL:
      return { ...state, partnerReviews: { all: [], error: action.payload, isLoading: false } };
    case PARTNER_REVIEW_CREATE_REQUEST:
      return { ...state, reviewsCreate: { review: {}, error: '', isLoading: true } };
    case PARTNER_REVIEW_CREATE_SUCCESS:
      return { ...state, reviewsCreate: { review: state.reviewsCreate.review, error: '', isLoading: false } };
    case PARTNER_REVIEW_CREATE_FAIL:
      return { ...state, reviewsCreate: { review: {}, error: action.payload, isLoading: false } };

    case PARTNER_REVIEW_UPDATE_REQUEST:
      return { ...state, reviewsUpdate: { review: {}, error: '', isLoading: true } };
    case PARTNER_REVIEW_UPDATE_SUCCESS:
      return { ...state, reviewsUpdate: { review: state.reviewsUpdate.review, error: '', isLoading: false } };
    case PARTNER_REVIEW_UPDATE_FAIL:
      return { ...state, reviewsUpdate: { review: {}, error: action.payload, isLoading: false } };

    case TOP_PARTNER:
      return { ...state, toppartner: { toppartner: state.toppartner.toppartner, error: '', isLoading: true } };
    case TOP_PARTNER_SUCCESS:
      return { ...state, toppartner: { toppartner: action.payload, error: '', isLoading: false } };
    case TOP_PARTNER_FAIL:
      return { ...state, toppartner: { toppartner: [], error: action.payload, isLoading: false } };
    default:
      return state;
  }
}
